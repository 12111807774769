<template>
  <main>
    <header>
      <h1>{{ title }}</h1>
    </header>
    <section v-if="posts">
      <post-item
        v-for="post in posts"
        :key="post.id"
        :post="post" />
      <pagination
        v-if="totalPages > 1"
        :total="totalPages"
        :current="props.page" />
    </section>
  </main>
</template>

<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import { useStore } from '../stores/main'
import PostItem from '@/components/template-parts/PostItem.vue'
import Pagination from '@/components/template-parts/PaginationItem.vue'

const store = useStore()
const props = defineProps({
  page: {
    type: Number,
    required: true,
  },
  year: {
    type: String,
    required: true,
  },
  month: {
    type: String,
    required: false,
    default: '',
  },
  day: {
    type: String,
    required: false,
    default: '',
  },
})
const totalPages = ref(0)
const request = ref({
  type: 'posts',
  params: {
    per_page: this.site.posts_per_page,
    page: this.page,
    after: '',
    before: '',
  },
  showLoading: true,
})
const after = computed(() => {
  return `${props.year}${props.month ? '-' + props.month : '-01'}${
    props.day ? '-' + props.day : '-01'
  }T00:00:00.000Z`
})
const before = computed(() => {
  let before = new Date(after.value)
  if (props.day) {
    before.setUTCDate(before.getUTCDate() + 1)
  } else if (this.month) {
    before.setUTCMonth(before.getUTCMonth() + 1)
  } else {
    before.setUTCFullYear(before.getUTCFullYear() + 1)
  }
  return before.toISOString()
})
const posts = computed(() => {
  return store.requestedItems(this.request)
})
const title = computed(() => {
  let options = { year: 'numeric' }
  if (props.month) {
    options.month = 'long'
    if (props.day) options.day = 'numeric'
  }
  return `Archive for ${new Date(
    after.value.replace('T0', 'T1')
  ).toLocaleDateString('en-US', options)}`
})

watch(before, (newValue) => {
  if (newValue) {
    request.value.params.before = newValue
  }
})
watch(after, (newValue) => {
  if (newValue) {
    request.value.params.after = newValue
  }
})

const getPosts = () => {
  return store.getItems(request.value)
}

const setAfterParam = () => {
  request.value.params.after = `${props.year}${
    props.month ? '-' + props.month : '-01'
  }${props.day ? '-' + props.day : '-01'}T00:00:00.000Z`
}

const setBeforeParam = () => {
  let before = new Date(request.value.params.after)
  if (props.day) {
    before.setUTCDate(before.getUTCDate() + 1)
  } else if (props.month) {
    before.setUTCMonth(before.getUTCMonth() + 1)
  } else {
    before.setUTCFullYear(before.getUTCFullYear() + 1)
  }
  request.value.params.before = before.toISOString()
}

const setTotalPages = () => {
  totalPages.value = store.getTotalPages(request.value)
}

onBeforeMount(() => {
  setAfterParam()
  setBeforeParam()
  getPosts().then(() => setTotalPages())
})
</script>
