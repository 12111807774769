<template>
  <article class="post">
    <a
      :href="props.post.link"
      :title="props.post.post_title">
      <post-taxonomies :post="props.post" />
      <div class="post-date">
        <time>{{ date }}</time>
      </div>
      <p
        class="title"
        v-html="props.post.title.rendered"></p>
      <post-meta :post="props.post" />
      <div class="read-more">
        <p>Read More</p>
        <div class="arrows">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </a>
  </article>
</template>

<script setup>
import { ref } from 'vue'
import { format } from 'date-fns'
import PostMeta from '@/components/utility/PostMeta.vue'
import PostTaxonomies from '@/components/utility/PostTaxonomies.vue'

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})
const date = ref(format(new Date(props.post.date), 'd MMMM yyyy'))
</script>

<style lang="scss">
.post {
}
</style>
