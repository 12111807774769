<template>
  <div
    class="spacer-wrapper"
    :style="`background-color:${props.data.back_background_color}`">
    <div
      class="spacer"
      :class="`rounded-${data.rounded}`"
      :style="`${
        height > 0 ? `padding-top: ${height}px;` : `margin-top: ${height}px;`
      } background-color: ${
        props.data.background_color
      }; box-shadow: 0 0 0 1px ${props.data.background_color};`">
      <div
        v-if="props.data.separator"
        class="module-container">
        <div class="inner-container">
          <BicolorLine
            :left-custom="props.data.separator_left"
            :right-custom="props.data.separator_right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* #META
 {
  "NAME": "SpacerComponent",
  "CLASSNAME": "spacer-wrapper",
  "URL": "http://vuevitewp.local/test"
 }
#ENDMETA */
import BicolorLine from '@/components/utility/BicolorLine.vue'

import { ref, onMounted, onUnmounted, onBeforeMount, watch } from 'vue'

const props = defineProps(['data'])

const deviceWidth = ref(0)
const height = ref(0)

watch(deviceWidth, () => {
  if (deviceWidth.value > 1024) {
    height.value = props.data.spacer_height
  } else if (deviceWidth.value > 768) {
    height.value = props.data.spacer_height_tablet
  } else {
    height.value = props.data.spacer_height_mobile
  }
})

onBeforeMount(() => (deviceWidth.value = window.innerWidth))

onMounted(() => {
  window.addEventListener('resize', () => {
    deviceWidth.value = window.innerWidth
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', () => {
    deviceWidth.value = window.innerWidth
  })
})
</script>

<style lang="scss" scoped>
.spacer-wrapper {
  @apply relative;

  .spacer {
    @apply relative;
    &.rounded-tr {
      @apply rounded-tr-[100px];
    }

    &.rounded-tl {
      @apply rounded-tl-[100px];
    }

    &.rounded-br {
      @apply rounded-br-[100px];
    }

    &.rounded-bl {
      @apply rounded-bl-[100px];
    }
  }
}

.module-container {
  @apply absolute top-0 w-full h-full;

  .inner-container {
    @apply relative w-full h-full
    flex items-center justify-center;

    .bicolor-line {
      @apply w-full;
    }
  }
}
</style>
