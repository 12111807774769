import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const bicolorLineAnim = (configs) => {
  gsap.fromTo(
    configs.cssSelector,
    {
      clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
    },
    {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
      stagger: {
        amount: 0.2,
      },
      scrollTrigger: {
        trigger: configs.trigger,
        scrub: true,
        start: 'top-=200 center',
        end: `bottom+=300 center`,
        id: configs.id,
      },
    }
  )
  gsap.from(
    `${configs.cssSelector} > .half-w`,
    {
      width: 0,
      stagger: {
        amount: 0.8,
      },
      scrollTrigger: {
        trigger: configs.trigger,
        scrub: true,
        start: 'top-=200 center',
        end: `bottom+=300 center`,
        id: configs.id,
      },
    },
    '<'
  )
}

export default bicolorLineAnim
