<template>
  <section class="open-pos-module-component module-container">
    <div class="inner-container">
      <div class="open-pos-top">
        <h2 class="title">Open Positions</h2>
        <div class="filters">
          <div class="filter-wrapper">
            <Dropdown
              :idx="0"
              :title="'Department'"
              :dropdowns="filterArrays"
              :parent-selected-state="selectedFilters" />
          </div>
        </div>
      </div>
      <div class="open-pos-data">
        <figure
          v-for="(dept, deptIdx) in filteredPositions"
          :key="`dept-${deptIdx}`"
          class="dept">
          <div v-if="dept.jobs.length >= 1">
            <figcaption class="dept-label">
              {{ dept.name }}
            </figcaption>
            <ul class="positions">
              <li
                v-for="(pos, posIdx) in dept.jobs"
                :key="`pos-${posIdx}`"
                class="position">
                <span class="position-title">{{ pos.title }}</span>
                <a
                  class="position-link"
                  :href="isItProd(pos.absolute_url)"
                  target="_self">
                  View Details
                </a>
              </li>
            </ul>
          </div>
        </figure>
      </div>
      <div
        v-if="
          defaultFilters !== selectedFilters &&
          filteredPositions[0]?.jobs.length === 0
        "
        class="header-2">
        There are currently no open positions in the
        {{ selectedFilters[0] }} department. Please check back later.
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "OpenPosComponent",
    "CLASSNAME": "open-pos-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */
import { onBeforeMount, ref, watch } from 'vue'
import Dropdown from '@/components/utility/Dropdown.vue'
import { useStore } from '@/stores/main'

const positions = ref([])
const filteredPositions = ref([])
const store = useStore()
let defaultFilters = ref(['All'])

let selectedFilters = ref([...defaultFilters.value])

let filterArrays = ref({
  titles: ['Department'],
  options: [[defaultFilters.value[0]]],
})

const isItProd = (url) => {
  if (!store.site.url.includes('revmed.com')) {
    const finalURL = new URL(url)
    const path = `${finalURL.pathname}${finalURL.search}`
    return path
  } else {
    return url
  }
}

onBeforeMount(async () => {
  const GHresponse = await fetch(
    'https://boards-api.greenhouse.io/v1/boards/revolutionmedicines/departments'
  )
  const GHjson = await GHresponse.json()

  const GH = GHjson.departments.filter((dept) => {
    return dept.jobs.length !== 0 && dept.name !== 'No Department'
  })

  const deptNames = GH.map((dept) => dept.name)

  positions.value = GH.map((dept) => {
    return dept
  })
  filteredPositions.value = positions.value
  filterArrays.value.options = [[defaultFilters.value[0], ...deptNames]]
})
watch(selectedFilters.value, (newFilters) => {
  if (newFilters[0] === defaultFilters.value[0]) {
    filteredPositions.value = positions.value
  } else {
    filteredPositions.value = positions.value.filter((dept) => {
      return dept.name === newFilters[0]
    })
  }
})
</script>

<style lang="scss" scoped>
.open-pos-module-component {
  @apply py-10;
  .inner-container {
    @apply px-[6px];
    .open-pos-top {
      @apply flex flex-col gap-[50px] mb-10
      xl:flex-row xl:items-center xl:justify-between;

      .title {
        @apply font-bold header-6 px-[25px]
        lg:header-4;
      }
      .filters {
        @apply flex flex-col px-[9px] gap-4
        xl:flex-row xl:px-0;
        .filter-wrapper {
          @apply relative p-2 bg-gray_green_trans rounded-[18px]
          xl:h-[96px] xl:w-[315px] xl:overflow-visible;
        }
      }
    }

    .open-pos-data {
      @apply flex flex-col gap-11;
      .dept {
        &-label {
          @apply px-[20px] header-6 font-bold text-gray_green mb-6
          lg:header-5;
          text-transform: none;
        }
        .positions {
          @apply flex flex-col gap-[8px];

          .position {
            @apply flex justify-between  items-center pl-3.5 pr-5 pt-[20px] pb-[20px] rounded-[20px] bg-white xl:px-[30px];

            &-title {
              @apply w-[200px] text-balanced text-[13px] font-normal leading-[120%] tracking-[-0.26px]
              lg:w-[500px]
              xl:header-4 xl:w-[780px];
            }
            &-link {
              @apply flex bg-progressive gap-2.5 px-5 py-2 rounded-[29px] transition-all
              text-[13px] font-normal leading-[120%] tracking-[-0.26px]
              xl:px-10 xl:py-4 xl:text-xl;

              &:hover {
                @apply text-progressive bg-humble;
              }
            }
          }
        }
        .see-all-positions {
          @apply w-full bg-balanced rounded-[20px] mt-[8px] py-5 px-16
          flex justify-center text-functional text-center font-FKGrotesk
          transition-all;

          &:hover,
          &:focus {
            @apply bg-white text-balanced;
          }
        }
      }
    }
  }
}
</style>
