<template>
  <section class="teams-module-component module-container">
    <div class="inner-container">
      <div class="nav-container">
        <h2 class="header-2">Our Team</h2>
        <CNav
          variant="tabs"
          role="tablist">
          <CNavItem
            v-for="(category, idx) in teams"
            :key="`navitem-${idx}`">
            <CNavLink
              :id="`nav-link-${idx}`"
              href="javascript:void(0);"
              :active="tabPaneActiveKey === idx"
              @keydown.tab="enterTabpanel($event, idx)"
              @keydown.right="switchTab(idx, 1)"
              @keydown.left="switchTab(idx, -1)"
              @click="
                () => {
                  tabPaneActiveKey = idx
                }
              ">
              {{ category.name }}
            </CNavLink>
          </CNavItem>
        </CNav>
      </div>
      <CTabContent>
        <CTabPane
          v-for="(category, idx) in teams"
          :key="`tabpane-${idx}`"
          :class="`tabpane-${idx}`"
          role="tabpanel"
          :aria-labelledby="`nav-link-${idx}`"
          :visible="tabPaneActiveKey === idx">
          <TeamCards
            :is-team-component="true"
            :is-child="true"
            :members="category.members"
            :have-img="!isNoImage(category.name)" />
        </CTabPane>
      </CTabContent>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TeamsComponent",
    "CLASSNAME": "teams-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/vue'
import TeamCards from '@/components/modules/SingleTeam/TeamCards.vue'
import { onBeforeMount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

// import { useStore } from '../../stores/main'

// const store = useStore()

const route = useRoute()

const props = defineProps(['data'])

const tabPaneActiveKey = ref(0)
const teams = ref([])

// const getCategory = async () => {
//   let request = {
//     type: 'team',
//     params: {
//       per_page: 100,
//       maximum: 100,
//     },
//     showLoading: true,
//   }

//   await store.getItems(request)
//   let formattedArray = store.team_tax
//   formattedArray.forEach((category) => {
//     category.members = []
//   })
//   store.requestedItems(request).map((member) => {
//     let memCat = member.category[0]
//     if (memCat === undefined) {
//       memCat = 1
//     }

//     formattedArray.forEach((category) => {
//       if (member.category.includes(category.term_id)) {
//         category.members.push(member)
//       }
//     })
//   })

//   const catIdOrder = props.data.categories_to_render
//   let orderedArray = catIdOrder.map((el) => {
//     return formattedArray.find((cat) => {
//       return cat.term_id === el
//     })
//   })

//   teams.value = orderedArray
// }

const isNoImage = (category) => {
  return category === 'Advisors' || category === 'Board of Directors'
}

const switchTab = (idx, step) => {
  tabPaneActiveKey.value = idx
  let nextTab = document.querySelector(`#nav-link-${idx + step}`)
  const lastIdx = teams.value.length - 1
  if (idx === 0 && step === -1) {
    tabPaneActiveKey.value = lastIdx
    nextTab = document.querySelector(`#nav-link-${lastIdx}`)
  } else if (idx === lastIdx && step === 1) {
    tabPaneActiveKey.value = 0
    nextTab = document.querySelector(`#nav-link-0`)
  } else {
    tabPaneActiveKey.value += step
  }
  nextTab.focus()
}

const enterTabpanel = (e, idx) => {
  if (e.shiftKey) {
    return
  }
  e.preventDefault()
  const firstCard = document.querySelectorAll(
    `.tabpane-${idx} > section > div > div > a`
  )[0]
  firstCard.focus()
}

const mountTeams = () => {
  const newTeamMembers = []
  if (props.data.senior_management_members.length > 0) {
    newTeamMembers.push({
      name: 'Senior Management',
      members: props.data.senior_management_members,
    })
  }
  if (props.data.leadership_members.length > 0) {
    newTeamMembers.push({
      name: 'Leadership',
      members: props.data.leadership_members,
    })
  }
  if (props.data.board_of_directors_members.length > 0) {
    newTeamMembers.push({
      name: 'Board of Directors',
      members: props.data.board_of_directors_members,
    })
  }
  if (props.data.advisor_members.length > 0) {
    newTeamMembers.push({
      name: 'Advisors',
      members: props.data.advisor_members,
    })
  }
  teams.value = newTeamMembers
}

const setTabByQuery = () => {
  let query = route.query.active
  if (query.includes('/')) {
    query = query.replaceAll('/', '')
  }
  console.log(query)

  const getTeam = (teamName) => {
    const teamValue = teams.value.findIndex((item) => item.name === teamName)
    console.log(teamValue)
    return teamValue
  }

  if (query === 'senior-management') {
    tabPaneActiveKey.value = getTeam('Senior Management')
  }

  if (query === 'leadership') {
    tabPaneActiveKey.value = getTeam('Leadership')
  }

  if (query === 'board-of-directors') {
    tabPaneActiveKey.value = getTeam('Board of Directors')
  }

  if (query === 'advisors') {
    tabPaneActiveKey.value = getTeam('Advisors')
  }

  // switch (query) {
  //   case 'senior-management':
  //     //tabPaneActiveKey.value = 0
  //     break
  //   case 'leadership':
  //     tabPaneActiveKey.value = 1
  //     break
  //   case 'board-of-directors':
  //     tabPaneActiveKey.value = 2
  //     break
  //   case 'advisors':
  //     tabPaneActiveKey.value = 3
  //     break
  //   default:
  //     break
  // }
  //
}

onBeforeMount(async () => {
  mountTeams()
  // await getCategory()
})

onMounted(() => {
  if (route.query.active) {
    setTabByQuery()
  }
})
</script>

<style lang="scss" scoped>
.teams-module-component {
  @apply bg-white py-16;
  .inner-container {
    .nav-container {
      @apply flex flex-col
      xl:flex-row xl:items-center xl:gap-16;
      .nav {
        @apply flex flex-col my-9 bg-functional rounded-[14px]
        xl:flex-row xl:justify-between xl:flex-1 xl:rounded-full;

        &-item {
          @apply flex flex-col
          xl:flex-1;
          .nav-link {
            @apply rounded-full text-center py-[10px]
            xl:py-4 transition-all duration-200;
            &.active {
              @apply bg-progressive;
            }
          }
        }
      }
    }
    .tab-content {
      .tab-pane {
        @apply -mt-36;
        .team-cards {
        }
      }
    }
  }
}
</style>
