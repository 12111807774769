<template>
  <section
    :id="animTriggerId"
    class="animated-cols-module-component module-container">
    <div class="inner-container">
      <div
        :id="pinTriggerId"
        class="title-container">
        <h2
          class="title header-3"
          v-html="data.title" />
      </div>
      <div class="animated-cols">
        <ul
          v-for="(col, colIdx) in splitCols(data.col_items)"
          :key="'col-' + colIdx"
          class="col">
          <li
            v-for="(item, itemIdx) in col"
            :key="'col-' + colIdx + 'item-' + itemIdx"
            class="col-item">
            <BicolorLine />

            <span
              class="col-item-text"
              v-html="item.text" />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "AnimatedColsComponent",
    "CLASSNAME": "animated-cols-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

import { onBeforeUnmount, onMounted, ref } from 'vue'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import useUtils from '@/components/composables/useUtils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import bicolorLineAnim from '@/assets/anims/bicolored-line-anim'
gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])
const { genUUID, device } = useUtils()

const animIDs = ref({
  trigger: genUUID(),
  bicolorLines: genUUID(),
  leftCol: genUUID(),
  rightCol: genUUID(),
  title: genUUID(),
})
const animTriggerId = `anim-trigger-${animIDs.value.trigger}`
const pinTriggerId = `pin-trigger-${animIDs.value.trigger}`

const splitCols = (arr) => {
  const middleIndex = Math.floor(arr.length / 2)
  const firstPart = arr.slice(0, middleIndex)
  const secondPart = arr.slice(middleIndex)
  return [firstPart, secondPart]
}

const titleAnim = () => {
  ScrollTrigger.create({
    trigger: `#${animTriggerId}`,
    start: 'top+=200 center',
    end: 'bottom bottom-=160',
    pin: `#${pinTriggerId}`,
    scrub: 1,
    id: animIDs.value.title,
  })
}

const colsAnim = () => {
  const cols = document.querySelectorAll('.col')
  const scrollTriggerConfigs = {
    trigger: `#${animTriggerId}`,
    scrub: true,
    start: 'top-=200 center',
    end: `bottom+=300 center`,
    id: animIDs.value.leftCol,
  }
  const colsOffset = 100
  gsap.to(cols[0], {
    y: -colsOffset,
    scrollTrigger: scrollTriggerConfigs,
  })
  gsap.to(cols[1], {
    y: colsOffset,
    scrollTrigger: scrollTriggerConfigs,
  })

  const bicolorLineSelector = `#${animTriggerId} > div > div > ul > li > .bicolor-line`
  bicolorLineAnim({
    cssSelector: bicolorLineSelector,
    trigger: `#${animTriggerId}`,
    id: animIDs.value.bicolorLines,
  })
}

onMounted(() => {
  if (device.value === 'desktop') {
    titleAnim()
    colsAnim()
  }
})

onBeforeUnmount(() => {
  const trigger = ScrollTrigger.getById(animIDs.value.trigger)
  const bicolorLines = ScrollTrigger.getById(animIDs.value.bicolorLines)
  const leftCol = ScrollTrigger.getById(animIDs.value.leftCol)
  const rightCol = ScrollTrigger.getById(animIDs.value.rightCol)
  const title = ScrollTrigger.getById(animIDs.value.title)
  if (trigger) trigger.kill()
  if (bicolorLines) bicolorLines.kill()
  if (leftCol) leftCol.kill()
  if (rightCol) rightCol.kill()
  if (title) title.kill()
})
</script>

<style lang="scss" scoped>
.animated-cols-module-component {
  @apply py-10 bg-white
  lg:pb-[150px];
  .inner-container {
    @apply flex flex-col gap-[40px]
    lg:flex-row lg:gap-0;

    .title-container {
      @apply lg:w-1/2 lg:pt-20;
      .title {
        @apply text-balanced  not-italic font-normal leading-10 tracking-[-0.8px]
        lg:max-w-[400px] lg:text-[40px];
      }
    }
    .animated-cols {
      @apply flex gap-2
      lg:w-1/2 lg:justify-center;
      .col {
        @apply w-full flex flex-col gap-2;
        &-item {
          @apply min-h-[120px] relative flex flex-col gap-3
          p-3 lg:min-h-0
          lg:pt-[70px] lg:pb-3 lg:px-6 lg:w-[300px]
          xl:w-[320px];
          .bicolor-line {
            @apply h-[3px] w-[66px];
          }
          &-text {
            @apply self-stretch text-balanced font-FKGrotesk text-base not-italic font-bold leading-5
            lg:header-5 lg:text-[22px] lg:leading-[26px] lg:tracking-[-0.44px];
            text-transform: none;
          }
        }
        &:first-child {
          @apply lg:pt-[100px];
        }
      }
    }
  }
}
</style>
