<template>
  <section class="quote-component module-container">
    <div class="inner-container">
      <div class="quote-content">
        <blockquote>
          <div class="quote-text">
            <p>
              <span
                v-for="(line, idx) in lines"
                :key="idx"
                class="line">
                <span
                  class="line-text"
                  v-html="line" />
              </span>
            </p>
          </div>
          <footer
            class="quote-auth"
            v-html="data.author" />
        </blockquote>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "QuoteComponent",
    "CLASSNAME": "quote-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

import { onBeforeUnmount, onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin, CSSPlugin } from 'gsap/all'
import useUtils from '@/components/composables/useUtils.js'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(CSSPlugin, CSSRulePlugin)

const { genUUID, device } = useUtils()
const props = defineProps(['data'])

const originalText = ref(props.data.quote)
let lines = originalText.value.split(/<br\s?\/?>\n?/)
lines[0] = lines[0].replace(/<p\s?\/?>\n?/, '')
lines[lines.length - 1] = lines[lines.length - 1].replace(/<\/p>\n?/, '')

const animIDs = ref({
  authorAnim: genUUID(),
  quoteAnim: genUUID(),
  highlightAnim: genUUID(),
})

const quoteAnim = () => {
  const highlight = CSSRulePlugin.getRule(':before')
  const tl = gsap.timeline()
  tl.fromTo(
    '.line-text',
    {
      top: '110%',
    },
    {
      top: 0,
      zIndex: 2,
      stagger: {
        each: 1,
        amount: 0.7,
      },
      duration: 1.5,
      ease: 'Power4.easeOut',
      scrollTrigger: {
        trigger: '.quote-component',
        scrub: true,
        start: 'top-=300 center',
        end: `top+=${device.value == 'mobile' ? '800' : '1200'} center`,
        id: animIDs.value.quoteAnim,
      },
    }
  ).fromTo(
    '.quote-auth',
    {
      y: 20,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,

      duration: 1.5,
      ease: 'Power4.easeOut',
      scrollTrigger: {
        trigger: '.quote-component',
        scrub: true,
        start: 'top center',
        end: 'top+=300 center',
        id: animIDs.value.authorAnim,
      },
    },
    '<'
  )
  if (device.value === 'desktop') {
    tl.from(
      highlight[6],
      {
        cssRule: {
          width: '0%',
        },
        scrollTrigger: {
          trigger: '.quote-component',
          scrub: true,
          start: 'top+=150 center',
          end: `bottom-=150 center`,
          id: animIDs.value.highlightAnim,
        },
      },
      '-=2'
    )
  }
}

onMounted(() => {
  quoteAnim()
})

onBeforeUnmount(() => {
  const quoteAnim = ScrollTrigger.getById(animIDs.value.quoteAnim)
  if (quoteAnim) quoteAnim.kill()
  const authorAnim = ScrollTrigger.getById(animIDs.value.authorAnim)
  if (authorAnim) authorAnim.kill()
  const highlightAnim = ScrollTrigger.getById(animIDs.value.highlightAnim)
  if (highlightAnim) highlightAnim.kill()
})
</script>

<style lang="scss" scoped>
.quote-component {
  @apply bg-balanced py-[10px];
  .inner-container {
    .quote-content {
      blockquote {
        @apply flex flex-col-reverse gap-5 items-center text-center;
        div:deep() {
          @apply flex flex-col items-center;
          p,
          strong {
            @apply font-[400] text-[30px] leading-[34px] font-FKDisplay
            lg:text-[80px] lg:leading-[80px];
          }
          p {
            @apply h-full flex flex-col
            lg:max-w-[100%];
            .line {
              @apply block relative overflow-hidden h-[35px]  -mb-[5px]
              lg:h-[85px];
              &-text {
                @apply relative text-approachable;
              }
            }
          }
          strong {
            @apply relative inline-block text-approachable  h-[28px]
            lg:h-[65px];
            &:before {
              @apply -z-1 absolute left-0 bottom-0 w-full h-[85%] bg-humble;
              content: '';
            }
          }
        }
        footer:deep() {
          @apply text-lg leading-6 text-approachable w-72 font-FKGrotesk;
        }
      }
    }
  }
}
</style>
