import { ref, onBeforeMount } from 'vue'
import { add } from 'date-fns'

const introShown = ref(false)
const preIntroShown = ref(false)

export default function useIntroShown() {
  const setIntroComplete = () => {
    introShown.value = true
    localStorage.setItem('introShown', true)
  }

  const setPreIntroComplete = () => {
    preIntroShown.value = true
    localStorage.setItem('preIntroShown', true)
  }

  const setIntroExpireTime = () => {
    localStorage.removeItem('introExpire')
    const currentDate = new Date()
    const expirationDate = add(currentDate, { days: 14 })
    // Set to 1 minute for debugging
    // const expirationDate = add(currentDate, { minutes: 1 })
    localStorage.setItem('introExpire', expirationDate)
  }

  const hasIntroExpired = () => {
    const currentDate = new Date()
    const expirationDate = new Date(localStorage.getItem('introExpire'))
    return currentDate > expirationDate
  }

  onBeforeMount(() => {
    if (!localStorage.getItem('introExpire')) {
      setIntroExpireTime()
    } else {
      if (hasIntroExpired()) {
        localStorage.removeItem('introShown')
        localStorage.removeItem('preIntroShown')
        setIntroExpireTime()
      } else {
        introShown.value = localStorage.getItem('introShown')
        preIntroShown.value = localStorage.getItem('preIntroShown')
      }
    }
  })

  return {
    introShown,
    preIntroShown,
    setPreIntroComplete,
    setIntroComplete,
  }
}
