<template>
  <section
    class="page-hero-module-component module-container relative"
    :class="{ 'contact-hero-module-component': data.is_contact }"
    :style="{
      '--bg-x-position': `${data.img_pos_x}%`,
    }">
    >
    <a
      v-if="data.address_link && data.address_link.title"
      :href="data.address_link.url"
      :target="data.address_link.target"
      class="floating-button-module-component">
      <div class="inner-floating-button">
        {{ data.address_link.title }}
        <img
          src="@/assets/img/circle-arrow.svg"
          alt=""
          class="-rotate-90" />
      </div>
    </a>
    <div
      v-if="data.is_contact"
      class="contact-bg-container">
      <img
        class="bg-image"
        :style="centerImage()"
        src="@/assets/img/contact-bg.jpg"
        alt="" />
    </div>
    <img
      v-else
      class="bg-image"
      :src="data.background_image.url"
      :alt="data.background_image.alt" />
    <div
      class="inner-container"
      :class="{ 'contact-layout': data.is_contact }">
      <div
        v-if="data.is_contact"
        class="contact-text-container">
        <BicolorLine
          left="10"
          extraclass="page-hero-line-contact" />
        <div
          class="hero-text"
          v-html="data.hero_text" />
      </div>
      <div
        v-else
        class="hero-text"
        :style="{
          '--revmed-hero-text-color': props.data.highlight_color,
        }"
        v-html="splitText(data.hero_text)"></div>
      <BicolorLine
        v-if="!data.is_contact"
        extraclass="page-hero-line" />
      <div
        v-if="data.is_contact"
        class="fake-overflow"
        :class="`rounded-${data.fake_overflow_rounded}`"
        :style="{
          background: data.fake_overflow_color,
        }" />
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "PageHeroComponent",
    "CLASSNAME": "page-hero-module-component",
    "URL": "http://vuevitewp.local/about"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import BicolorLine from '../utility/BicolorLine.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import useUtils from '@/components/composables/useUtils.js'
import useIntroShown from '@/components/composables/useIntroShown.js'

gsap.registerPlugin(ScrollTrigger)
gsap.config({
  force3D: true,
})
const { preIntroShown } = useIntroShown()
const { genUUID } = useUtils()
const animId = ref(genUUID())

const splitText = (text) => {
  //remove H1
  const noH1 = text.replace(/<[/\n\r]*h1[^>]*>/g, '')

  // split text by <br /> tag
  const splitText = noH1.split('<br />')

  // add span tags and H1 tags
  let finalSplit = ''
  splitText.forEach(
    (x, index) =>
      (finalSplit += `${index === 0 ? '<h1>' : ''}
        <span><span>${x}</span></span>
        ${index === splitText.length - 1 ? '</h1>' : ''}`)
  )
  return finalSplit
}

const centerImage = () => {
  if (props.data.is_contact && window.innerWidth > 1420) return { top: '-13%' }
  else return {}
}

const bgAnimation = () => {
  gsap.fromTo(
    '.page-hero-module-component .bg-image',
    {
      y: '-10%',
    },
    {
      y: '10%',
      scrollTrigger: {
        trigger: '.page-hero-module-component .bg-image',
        id: animId.value,
        scrub: true,
        start: 'center center',
        end: 'bottom center',
        markers: false,
      },
    }
  )
}

const textAnimation = () => {
  gsap.set('.page-hero-module-component .bicolor-line.page-hero-line', {
    x: '-125%',
  })

  gsap.set('.page-hero-module-component .hero-text > h1 > span > span', {
    rotate: 0.001,
    y: '115%',
  })

  bgAnimation()

  gsap.fromTo(
    '.page-hero-module-component .bicolor-line.page-hero-line',
    {
      x: '-125%',
    },
    {
      x: 0,
      duration: 2,
      ease: 'Power4.easeOut',
      delay: 0.7,
    }
  )

  gsap.fromTo(
    '.page-hero-module-component .hero-text > h1 > span > span',
    {
      rotate: 0.001,
      y: '115%',
    },
    {
      y: 0,
      duration: 2,
      ease: 'Power4.easeOut',
      stagger: {
        amount: 0.2,
      },
      delay: 0.5,
    }
  )
}

const mountAnimation = () => {
  if (preIntroShown.value) {
    textAnimation()
  } else {
    setTimeout(() => {
      mountAnimation()
    }, 100)
  }
}

onMounted(() => {
  if (props.data.is_contact) {
    bgAnimation()
  } else {
    mountAnimation()
  }
})

onBeforeUnmount(() => {
  const heroAnim = ScrollTrigger.getById(animId.value)
  if (heroAnim) heroAnim.kill()
})

const props = defineProps(['data'])
// console.log(props)
</script>

<style lang="scss" scoped>
.page-hero-module-component {
  @apply h-[110vh] bg-cover bg-no-repeat bg-top relative overflow-hidden;
  .bg-image {
    @apply h-full w-full object-cover object-top absolute top-0 left-0;
    object-position: var(--bg-x-position);
  }
  .inner-container:deep() {
    @apply h-full flex flex-col justify-center overflow-hidden;
    .bicolor-line.page-hero-line {
      @apply w-32 lg:w-56;
    }
    .hero-text {
      @apply mb-10 w-full lg:w-8/12;
      strong {
        @apply font-normal;
        color: var(--revmed-hero-text-color);
      }
      h1 {
        @apply font-FKDisplayALT text-white text-[40px] lg:text-[60px] leading-[40px] lg:leading-[60px] font-normal;
        span {
          @apply text-white overflow-hidden block h-[42px] lg:h-[62px];
        }
      }
    }
  }
}
.contact-hero-module-component {
  @apply overflow-visible
  lg:max-h-[835px];
  .contact-layout:deep() {
    @apply overflow-visible justify-end
    lg:flex-row lg:justify-start lg:items-center
    lg:static
    xl:p-0;
    .contact-text-container {
      @apply z-2 -mb-[150px] flex flex-col items-start gap-[19px]  px-8 py-10 
      backdrop-blur-[20px] shadow-[0px_1px_1px_0px_rgba(255,255,255,0.15)_inset] bg-[rgba(14,67,67,0.3)] rounded-[20px]
      lg:w-[450px]
      lg:pl-[71px] lg:pr-[100px] lg:pt-16 lg:pb-24  lg:rounded-[25px]
      2xl:mt-[100px];

      .page-hero-line-contact {
        @apply w-[66px] h-[3px] my-1;
      }
      .hero-text {
        @apply mb-0 flex flex-col gap-5
        lg:w-full 
        lg:gap-[50px];
        h1 {
          @apply mb-6
          lg:header-2 
          xl:-mb-[20px];
        }
        p {
          @apply text-functional font-FKGrotesk
          lg:text-[22px];
        }
      }
    }
    .fake-overflow {
      @apply absolute bottom-[-170px] left-0 h-[230px] w-full bg-green-50
      lg:h-[300px]
      xl:bottom-[-110px] xl:h-[290px];
      &.rounded-right {
        @apply rounded-tr-[50px]
        lg:rounded-tr-[100px];
      }
      &.rounded-left {
        @apply rounded-tl-[50px]
        lg:rounded-tl-[100px];
      }
    }
  }
}
.floating-button-module-component {
  @apply absolute z-[36] bottom-96 lg:bottom-48 right-3 cursor-pointer;
  .inner-floating-button {
    @apply flex items-center bg-humble/30 text-white rounded-full p-2 pl-8 backdrop-blur-md
    header-6;
    img {
      @apply ml-[20px];
    }
  }
}
</style>
