<template>
  <img
    v-if="device === 'desktop'"
    class="decorative-image"
    alt=""
    :style="cssDt"
    :src="data.image.url" />
  <img
    v-else
    class="decorative-image"
    alt=""
    :style="cssMobile"
    :src="data.image.url" />
</template>

<script setup>
/* #META
   {
    "NAME": "DecorationComponent",
    "CLASSNAME": "decorative-image",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

import { ref } from 'vue'
import useUtils from '@/components/composables/useUtils.js'
const { device } = useUtils()
const props = defineProps(['data'])
const imgPos = ref(props.data.image_positioning)
const cssDt = {
  right: imgPos.value.dt.alignment_x
    ? null
    : `${imgPos.value.dt.pos_x}${imgPos.value.unit}`,
  left: imgPos.value.dt.alignment_x
    ? `${imgPos.value.dt.pos_x}${imgPos.value.unit}`
    : null,
  top: imgPos.value.dt.alignment_y
    ? null
    : `${imgPos.value.dt.pos_y}${imgPos.value.unit}`,
  bottom: imgPos.value.dt.alignment_y
    ? `${imgPos.value.dt.pos_y}${imgPos.value.unit}`
    : null,
}

const cssMobile = {
  right: imgPos.value.mobile.alignment_x
    ? null
    : `${imgPos.value.mobile.pos_x}${imgPos.value.unit}`,
  left: imgPos.value.mobile.alignment_x
    ? `${imgPos.value.mobile.pos_x}${imgPos.value.unit}`
    : null,
  top: imgPos.value.mobile.alignment_y
    ? null
    : `${imgPos.value.mobile.pos_y}${imgPos.value.unit}`,
  bottom: imgPos.value.mobile.alignment_y
    ? `${imgPos.value.mobile.pos_y}${imgPos.value.unit}`
    : null,
}
</script>

<style lang="scss" scoped>
.decorative-image {
  @apply absolute z-3;
}
</style>
