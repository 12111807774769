<template>
  <a
    v-if="props.url"
    :class="`button ${props.color}`"
    :href="props.url"
    :target="props.target"
    @mouseover="animateOnEnter()"
    @mouseleave="animateOnLeave()">
    <div class="button-wrapper">
      <p class="button-title">{{ props.text }}</p>
    </div>
    <div
      ref="lottieContainer"
      class="lottie"></div>
  </a>
  <button
    v-else-if="!props.url && props.func"
    :class="`button ${props.color}`"
    @click="func(props.args)"
    @mouseover="animateOnEnter()"
    @mouseleave="animateOnLeave()">
    <div class="button-wrapper">
      <p class="button-title">{{ props.text }}</p>
    </div>
    <div
      ref="lottieContainer"
      class="lottie"></div>
  </button>
  <div
    v-else
    :class="`button cursor-pointer ${props.color}`"
    @mouseover="animateOnEnter()"
    @mouseleave="animateOnLeave()">
    <div class="button-wrapper">
      <p class="button-title">{{ props.text }}</p>
    </div>
    <div
      ref="lottieContainer"
      class="lottie"></div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import LottieWeb from 'lottie-web'
import logoJson from '@/assets/lottie/btn-hover.json'
import useUtils from '../composables/useUtils'

const { device } = useUtils()

const props = defineProps({
  text: String,
  url: String,
  target: String,
  color: String,
  func: Function,
  args: Number,
})
const lottieContainer = ref()
const anim = ref()

const animateOnEnter = () => {
  if (device.value !== 'desktop') return
  LottieWeb.setDirection(1)
  setTimeout(() => {
    anim.value.goToAndPlay(1, true)
    anim.value.play()
  }, 100)
}

const animateOnLeave = () => {
  if (device.value !== 'desktop') return
  LottieWeb.setDirection(-1)
}

onMounted(() => {
  anim.value = LottieWeb.loadAnimation({
    container: lottieContainer.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: logoJson,
    speed: 100,
  })
  anim.value.setSpeed(1.5)
})
onBeforeUnmount(() => {
  anim.value.destroy()
})
</script>

<style lang="scss" scoped>
.button {
  @apply relative flex w-fit;

  &:hover {
    .lottie {
      @apply ml-[5px]
      -translate-x-14 lg:-translate-x-3 rotate-0 scale-[1.60];
    }
  }

  .button-wrapper {
    @apply flex;
  }

  .button-title {
    @apply relative py-4 px-10 min-w-[167px] rounded-[29px]
    text-center text-[1.125rem] leading-[1.375rem] tracking-[-0.0225rem] z-1 self-start text-humble bg-progressive;
  }

  .lottie {
    @apply pointer-events-none absolute -right-14 flex items-center justify-center w-[50px] h-[54px] ml-0
     bg-contain bg-no-repeat
    transition-all duration-500 ease-in-out -translate-x-14;
  }
}

// .button.green {
//   @apply inline-flex my-5 py-3.5 px-3.5 w-52 border-solid border-y-2 border-black hover:bg-gray-500 hover:text-white justify-between items-center text-black;
//   @apply transition transition-all ease-in-out duration-200;
//   .button-icon {
//     @apply transition transition-all ease-in-out duration-200;
//     svg {
//       path {
//         @apply fill-black;
//         @apply transition transition-all ease-in-out duration-200;
//       }
//     }
//   }
//   &:hover {
//     .button-icon {
//       @apply transform rotate-45 translate-x-1;
//       @apply transition transition-all ease-in-out duration-200;
//       svg {
//         path {
//           @apply fill-white;
//           @apply transition transition-all ease-in-out duration-200;
//         }
//       }
//     }
//   }
// }

// .button.black {
//   @apply inline-flex my-5 py-3.5 px-3.5 w-52 border-solid border-y-2 border-text hover:bg-text hover:text-white justify-between items-center text-text;
//   @apply transition transition-all ease-in-out duration-200;
//   .button-icon {
//     @apply transition transition-all ease-in-out duration-200;
//     svg {
//       path {
//         @apply fill-text;
//         @apply transition transition-all ease-in-out duration-200;
//       }
//     }
//   }
//   &:hover {
//     .button-icon {
//       @apply transform rotate-45 translate-x-1;
//       @apply transition transition-all ease-in-out duration-200;
//       svg {
//         path {
//           @apply fill-white;
//           @apply transition transition-all ease-in-out duration-200;
//         }
//       }
//     }
//   }
// }
// .button.white {
//   @apply inline-flex my-5 py-3.5 px-3.5 w-52 border-solid border-y-2 border-white hover:bg-white hover:text-white justify-between items-center text-white hover:text-black;
//   @apply transition transition-all ease-in-out duration-200;
//   .button-icon {
//     @apply transition transition-all ease-in-out duration-200;
//     svg {
//       path {
//         @apply fill-white;
//         @apply transition transition-all ease-in-out duration-200;
//       }
//     }
//   }

//   &:hover {
//     .button-icon {
//       @apply transform rotate-45 translate-x-1;
//       @apply transition transition-all ease-in-out duration-200;
//       svg {
//         path {
//           @apply fill-black;
//           @apply transition transition-all ease-in-out duration-200;
//         }
//       }
//     }
//   }
// }
</style>
