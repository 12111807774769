<template>
  <section
    :id="animTriggerId"
    class="card-list-module-component module-container"
    :class="{ 'no-bg-container': data.no_background }">
    <div class="inner-container">
      <div
        class="about-cards"
        :class="data.no_background ? 'no-background' : null">
        <div
          v-for="(item, idx) in data.list"
          :key="idx"
          class="card">
          <img
            v-if="!data.no_background"
            src="@/assets/img/empty-hex.svg"
            class="hex" />
          <BicolorLine />
          <div
            class="copy"
            v-html="item.text" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
{
  "NAME": "CardListComponent",
  "CLASSNAME": "card-list-module-component",
  "URL": "http://vuevitewp.local/test"
}
#ENDMETA */
import { onBeforeUnmount, onMounted, ref } from 'vue'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import useUtils from '@/components/composables/useUtils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import bicolorLineAnim from '@/assets/anims/bicolored-line-anim'
gsap.registerPlugin(ScrollTrigger)

const { genUUID } = useUtils()
const animID = ref(genUUID())
const animTriggerId = `anim-trigger-${animID.value}`

defineProps(['data'])

const cardAnim = () => {
  const cssSelector = `#${animTriggerId} > .inner-container > .about-cards > .card > .bicolor-line`
  bicolorLineAnim({
    cssSelector: cssSelector,
    trigger: `#${animTriggerId}`,
    id: animID.value,
  })
}
onMounted(() => {
  cardAnim()
})

onBeforeUnmount(() => {
  const anim = ScrollTrigger.getById(animID.value)
  if (anim) anim.kill()
})
</script>

<style lang="scss" scoped>
.card-list-module-component {
  @apply py-[10px];

  .inner-container {
    @apply xl:px-0;
  }
  .about-cards {
    @apply flex flex-col gap-[10px]
    lg:justify-center lg:flex-row lg:flex-wrap lg:gap-0;
    .card {
      @apply w-full bg-white py-[20px] pl-[15px] pr-[45px] rounded-[20px]
      lg:w-[32%] lg:min-h-[170px] lg:mr-[2%] lg:mb-[4%];
      &:nth-child(3n) {
        @apply mr-0;
      }

      .hex {
        @apply ml-[5px] mt-[5px];
      }

      .bicolor-line {
        @apply ml-5 w-[66px] h-[3px] mt-10 mb-5
        lg:ml-[15px] lg:mt-8;
      }

      .copy {
        @apply w-[80%] font-FKGrotesk font-medium ml-5 leading-[26px] text-[22px] text-balanced
        lg:w-full lg:ml-[15px];
      }
      @apply gap-[25px];
    }
  }

  &.no-bg-container {
    .inner-container {
      @apply px-14;

      .about-cards {
        &.no-background {
          .card {
            @apply bg-transparent p-0
            lg:min-h-min;
          }
        }
      }
    }
  }
}
</style>
