<template>
  <section
    class="floating-media-module-component-about module-container"
    :class="`y-position-${data.pos.y_axis}`">
    <div
      class="inner-container px-0"
      :class="`lg:x-position-${data.pos.x_axis}`">
      <div class="floating-media">
        <img
          class="top-box floating-box"
          :src="data.imgs[0].url" />
        <button
          v-if="data.video_data.video"
          class="video-box floating-box">
          <div class="video-box-inner">
            <div class="video-box-inner-inner">
              <div class="play">
                <img src="@/assets/img/play-icon.svg" />
              </div>
              <div
                v-if="data.video_data.video_title"
                class="video-title">
                <span
                  class="title-text"
                  v-html="data.video_data.video_title" />
                <span
                  class="title-text"
                  v-html="data.video_data.video_title" />
              </div>
            </div>
          </div>
        </button>
        <img
          class="bottom-box floating-box"
          :src="data.imgs[1].url" />
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "FloatingMediaComponent",
    "CLASSNAME": "floating-media-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

// Note: need to implement video (no design yet)
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import useUtils from '@/components/composables/useUtils.js'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])
// console.log(props.data)

const { genUUID, device } = useUtils()

const animID = ref(genUUID())

const boxesAnim = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.floating-media-module-component-about',
      scrub: 0.75,
      start: '-200px 100%',
      end: '2000px 100%',
      id: animID.value,
      markers: false,
    },
  })

  if (device.value === 'mobile') {
    tl.fromTo(
      '.top-box',
      {
        y: 40,
        duration: 10,
      },
      {
        y: 100,
      }
    )
    tl.fromTo(
      '.bottom-box',
      {
        y: 100,
      },
      {
        y: -100,
      },
      '<'
    )
  } else {
    tl.fromTo(
      '.top-box',
      {
        y: 40,
        duration: 10,
      },
      {
        y: 200,
      }
    ).fromTo(
      '.bottom-box',
      {
        y: 600,
      },
      {
        y: -300,
      },
      '<'
    )
  }

  if (document.querySelector('.video-box')) {
    tl.from(
      '.video-box',
      {
        y: 0,
      },
      '<'
    )
  }
}

onMounted(() => {
  let animMoveValue = 150
  if (device.value === 'mobile') {
    animMoveValue = 100
  }
  boxesAnim(animMoveValue)
})

onBeforeUnmount(() => {
  const mediaAnim = ScrollTrigger.getById(animID.value)
  if (mediaAnim) mediaAnim.kill()
})
</script>

<style lang="scss" scoped>
.floating-media-module-component-about {
  @apply py-[10px]
  lg:py-0 lg:h-0 lg:flex lg:justify-start lg:items-center;

  &.y-position-bottom {
    @apply lg:items-start;
  }
  &.y-position-top {
    @apply lg:items-end;
    .floating-media {
      @apply lg:translate-y-[350px];
    }
  }

  .inner-container {
    @apply pointer-events-none flex justify-center md:justify-end z-5;

    &.x-position-left {
      @apply lg:justify-start;
    }
    &.x-position-right {
      @apply lg:justify-end;
    }
    .floating-media {
      @apply flex flex-col w-[390px]
      sm:w-[600px]
      lg:h-[700px] lg:w-[320px]
      xl:w-[600px];
      .floating-box {
        @apply w-[203px] h-[207px] rounded-[25px] object-cover;
      }

      .top-box {
        @apply self-end rounded-bl-[60px]
        sm:w-[350px] sm:h-[357px]
        lg:w-[203px] lg:h-[207px]
        xl:w-[350px] xl:h-[357px];
      }

      .video-box {
        @apply self-center pointer-events-auto z-1 relative w-[163px] h-[163px] my-[-50px]
        lg:mt-[-75px] lg:mb-[-40px] lg:w-[175px] lg:h-[175px] -left-0 lg:-left-16 lg:top-10;
        &-inner {
          @apply h-full rounded-[25px] backdrop-blur-[15px]
            p-5
          transition-all duration-300 ease-in-out;
          box-shadow: 0px 2px 1px 0px #ffffff26 inset;
          &-inner {
            @apply h-full flex items-end justify-center
            transition-all duration-300 ease-in-out;
            .video-title {
              @apply relative overflow-hidden;
              p,
              span {
                @apply text-white;
              }
              .title-text {
                @apply transition-all duration-300 ease-in-out;
              }
              .title-text:first-child {
                @apply absolute left-0 bottom-[-20px];
              }
              .title-text:nth-child(2) {
                @apply flex;
              }
            }
            .play {
              @apply top-0 left-0 absolute w-full h-full
            flex items-center justify-center pointer-events-none
            transition-all duration-300 ease-in-out;
            }
          }
          &:hover,
          &:focus,
          &:focus-visible {
            @apply scale-90;
            .video-box-inner-inner {
              @apply scale-110;
            }
            .play {
              @apply scale-125;
            }

            .title-text:first-child {
              @apply bottom-1/2;
              transform: translateY(50%);
            }
            .title-text:nth-child(2) {
              @apply translate-y-[-20px];
            }
          }
        }
      }

      .bottom-box {
        @apply self-start -left-0 lg:-left-20 relative
        rounded-tr-[60px]
        sm:w-[270px] sm:h-[276px] sm:ml-0
        lg:w-[203px] lg:h-[207px] lg:ml-0
        xl:w-[270px] xl:h-[276px] xl:ml-0;
      }
    }
  }
}
</style>
