import { onBeforeUnmount, ref } from 'vue'
import Lenis from '@studio-freight/lenis'
import useUtils from './useUtils'

const locoScroll = ref()
const observer = ref()

export default function useSmoothScroll() {
  const { setScrollProgress } = useUtils()

  const activate = () => {
    if (locoScroll.value) return
    locoScroll.value = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      orientation: 'vertical', // vertical, horizontal
      gestureOrientation: 'vertical', // vertical, horizontal, both
      smoothWheel: true,
      wheelMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })
    locoScroll.value.on('scroll', ({ progress }) => {
      setScrollProgress(progress)
    })
    function raf(time) {
      locoScroll.value.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
    observer.value = new ResizeObserver(() => refreshScroll()).observe(
      document.querySelector('body')
    )
  }

  onBeforeUnmount(() => {
    // console.log(observer.value)
    if (observer.value) {
      observer.value.disconnect()
    }
  })

  const refreshScroll = () => {
    // use it if need it
  }

  return {
    activate,
    locoScroll,
    observer,
  }
}
