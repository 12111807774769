<template>
  <section class="call-to-action-module-component">
    <div class="inner-container flex flex-col lg:flex-row gap-6 lg:gap-20">
      <div class="reveal-text w-full lg:w-7/12">
        <BicolorLine extraclass="cta-bicolor-line" />
        <div
          :id="`text-${uniqueId}`"
          class="text"
          v-html="addBGDiv(data.cta_text)" />
      </div>
      <div class="button-area flex flex-col gap-y-6 mt-10">
        <ButtonGlobal
          v-for="(button, index) of data.cta_button"
          :key="index"
          :text="button.button.title"
          :target="button.button.target"
          :url="button.button.url"
          class="cta-button" />
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "CallToActionComponent",
    "CLASSNAME": "call-to-action-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import gsap from 'gsap'
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'
import BicolorLine from '../utility/BicolorLine.vue'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import useUtils from '@/components/composables/useUtils'

defineProps(['data'])

const { genUUID } = useUtils()
const splitted = ref()
const uniqueId = ref()

gsap.registerPlugin(ScrollTrigger)

const animateText = () => {
  gsap.fromTo(
    '.cta-bicolor-line',
    {
      x: -40,
      opacity: 0,
    },
    {
      x: 0,
      opacity: 1,
      scrollTrigger: {
        id: `bicolor-${uniqueId.value}`,
        trigger: `#text-${uniqueId.value}`,
        start: 'top bottom-=100px',
        end: 'bottom bottom-=100px',
        scrub: false,
        markers: false,
      },
    }
  )
  gsap.fromTo(
    `#text-${uniqueId.value} div.line > *`,
    {
      y: '100%',
    },
    {
      y: '0%',
      duration: 1,
      stagger: 0.1,
      ease: 'power4.out',
      scrollTrigger: {
        id: `text-${uniqueId.value}`,
        trigger: `#text-${uniqueId.value}`,
        start: 'top bottom-=100px',
        end: 'bottom bottom-=100px',
        scrub: false,
        markers: false,
      },
    }
  )
  gsap.fromTo(
    `#text-${uniqueId.value} div.highlight`,
    {
      width: '0%',
    },
    {
      width: '103%',
      duration: 1,
      stagger: 0.1,
      ease: 'power4.out',
      scrollTrigger: {
        id: `highlight-${uniqueId.value}`,
        trigger: `#text-${uniqueId.value}`,
        start: 'top-=150% center',
        end: 'bottom center',
        scrub: false,
        markers: false,
      },
    }
  )
  gsap.fromTo(
    '.cta-button',
    {
      opacity: 0,
    },
    {
      opacity: 1,
      stagger: 0.3,
      ease: 'power4.out',
      scrollTrigger: {
        id: `buttons-${uniqueId.value}`,
        trigger: `#text-${uniqueId.value}`,
        start: 'top-=150% center',
        end: 'bottom center',
        scrub: false,
        markers: false,
      },
    }
  )
}

const addBGDiv = (html) => {
  return html.replace(
    /<em>/g,
    `<em><div class="highlight highlight-${uniqueId.value}"></div>`
  )
}

const splitIt = () => {
  const breakIt = document.querySelector(`#text-${uniqueId.value}`)

  if (!breakIt) {
    setTimeout(() => {
      splitIt()
      // console.log('waiting for text to be rendered')
    }, 100)
  } else {
    setTimeout(() => {
      const textLines = new SplitType(`#text-${uniqueId.value} > *`, {
        types: 'lines, words',
      })
      splitted.value = textLines.lines
      animateText()
    }, 100)
  }
}

onMounted(() => {
  uniqueId.value = genUUID()
  splitIt()
})

onBeforeUnmount(() => {
  const textAnim = ScrollTrigger.getById(`text-${uniqueId.value}`)
  if (textAnim) textAnim.kill()
  const highlightAnim = ScrollTrigger.getById(`highlight-${uniqueId.value}`)
  if (highlightAnim) highlightAnim.kill()
  const buttonsAnim = ScrollTrigger.getById(`buttons-${uniqueId.value}`)
  if (buttonsAnim) buttonsAnim.kill()
  const bicolorAnim = ScrollTrigger.getById(`bicolor-${uniqueId.value}`)
  if (bicolorAnim) bicolorAnim.kill()
})
</script>

<style lang="scss" scoped>
.call-to-action-module-component {
  @apply module-container;
  .reveal-text {
    .cta-bicolor-line {
      @apply w-[66px] h-[3px]
      lg:w-[100px] lg:h-[5px];
    }
    .text:deep() {
      @apply header-2 lg:text-[40px] leading-none mt-5;
      .line {
        @apply overflow-hidden;
      }
      .word {
        @apply text-balanced;
      }
      em {
        @apply not-italic;
        .highlight {
          @apply h-full w-full absolute left-0 bottom-0 z-[-1] bg-approachable;
        }
      }
    }
  }
}
</style>
