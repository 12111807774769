<template>
  <section class="about-values-module-component module-container">
    <div class="inner-container">
      <div class="top-section">
        <div class="video-section">
          <div class="negative-space" />
          <FloatingMediaComponent :data="floatingMediaData" />
        </div>
        <div class="card-section">
          <div class="card">
            <h2 class="title">
              {{ data.about_section.who_we_are.title }}
            </h2>
            <div
              class="description"
              v-html="data.about_section.who_we_are.content" />
          </div>
        </div>
      </div>
      <div class="bottom-section">
        <div class="quote lg:my-32">
          <TextRevealComponent
            :data="{
              text: data.about_section.quote_section,
              mini_heading: '',
              horizontal_padding: true,
              color_settings: {
                mini_heading_text_color: '#1E6B5C',
                text_color: '#70FFE5',
                bg_color: 'transparent',
                highlight_color: '#0E4343',
              },
            }" />
        </div>
        <BicolorLine extraclass="about-component" />
        <p class="text-[26px] mt-6">Our Values</p>
      </div>
    </div>
    <ValuesAnimation :steps="data.values_section.values" />
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "AboutValuesComponent",
    "CLASSNAME": "about-values-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import FloatingMediaComponent from '@/components/modules/aboutValues/VideoParallax.vue'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import ValuesAnimation from './aboutValues/ValuesAnimation.vue'
import useUtils from '@/components/composables/useUtils'
import TextRevealComponent from '@/components/modules/TextRevealComponent.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const { genUUID } = useUtils()
const animID_0 = ref(genUUID())
const animID_1 = ref(genUUID())

const props = defineProps(['data'])

const floatingMediaData = {
  pos: props.data.about_section.video_section.pos,
  imgs: props.data.about_section.video_section.imgs,
  video_data: {
    video_title: props.data.about_section.video_section.video_title,
    video: props.data.about_section.video_section.video,
  },
}

const moduleAnimations = () => {
  gsap.fromTo(
    '.bicolor-line.about-component',
    {
      width: 0,
    },
    {
      width: '100%',
      scrollTrigger: {
        trigger: '.bottom-section',
        start: 'top center',
        end: 'bottom-=200px center',
        scrub: true,
        id: animID_0.value,
      },
    }
  )
  gsap.fromTo(
    '.about-values-module-component',
    {
      backgroundColor: '#1E6B5C',
    },
    {
      backgroundColor: '#F2F2F2',
      scrollTrigger: {
        trigger: '.bottom-section',
        start: 'bottom-=250px center',
        end: 'bottom+=300px center',
        scrub: true,
        markers: false,
        id: animID_1.value,
      },
    }
  )
}

onMounted(() => {
  moduleAnimations()
})

onBeforeUnmount(() => {
  const anim_0 = ScrollTrigger.getById(animID_0.value)
  const anim_1 = ScrollTrigger.getById(animID_1.value)
  if (anim_0) anim_0.kill()
  if (anim_1) anim_1.kill()
})
</script>

<style lang="scss" scoped>
.about-values-module-component {
  @apply bg-balanced transition-colors;

  .negative-space {
    @apply w-full -mt-20 lg:mt-0 lg:invisible;
  }
  .spacer {
    @apply h-[200vh];
  }
  .inner-container {
    .top-section {
      @apply flex flex-col-reverse lg:flex-row gap-0 lg:gap-20;
      .video-section {
        @apply w-full lg:w-5/12 flex flex-col items-center lg:flex-row;
      }
      .card-section:deep() {
        @apply w-full lg:w-6/12;
        .card {
          @apply text-white
          lg:bg-humble lg:p-14 lg:rounded-2xl;
          h2 {
            @apply text-progressive font-FKDisplayALT header-2 
            font-light my-6 lg:mt-0 lg:text-[40px] lg:tracking-[0.8px];
          }
          .description {
            p {
              @apply text-white mb-6;
            }
          }
        }
      }
    }
    .bottom-section:deep() {
      .bicolor-line.about-component {
        @apply w-full;
      }
    }
  }
}
</style>
