<template>
  <main>
    <header>
      <h1>{{ title }}</h1>
    </header>
    <section v-if="posts">
      <post-item
        v-for="post in posts"
        :key="post.id"
        :post="post" />
      <pagination
        v-if="totalPages > 1"
        :total="totalPages"
        :current="props.page" />
    </section>
  </main>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '../stores/main'
import PostItem from '@/components/template-parts/PostItem.vue'
import Pagination from '@/components/template-parts/PaginationItem.vue'

const store = useStore()
const props = defineProps({
  page: {
    type: Number,
    required: true,
  },
  slug: {
    type: String,
    required: false,
    default: '',
  },
})
const authorRequest = ref({
  type: 'users',
  slug: props.slug,
})
const postsRequest = ref({
  type: 'posts',
  params: {
    per_page: store.site.posts_per_page,
    page: props.page,
    author: null,
  },
  showLoading: true,
})
const totalPages = ref(0)

const author = computed(() => {
  return store.getCurrentSingleBySlug(authorRequest.value)
})
const posts = computed(() => {
  if (author.value) {
    return store.requestedItems(postsRequest.value)
  }
  return []
})
const title = computed(() => {
  return `Posts by ${author.value ? author.value.name : ''}`
})

const getAuthor = () => {
  return store.getSingleBySlug(authorRequest.value).then(() => {
    setAuthorParam()
    store.updateDocTitle({
      parts: [author.value.name, store.value.site.name],
    })
  })
}

const getPosts = () => {
  return store.getItems(postsRequest.value)
}

const setAuthorParam = () =>
  (postsRequest.value.params.author = author.value.id)
const setTotalPages = () =>
  (totalPages.value = store.getTotalPages(postsRequest.value))

onBeforeMount(() => {
  getAuthor()
    .then(() => getPosts())
    .then(() => setTotalPages())
})
</script>
