<template>
  <main>
    <section class="news">
      <div class="container">
        <div class="posts-header page-header">
          <h1 class="h2">News</h1>
        </div>
        <transition
          name="fade"
          mode="out-in">
          <div
            v-if="filteredPosts"
            class="posts-container">
            <post-item
              v-for="post in filteredPosts"
              :key="post.id"
              :post="post"
              :show-image="false" />
          </div>
        </transition>
      </div>
    </section>
  </main>
</template>

<script setup>
import PostItem from '@/components/template-parts/PostItem.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from '../stores/main'

const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
})

const request = ref({
  type: 'posts',
  params: {
    fields:
      'id,slug,title,featured_media,link,categories,excerpt,better_featured_image,date,modified,content',
  },
  showLoading: true,
})
const pageRequest = ref({
  type: 'pages',
  slug: props.slug,
  showLoading: false,
})
const categoryRequest = ref({
  type: 'categories',
  params: {
    orderby: 'id',
  },
})
// const activeFilter = ref(null)
const filteredPosts = ref('')
const featuredPosts = ref([])
// const totalPages =  handleFilterref(0)
// const filterExpanded = ref(false)
// const filterOption =  ref('')
// const filterAll = ref(null)

const posts = computed(() => {
  return store.requestedItems(request.value)
})
// const categories = computed(() => {
//   return store.requestedItems(categoryRequest.value)
// })
const postsPage = computed(() => {
  return store.getCurrentSingleBySlug(pageRequest.value)
})

const getCurrentPosts = () => {
  return store.getItems(request.value)
}
const getCategories = () => {
  return store.getItems(categoryRequest.value)
}
// const setTotalPages = () => {
//   totalPages.value = store.getTotalPages(this.request.value)
// }

const getPostsPageInfo = () => {
  store.getSingleBySlug(pageRequest.value).then(() => {
    if (postsPage.value.acf.featuredPosts) {
      postsPage.value.acf.featuredPosts.forEach((post) => {
        return store
          .getSingleById({
            type: 'posts',
            id: post,
            batch: true,
          })
          .then(() => {
            featuredPosts.value.push(
              store.singleById({
                type: 'posts',
                id: post,
                batch: true,
              })
            )
          })
      })
    }
  })
}

/*const handleFilter = (index, category = null, name = '')  => {
  if (index >= 0) {
    activeFilter.value = index
    filterAll.value.classList.remove('active')
    const filterRequest = {
      type: 'posts',
      params: {
        categories: category,
        fields:
          'id,slug,title,featured_media,link,categories,excerpt,better_featured_image,date,modified,content',
      },
      showLoading: false,
    }
    store.getItems(filterRequest.value)
      .then(() => {
        filteredPosts.value = store.requestedItems(filterRequest.value)
      })
    filterExpanded.value = false
    filterOption.value = name
  } else {
    activeFilter.value = null
    filterAll.value.classList.add('active')
    filteredPosts.value = posts.value
    filterExpanded.value = false
    filterOption.value = ''
  }
}
*/

/*const loadMorePosts = () => {
  request.value.params.page++
  return store.getItems(request.value)
    .then(() => {
      filteredPosts.value = filteredPosts.value.concat(
        store.requestedItems(request.value)
      )
    })
}
*/

/*
const openFilter = () => {
  filterExpanded.value = !filterExpanded.value
}
*/

onBeforeMount(() => {
  getPostsPageInfo()

  getCurrentPosts().then(() => {
    filteredPosts.value = posts.value
  })

  getCategories()

  store.updateDocTitle({
    parts: ['News', store.site.name],
  })
})
</script>

<style lang="scss"></style>
