<template>
  <section
    :id="animTriggerId"
    class="text-with-cards-module-component module-container"
    :class="{ 'in-module': data.isInModule, 'clear-bg': data.lightBG }">
    <div class="inner-container">
      <div class="section-copy">
        <h2
          class="section-copy-title header-2"
          v-html="data.content.title" />
        <div
          class="section-copy-top"
          v-html="data.content.text" />
        <div
          v-if="data.content.smaller_text"
          class="section-copy-bottom"
          v-html="data.content.smaller_text" />
        <div
          v-if="data.content.cta"
          class="cta">
          <ButtonGlobal
            :text="data.content.cta.title"
            :target="data.content.cta.target"
            :url="data.content.cta.url" />
        </div>
        <div
          v-if="data.content.separator"
          class="pt-16 mt-[-2rem] mb-[-2rem]">
          <BicolorLine />
        </div>
      </div>
      <div
        v-if="data.cards"
        class="section-cards">
        <a
          v-for="(card, idx) in data.cards"
          :key="idx"
          :target="card.card.target"
          :href="card.card.url"
          class="card">
          <div class="card-inner">
            <div class="card-arrow">
              <div class="card-arrow-inner">
                <img
                  class="anim-arrow"
                  src="@/assets/img/icon-arrow-right-approachable.svg" />
                <img
                  class="anim-arrow"
                  src="@/assets/img/icon-arrow-right-approachable.svg" />
              </div>
            </div>
            <BicolorLine />
            <span
              class="card-text"
              v-html="card.card.title" />
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TextWithCardsComponent",
    "CLASSNAME": "text-with-cards-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

import { ref, onMounted, onBeforeUnmount } from 'vue'
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import useUtils from '@/components/composables/useUtils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import bicolorLineAnim from '@/assets/anims/bicolored-line-anim'
gsap.registerPlugin(ScrollTrigger)

const { genUUID } = useUtils()
const animID = ref(genUUID())
const animTriggerId = `anim-trigger-${animID.value}`

const props = defineProps(['data'])

const cardAnim = () => {
  const cssSelector = `#${animTriggerId} > div > div.section-cards > a .bicolor-line`
  bicolorLineAnim({
    cssSelector: cssSelector,
    trigger: `#${animTriggerId}`,
    id: animID.value,
  })
}

onMounted(() => {
  if (props.data.cards) {
    cardAnim()
  }
})

onBeforeUnmount(() => {
  const anim = ScrollTrigger.getById(animID.value)
  if (anim) anim.kill()
})
</script>

<style lang="scss" scoped>
.text-with-cards-module-component {
  @apply bg-balanced py-[10px];

  .inner-container {
    @apply flex flex-col
    lg:flex-row lg:gap-40
    xl:gap-72;
    .section-copy {
      @apply flex flex-col gap-8
      lg:max-w-lg;
      &-title {
        @apply text-approachable;
      }
      &-top:deep(),
      &-bottom:deep() {
        @include wysiwyg {
          @apply text-white;
        }
        .button-title {
          @apply text-humble;
        }
      }
      &-top:deep() {
        @include wysiwyg {
          @apply text-lg leading-6 font-normal;
        }
      }
      &-bottom:deep() {
        @apply flex flex-col gap-3;
        p {
          @apply text-sm leading-5;
        }
      }
      .cta {
      }
    }
    .section-cards {
      @apply mt-12 flex flex-col gap-[22px]
      lg:min-w-[360px] lg:mt-0;

      .card {
        @apply relative bg-white rounded-[20px] transition-all duration-300 ease-in-out;

        &-inner {
          @apply pt-14 pb-8 px-8
          flex flex-col gap-5
          transition-all duration-300 ease-in-out;
        }
        &-arrow {
          @apply overflow-hidden absolute top-[15px] right-[20px]
          bg-humble h-10 w-10 rounded-full
          flex items-center justify-center
          transition-all duration-300 ease-in-out;

          &-inner {
            @apply relative transition-all duration-300 ease-in-out;
            .anim-arrow {
              @apply transition-all duration-300 ease-in-out;
              &:first-child {
                @apply absolute left-[-50px];
              }
            }
          }
        }
        .bicolor-line {
          @apply w-[66px] h-[3px];
        }
        &-text {
          @apply text-[22px] font-FKGrotesk;
        }

        &:hover {
          @apply scale-90;
          .card-inner {
            @apply scale-110;
          }
          .card-arrow {
            @apply scale-90 top-[10px] right-[19px];

            &-inner {
              .anim-arrow:first-child {
                @apply left-1/2;
                transform: translateX(-50%);
              }
              .anim-arrow:nth-child(2) {
                @apply translate-x-[50px];
              }
            }
          }
        }
      }
    }
  }
  &.in-module {
    @apply bg-transparent p-0;

    .inner-container {
      @apply p-0;
    }
  }

  &.clear-bg {
    .section-copy {
      &-title {
        @apply text-balanced;
      }
      &-top:deep(),
      &-bottom:deep() {
        @include wysiwyg {
          @apply text-humble;
        }
      }
    }
  }
}
</style>
