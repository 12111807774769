<template>
  <section class="publications-module-component module-container">
    <div class="publications-header">
      <div class="inner-container">
        <h1 class="title header-3">Publications</h1>
      </div>
    </div>
    <div class="publications-data inner-container">
      <PublicationsFilterSelect
        :loading="loading"
        :filters="filters"
        :selected-options="selectedFilters" />
      <PublicationsList
        :publications="publications"
        :items-per-page="data.per_page"
        :is-loading="loading" />
    </div>
  </section>
</template>

<script setup>
import { onBeforeMount, ref, watch } from 'vue'
import { useStore } from '@/stores/main'

import PublicationsFilterSelect from '@/components/modules/Publications/PublicationsFilterSelect.vue'
import PublicationsList from '@/components/modules/Publications/PublicationsList.vue'

/* #META
   {
    "NAME": "PublicationsComponent",
    "CLASSNAME": "publications-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

const store = useStore()
defineProps(['data'])

const filters = ref({
  year: {
    title: 'Year',
    options: [],
  },
  conf_pub: {
    title: 'Conference / Journal',
    options: [],
  },
  program: {
    title: 'Program',
    options: [],
  },
  type: {
    title: 'Type',
    options: [],
  },
})

const publications = ref([])

const defaultFilters = ref([
  'All Years',
  'All Conferences / Journals',
  'All Programs',
  'All Types',
])

const selectedFilters = ref([...defaultFilters.value])

const loading = ref(false)

const getPubs = async () => {
  loading.value = true
  const pubRequest = ref({
    type: 'publications',
    params: {
      operand: 'AND',
      year:
        selectedFilters.value[0] === defaultFilters.value[0]
          ? ''
          : selectedFilters.value[0],
      conference:
        selectedFilters.value[1] === defaultFilters.value[1]
          ? ''
          : selectedFilters.value[1],
      program:
        selectedFilters.value[2] === defaultFilters.value[2]
          ? ''
          : selectedFilters.value[2],
      type:
        selectedFilters.value[3] === defaultFilters.value[3]
          ? ''
          : selectedFilters.value[3],
    },
  })
  await store.getItems(pubRequest.value)
  const requestedPublications = store.requestedItems(pubRequest.value)
  publications.value = requestedPublications
  loading.value = false
  return requestedPublications
}

watch(selectedFilters.value, async () => {
  // console.log(selectedFilters.value)
  const reqPub = await getPubs()

  const findYears = reqPub.map((pub) =>
    new Date(pub.acf.publication_date).getFullYear()
  )
  const conferences = []
  reqPub.map((pub) => {
    for (const confPub of pub.acf.conference_publications) {
      conferences.push(confPub)
    }
    return conferences
  })

  const program = []
  reqPub.map((pub) => {
    for (const prog of pub.acf.program) {
      program.push(prog)
    }
    return program
  })

  const type = []
  reqPub.map((pub) => {
    for (const t of pub.acf.type) {
      type.push(t)
    }
    return type
  })

  const years = [...new Set(findYears)]
  const confs = [...new Set(conferences)]
  const programs = [...new Set(program)]
  const types = [...new Set(type)]

  filters.value.year.options = [defaultFilters.value[0], ...years]
  filters.value.conf_pub.options = [defaultFilters.value[1], ...confs]
  filters.value.program.options = [defaultFilters.value[2], ...programs]
  filters.value.type.options = [defaultFilters.value[3], ...types]
})

onBeforeMount(async () => {
  getPubs()
  const options = window.__VUE_WORDPRESS__.state.acf_options

  filters.value.year.options = [defaultFilters.value[0], ...options.year_list]
  filters.value.conf_pub.options = [
    defaultFilters.value[1],
    ...options.conference_publications_list,
  ]
  filters.value.program.options = [
    defaultFilters.value[2],
    ...options.program_list,
  ]
  filters.value.type.options = [defaultFilters.value[3], ...options.type_list]
})
</script>

<style lang="scss" scoped>
.publications-module-component {
  @apply pb-10;
  .publications-header {
    @apply bg-balanced rounded-br-[75px] mb-[7px]
    lg:mb-[14px] lg:rounded-br-[100px];
    .inner-container {
      @apply flex justify-center pt-8 pb-12
      lg:pb-20;
      .title {
        @apply text-center text-approachable
        lg:text-[60px];
      }
    }
  }
  .inner-container {
    @apply px-7;
    // original padding: px-14
  }
  .publications-data {
    @apply flex flex-col gap-[6px]
    lg:gap-11;
  }
}
</style>
