import { ref, onMounted, onBeforeUnmount, onBeforeMount } from 'vue'
import { useStore } from '@/stores/main'
import shortUUID from 'short-uuid'

const scrollY = ref(0)
const scrollX = ref(0)
const scrollProgress = ref(0)
const heroAnimationComplete = ref(false)

export default function useUtils() {
  const device = ref()

  const genUUID = () => {
    return shortUUID('0123456789abcdefghijklmnoqrstuvwxyz')
      .generate()
      .substring(0, 10)
  }

  const decodeTitle = (title) => {
    const txt = document.createElement('textarea')
    txt.innerHTML = title
    return txt.value
  }

  const convertToRelative = (url) => {
    const store = useStore()
    const path = url.replace(store.site.url, '')
    return path
  }

  const isExternal = (url) => {
    const domain = (url) => {
      return url.replace('http://', '').replace('https://', '').split('/')[0]
    }
    return domain(location.href) !== domain(url)
  }

  const isDevice = (val) => {
    if (val < 768) {
      return (device.value = 'mobile')
    } else if (val >= 768 && val < 1024) {
      return (device.value = 'tablet')
    } else if (val >= 1024) {
      return (device.value = 'desktop')
    }
  }

  const setScrollProgress = (progress) => {
    scrollProgress.value = progress
  }

  const sizing = () => {
    window.addEventListener('resize', function () {
      device.value = isDevice(window.innerWidth)
    })
    device.value = isDevice(window.innerWidth)
  }

  onMounted(() => {
    const body = document.querySelector('body')
    body.addEventListener('scroll', () => {
      scrollY.value = body.scrollY
      scrollX.value = body.scrollX
    })
  })

  onBeforeMount(() => {
    sizing()
  })

  onBeforeUnmount(() =>
    window.removeEventListener('resize', function () {
      device.value = isDevice(window.innerWidth)
    })
  )

  return {
    device,
    scrollY,
    heroAnimationComplete,
    scroll,
    decodeTitle,
    convertToRelative,
    isExternal,
    setScrollProgress,
    genUUID,
  }
}
