<template>
  <div
    ref="dropdown"
    role="button"
    data-lenis-prevent
    class="dropdown dropdown-def-style"
    :class="{ active: isOpen }">
    <div
      class="dropdown-switcher"
      @click="switchFilterOpenState(idx)">
      <div class="dropdown-switcher-top">
        <span class="dropdown-switcher-top-title">{{ title }}</span>
        <button
          v-if="selectedRef[idx] !== dropdowns.options[idx][0]"
          class="clear-selection"
          @click="resetOption()">
          Clear
        </button>
      </div>
      <span class="dropdown-switcher-bottom">
        {{ selectedRef[idx] }}
      </span>
      <div class="text-hider" />
      <div class="dropdown-arrow-container">
        <div class="dropdown-arrow">
          <img src="@/assets/img/caret-down-progressive.svg" />
        </div>
      </div>
    </div>
    <SlideUpDown v-model="isOpen">
      <ul class="dropdown-options">
        <li
          v-for="(option, optionIdx) in dropdowns.options[idx]"
          :key="`option-${option}-${optionIdx}-title-${idx}`"
          class="option"
          :class="{ active: option === selectedRef[idx] }">
          <button
            class="option-inner"
            @click="selectOption(option, idx)">
            {{ option }}
          </button>
        </li>
      </ul>
    </SlideUpDown>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue'
import SlideUpDown from 'vue3-slide-up-down'

const props = defineProps(['idx', 'title', 'dropdowns', 'parentSelectedState'])

const selectedRef = ref(props.parentSelectedState)

const isOpen = ref(false)

const dropdown = ref()

const switchFilterOpenState = () => {
  isOpen.value = !isOpen.value
}
const resetOption = () => {
  isOpen.value = true
  return (selectedRef.value[props.idx] = props.dropdowns.options[props.idx][0])
}
const selectOption = (option) => {
  isOpen.value = false
  if (selectedRef.value[props.idx] !== option) {
    return (selectedRef.value[props.idx] = option)
  }
}

const closeFiltersOnClick = (e) => {
  if (dropdown.value) {
    if (!dropdown.value.contains(e.target) && isOpen.value) {
      isOpen.value = false
    }
  }
}
onMounted(() => {
  document.body.addEventListener(
    'click',
    (event) => {
      closeFiltersOnClick(event)
    },
    true
  )
})

onBeforeMount(() => {
  document.body.removeEventListener(
    'click',
    (event) => {
      closeFiltersOnClick(event)
    },
    true
  )
})
</script>

<style lang="scss" scoped>
.dropdown-def-style {
  @apply relative box-content rounded-[18px] transition-all py-[15px] min-h-[50px]
      flex flex-col justify-between;

  .dropdown-arrow-container {
    @apply absolute right-[10px] top-0
        flex items-center justify-center
        h-20 w-[50px];

    .dropdown-arrow {
      @apply flex justify-center items-center
           bg-white w-[35px] h-[35px] rounded-full;
      img {
        @apply transition-all;
      }
    }
  }
}

.active > .dropdown-arrow-container,
.active > * > .dropdown-arrow-container {
  .dropdown-arrow {
    img {
      @apply rotate-180;
    }
  }
}

.dropdown {
  @apply relative flex-1 rounded-[15px] py-[15px]
        transition-all shadow-[0px_1px_1px_0px_rgba(255,255,255,0.15)_inset] [background:linear-gradient(90deg,rgba(30,107,92,0.06)_0%,rgba(30,107,92,0.06)_100%),rgba(255,255,255,0.62)]
        lg:overflow-visible lg:max-h-20;
  &-switcher {
    @apply flex flex-col gap-1 px-5;

    & > * {
      @apply pointer-events-none;
    }

    &-top {
      @apply flex gap-[10px];
      &-title {
        @apply font-FKGrotesk text-sm font-normal leading-5;
      }
      .clear-selection {
        @apply pointer-events-auto z-2 text-[color:var(--humble,#0e4343)] font-FKGrotesk text-sm not-italic font-normal leading-5 text-center 
                px-2 pt-px
                bg-progressive rounded-[100px] shadow-[0px_1px_1px_0px_rgba(30,107,92,0.6)];
      }
    }
    &-bottom {
      @apply font-FKGrotesk font-bold whitespace-nowrap overflow-hidden;
    }

    .text-hider {
      @apply absolute right-0 top-0
            w-[100px] h-full rounded-[15px]
            [background:linear-gradient(90deg,transparent_10%,#e3e8e7_40%)];
    }
  }

  &-options {
    @apply h-0 flex flex-col transition-all z-10 
          lg:max-h-[350px] lg:absolute lg:top-[calc(100%_-_15px)] lg:overflow-y-scroll lg:bg-white lg:w-full lg:rounded-br-[15px] lg:rounded-bl-[15px]
          lg:shadow-[0px_15px_23px_0px_rgba(0,0,0,0.1)];

    &::-webkit-scrollbar {
      @apply w-[10px];
    }

    &::-webkit-scrollbar-track {
      @apply bg-transparent rounded-2xl border-[none];
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-clip-content bg-[rgba(30,107,92,0.47)] rounded-lg border-[3px] border-solid border-transparent;
    }
    .option {
      @apply flex border-t-[1px] border-gray_green_trans p-px;
      &-inner {
        @apply font-FKGrotesk font-[400] text-left w-full px-[19px] rounded-[5px] transition-all;
      }
      &:first-child {
        @apply lg:mt-[15px];
      }
      .option-inner {
        @apply py-1;
      }
      &.active {
        .option-inner {
          @apply bg-balanced text-functional;
        }
      }
    }
  }

  &.active {
    @apply lg:max-h-max [background:linear-gradient(0deg,rgba(255,255,255,1)_0%,rgba(255,255,255,1)_100%)];

    .dropdown-options {
      @apply h-auto;
    }

    .text-hider {
      @apply [background:linear-gradient(90deg,transparent_10%,white_40%)];
    }
  }

  .dropdown-options {
    @apply pl-[10px];
  }

  &:nth-child(2) {
    @apply lg:max-w-[35%] lg:min-w-[35%];
  }
}
</style>
