<template>
  <section class="hero module-container">
    <div class="hero-top">
      <div class="inner-container">
        <div class="hero-text">
          <div
            ref="lottieContainer"
            class="lottieHero" />
          <div
            class="hero-title header-2"
            v-html="splitText(props.data.hero_header)" />
          <div class="bicolor-line-container">
            <BicolorLine />
          </div>
        </div>
      </div>
      <div class="arrow-down">
        <img
          class=""
          alt="Arrow Down Icon"
          src="@/assets/img/icon-arrow-down-green.svg" />
      </div>
    </div>
    <div class="hero-image">
      <img
        :src="props.data.hero_image.url"
        :alt="props.data.hero_image.alt" />
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HeroComponent",
    "CLASSNAME": "hero",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */
import { onMounted, onBeforeUnmount, ref } from 'vue'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useUtils from '@/components/composables/useUtils.js'
import useIntroShown from '@/components/composables/useIntroShown.js'

gsap.registerPlugin(ScrollTrigger)
gsap.config({
  force3D: true,
})

const { heroAnimationComplete, genUUID } = useUtils()
const { introShown, preIntroShown } = useIntroShown()
const animId = ref(genUUID())
const lottieContainer = ref()

const splitText = (text) => {
  //remove H1
  const noH1 = text.replace(/<[/\n\r]*h1[^>]*>/g, '')

  // split text by <br /> tag
  const splitText = noH1.split('<br />')

  // add span tags and H1 tags
  let finalSplit = ''
  splitText.forEach(
    (x, index) =>
      (finalSplit += `${index === 0 ? '<h1>' : ''}
        <span><span>${x}</span></span>
        ${index === splitText.length - 1 ? '</h1>' : ''}`)
  )
  return finalSplit
}

const initialTextAnimation = () => {
  if (!heroAnimationComplete.value) {
    heroAnimationComplete.value = true
  }

  gsap.fromTo(
    '.hero .bicolor-line-container .bicolor-line',
    {
      x: '-100%',
    },
    {
      x: 0,
      duration: 2,
      ease: 'Power4.easeOut',
    }
  )

  gsap.fromTo(
    '.hero-title > h1 > span > span',
    {
      rotate: 0.001,
      y: '115%',
    },
    {
      y: 0,
      duration: 2,
      ease: 'Power4.easeOut',
      stagger: {
        amount: 0.2,
      },
    }
  )
}

const initialImageAnimation = () => {
  gsap.fromTo(
    '.hero-image > img',
    {
      scale: '1.1',
    },
    {
      scale: '1',
      duration: 2,
      ease: 'Power4.easeOut',
    }
  )
}

const parallax = () => {
  gsap.fromTo(
    '.hero-image > img',
    {
      y: -150,
    },
    {
      y: 150,
      scrollTrigger: {
        trigger: '.hero-image > img',
        scrub: true,
        start: 'top-=300 top',
        end: 'bottom top',
        id: animId.value,
        markers: false,
      },
    }
  )
}

const animationImage = () => {
  if (preIntroShown.value) {
    initialImageAnimation()
  } else {
    setTimeout(() => {
      animationImage()
    }, 100)
  }
}

const animationPack = () => {
  if (introShown.value) {
    initialTextAnimation()
  } else {
    setTimeout(() => {
      animationPack()
    }, 100)
  }
}

onMounted(() => {
  gsap.set('.hero .bicolor-line-container .bicolor-line', {
    x: '-100%',
  })

  gsap.set('.hero-title > h1 > span > span', {
    rotate: 0.001,
    y: '115%',
  })
  gsap.set('.hero-image > img', {
    scale: '1.1',
  })
  animationPack()
  animationImage()
  parallax()
})

onBeforeUnmount(() => {
  const parallax = ScrollTrigger.getById(animId.value)
  if (parallax) parallax.kill()
})

const props = defineProps(['data'])
</script>

<style lang="scss" scoped>
.hero {
  @apply block;
  .hero-top {
    @apply bg-white relative pt-[10px] pb-[65px] rounded-br-[70px]
    lg:pb-[170px] lg:rounded-br-[100px] z-1;

    .hero-text {
      @apply relative
      md:max-w-[490px]
      lg:pb-10 lg:max-w-[680px];
      .lottieHero {
        @apply absolute -top-4 lg:-top-7 -left-6 w-full max-w-[500px] h-full;
      }
      .hero-title {
        &:deep(h1) {
          @apply font-FKDisplayALT text-[34px] leading-[34px] tracking-[-0.8px] 
          lg:text-6xl lg:not-italic lg:font-normal lg:leading-[60px] lg:tracking-[-1.2px];
          br {
            @apply hidden lg:block;
          }
          strong {
            @apply font-normal text-progressive;
          }
          span {
            @apply text-balanced overflow-hidden block h-[46px] lg:h-[72px];
            span {
              @apply inline-block h-[46px] lg:h-[72px];
              strong {
                @apply font-normal text-progressive;
              }
            }
          }
        }
      }
      .bicolor-line-container {
        @apply relative overflow-hidden w-full;
      }
      .bicolor-line {
        @apply mt-6 h-[4px] w-[120px]
        lg:mt-6 lg:h-[5px] lg:w-[220px];
      }
    }

    .arrow-down {
      @apply absolute bottom-[46px] right-5 w-10 h-10 rounded-[50%]
      flex justify-center items-center bg-functional
      lg:bottom-16 lg:right-16;

      @keyframes moveUpDown {
        0% {
          transform: translateY(-2px);
        }
        50% {
          transform: translateY(2px);
        }
        100% {
          transform: translateY(-2px);
        }
      }

      img {
        animation: moveUpDown 2s ease-in-out infinite;
      }
    }
  }

  .hero-image {
    @apply h-[500px] lg:h-[100vh] w-full relative -z-0 mt-[-100px] overflow-hidden;

    img {
      @apply h-full w-full object-cover;
    }
  }
}
</style>
