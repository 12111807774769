<template>
  <a
    v-if="to"
    :href="to"
    class="back-button">
    <div class="back-button-icon">
      <img src="@/assets/img/icon-arrow-left-approachable.svg" />
    </div>
    <span class="back-button-text"> {{ text ? text : 'Back' }} </span>
  </a>
  <button
    v-else
    class="back-button"
    @click="router.back()">
    <div class="back-button-icon">
      <img src="@/assets/img/icon-arrow-left-approachable.svg" />
    </div>
    <span class="back-button-text"> {{ text ? text : 'Back' }} </span>
  </button>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
defineProps(['text', 'to'])
</script>

<style lang="scss" scoped>
.back-button {
  @apply flex items-center justify-between bg-humbe_trans
  h-[50px] max-w-[114px] pl-2 pr-4 rounded-full;
  width: 142px;
  &-icon {
    @apply w-[35px] h-[35px] flex items-center justify-center rounded-full 
     bg-white shadow-[0px_2px_2px_0px_rgba(30,107,92,0.2)];
  }
  &-text {
    @apply font-FKGrotesk text-[white] text-[16px];
  }
}
</style>
