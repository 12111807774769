<template>
  <section
    class="text-w-icon-wrapper"
    :class="{ 'bg-fade': data.bg_fade }">
    <!-- <SpacerComponent
      v-if="data.bg_fade"
      :data="spacerConfigs[0]" /> -->
    <section
      class="text-w-icon module-container"
      :class="data.text_pos ? 'layout-variant' : null">
      <div class="inner-container">
        <div
          class="text-w-icon-data"
          :class="data.spline ? 'gap-x-10' : 'gap-x-48'">
          <div class="text-w-icon-copy flex-1">
            <div class="headline">
              <BicolorLine
                v-if="data.bg_fade"
                left="humble"
                right="progressive" />
              <h2
                class="header-2"
                v-html="data.content.title" />
            </div>
            <div
              class="text"
              v-html="data.content.text" />
            <div
              v-if="data.content.cta"
              class="cta">
              <ButtonGlobal
                :text="data.content.cta.title"
                :target="data.content.cta.target"
                :url="data.content.cta.url" />
            </div>
          </div>
          <img
            v-if="!data.spline && data.image"
            :src="data.image.url"
            :alt="data.image.alt"
            class="image-container flex-1" />
          <div
            v-else-if="data.spline"
            class="flex-1">
            <canvas
              ref="canvas"
              class="spline-animation pointer-events-none"></canvas>
          </div>
          <div
            v-else
            class="image-container animated-icon flex-1">
            <div
              :id="animTriggerClass"
              ref="lottieContainer"
              class="lottie-container"></div>
          </div>
        </div>
      </div>
    </section>
    <SpacerComponent
      v-if="data.bg_fade"
      :data="spacerConfigs[1]" />
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TextWithIconComponent",
    "CLASSNAME": "text-w-icon-wrapper",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'
import SpacerComponent from '@/components/modules/SpacerComponent.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import useUtils from '@/components/composables/useUtils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LottieWeb from 'lottie-web'
import logoJson from '@/assets/lottie/REVMED_LOGO.json'
import BicolorLine from '@/components/utility/BicolorLine.vue'

gsap.registerPlugin(ScrollTrigger)

const { genUUID } = useUtils()
const animID = ref(genUUID())
const lottieAnimID = ref(genUUID())
const lottieContainer = ref()
const props = defineProps(['data'])
const spline = ref()
const canvas = ref()

const newBgColor = '#70FFE5'

const animTriggerClass = `anim-trigger-${animID.value}`
const spacerConfigs = [
  {
    separator: false,
    rounded: 'tr',
    spacer_height: '120',
    spacer_height_tablet: '120',
    spacer_height_mobile: '120',
    back_background_color: 'rgba(255, 255, 255, 0)',
    background_color: newBgColor,
    separator_left: '#000',
    separator_right: '#000',
  },
  {
    separator: false,
    spacer_height: '120',
    spacer_height_tablet: '120',
    spacer_height_mobile: '120',
    back_background_color: 'rgba(255, 255, 255, 0)',
    background_color: newBgColor,
    separator_left: '#000',
    separator_right: '#000',
  },
]

const animateOnScroll = () => {
  console.log(animTriggerClass)
  const anim = LottieWeb.loadAnimation({
    container: lottieContainer.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: logoJson,
  })

  ScrollTrigger.create({
    trigger: `#${animTriggerClass}`,
    start: 'top-=200 center',
    end: 'bottom+=100 center',
    onUpdate: ({ progress }) => {
      anim.goToAndStop((progress + 0.3) * (anim.totalFrames - 1), true)
    },
    markers: false,
    id: lottieAnimID.value,
  })
}

// const bgFade = () => {
//   gsap.to('body', {
//     background: newBgColor,
//     scrollTrigger: {
//       // id: `bicolor-${uniqueId.value}`,
//       trigger: `#${animTriggerClass}`,
//       start: 'top-=30% center',
//       end: 'bottom-=30% center',
//       scrub: 1,
//       // markers: true,
//     },
//   })
// }

// const parallaxAnim = (isVariant, hasImage) => {
//   if (isVariant && hasImage) {
//     gsap.fromTo(
//       '.text-w-icon-data > img',
//       {
//         y: device.value === 'mobile' || device.value === 'tablet' ? -125 : -200,
//       },
//       {
//         y: device.value === 'mobile' || device.value === 'tablet' ? 25 : 50,
//         scrollTrigger: {
//           trigger: `#${animTriggerClass}`,
//           scrub: 0.75,
//           start: 'top-=200 bottom',
//           end: 'bottom top',
//           id: animID.value,
//         },
//       }
//     )
//   }
// }

const changeBgColor = () => {
  const elem = document.querySelector('.site-footer')
  if (!elem) {
    setTimeout(() => {
      changeBgColor()
    }, 100)
  } else {
    gsap.set('.site-footer', {
      background: newBgColor,
    })
  }
}

const mountSpline = () => {
  if (!canvas.value) {
    setTimeout(() => {
      mountSpline()
    }, 100)
  } else {
    import('@splinetool/runtime')
      .then(({ Application }) => {
        spline.value = new Application(canvas.value)
        spline.value.load(props.data.splinecode.url)
      })
      .catch((error) => console.log(error))
  }
}

onMounted(() => {
  mountSpline()
  if (!props.data.spline && !props.data.image) {
    animateOnScroll()
  }
  //console.log(props.data.bg_fade)
  if (props.data.bg_fade) {
    changeBgColor()
    //   bgFade()
  }
  // parallaxAnim(props.data.text_pos, props.data.image)
})

onBeforeUnmount(() => {
  gsap.set('.site-footer', {
    background: 'transparent',
  })
  const lottieAnim = ScrollTrigger.getById(lottieAnimID.value)
  if (lottieAnim) lottieAnim.kill()
  const imgAnim = ScrollTrigger.getById(animID.value)
  if (imgAnim) imgAnim.kill()
})
</script>

<style lang="scss" scoped>
.text-w-icon-wrapper {
  @apply bg-functional;
  &.bg-fade {
    .text-w-icon {
      @apply bg-approachable;
    }
  }
  .text-w-icon {
    @apply relative py-[10px];

    .text-w-icon-data {
      @apply flex flex-col justify-between items-center
    lg:flex-row lg:items-center;
      .text-w-icon-copy {
        @apply lg:max-w-[490px];
        .headline {
          @apply mb-8;
        }

        .text {
          @apply flex flex-col gap-8
        mb-12
        lg:mb-8;
          &:deep {
            @include wysiwyg {
              @apply body;
            }
          }
        }

        .cta {
          @apply mb-16
        lg:mb-0;
        }
      }

      .image-container {
        @apply flex justify-center items-center w-full max-w-3xl h-[400px] rounded-[20px]  object-cover
      lg:max-w-[400px] lg:min-h-full;
        &.animated-icon {
          @apply bg-balanced;
        }
      }
    }

    &.layout-variant {
      .text-w-icon-data {
        @apply flex-col-reverse
    lg:flex-row-reverse;
        .text-w-icon-copy {
          @apply mt-12
        lg:mt-0;

          .cta {
            @apply mb-0;
          }
        }
        .image-container {
          @apply rounded-tr-[50px];
        }
      }
    }
  }

  .bicolor-line {
    @apply w-[66px] h-[3px] mt-10 mb-5
         lg:mt-8;
  }

  .spline-animation {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1/1;
  }
}
</style>
