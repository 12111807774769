<template>
  <main class="single-team">
    <DecorationComponent :data="decorationProps" />
    <section class="module-container">
      <div class="inner-container back-button-container">
        <BackButton to="/about/#team-section" />
      </div>
    </section>
    <TeamBio :member="member" />
    <TeamCards
      :member="member"
      :members="members"
      :category="catData.name"
      :have-img="!isNoImage(member)" />
    <section class="module-container">
      <div class="inner-container bottom-button-container">
        <ButtonGlobal
          text="Back to About Us"
          target="_self"
          url="/about/#team-section" />
      </div>
    </section>
  </main>
</template>

<script setup>
import { computed, ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../stores/main'
// import pageview from 'vue-gtag'
import useUtils from '@/components/composables/useUtils.js'
import BackButton from '@/components/utility/BackButton.vue'
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'
import TeamBio from '@/components/modules/SingleTeam/TeamBio.vue'
import TeamCards from '@/components/modules/SingleTeam/TeamCards.vue'
import DecorationComponent from '@/components/modules/DecorationComponent.vue'
import decoration from '@/assets/img/single-team-outlined-logo.svg'

const router = useRouter()
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
    default: '',
  },
})

const { decodeTitle /*convertToRelative */ } = useUtils()

const request = ref({
  type: 'team',
  slug: props.slug,
  showLoading: true,
})

const decorationProps = {
  image: {
    url: decoration,
  },
  image_positioning: {
    dt: {
      alignment_x: true,
      alignment_y: true,
      pos_x: '0',
      pos_y: '18',
    },
    mobile: {
      alignment_x: true,
      alignment_y: true,
      pos_x: '0',
      pos_y: '30',
    },
  },
}

const members = ref()

const member = computed(() => {
  return store.getCurrentSingleBySlug(request.value)
})

const catData = ref({
  id: null,
  name: '',
})

const getCategoryName = async () => {
  catData.value.id = member.value.category[0]
  const categoryRequest = {
    type: 'category',
    id: catData.value.id,
  }
  await store.getSingleById(categoryRequest)
  const data = store.singleById(categoryRequest)
  catData.value.name = data.name
}

const getMember = async () => {
  await store.getSingleBySlug(request.value)
  if (member.value) {
    store.updateDocTitle({
      parts: [decodeTitle(member.value.title.rendered), store.site.name],
    })
    // .then(() => {
    // pageview({
    //   page_path: convertToRelative(member.value.link),
    //   page_title: document.title,
    //   page_location: window.location.href,
    // })
    // })
  } else {
    router.replace('/404')
    // pageview({
    //   page_path: '/404',
    //   page_title: document.title,
    //   page_location: window.location.href,
    // })
  }
}

const getCategory = async (member) => {
  let catRequest = {
    type: 'team',
    params: { category: member.value.category[0], per_page: 100 },
    showLoading: true,
  }
  await store.getItems(catRequest)
  members.value = store.requestedItems(catRequest)
}

const isNoImage = (member) => {
  // code for local:
  // return member?.category[0] === 9 || member?.category[0] === 8

  //code for staging (board of advisors ID is 7 instead of 8)
  return member?.category[0] === 9 || member?.category[0] === 7
}

onBeforeMount(async () => {
  await getMember()
  await getCategory(member)
  await getCategoryName()
})
</script>

<style lang="scss" scoped>
.single-team {
  @apply bg-white
  pb-16
    lg:pb-[120px];
  .back-button-container {
    @apply pb-6 pt-[87px]
    lg:pt-[120px]
    xl:px-0 xl:mx-[30px];
    @media (min-width: 2000px) {
      @apply mx-auto;
    }
  }
  .bottom-button-container {
    @apply flex justify-center;
    .button {
      @apply mt-16 transition-all;
      &:hover {
        @apply mr-0;
      }
    }
  }
  .advisor-bg-top {
    @apply bg-functional;
  }
  .advisor-bg {
    @apply bg-functional rounded-bl-[50px] pb-[75px] mb-[-75px]

    lg:rounded-bl-[100px] lg:pb-[90px];
  }
}
</style>
