<template>
  <section class="publications-list">
    <div
      v-if="publications.length < 1 && mounted"
      class="publications no-items">
      <p class="header-2">No Publications found with specified filters.</p>
    </div>

    <ul
      v-if="!isLoading"
      class="publications">
      <li
        v-for="(pub, idx) in paginatedPubs[currentPage]"
        :key="idx"
        class="publication">
        <div class="publication-meta">
          <div class="meta date">
            <span v-html="pub.acf.publication_date" />
          </div>
          <div class="meta conf_pub">
            <p v-html="toCommaSeparated(pub.acf.conference_publications)" />
          </div>
          <div class="meta programs">
            <span v-html="toCommaSeparated(pub.acf.program)" />
          </div>
        </div>
        <div class="publication-data">
          <div
            class="text"
            v-html="pub.post_title" />
          <ButtonGlobal
            text="View"
            target="_blank"
            :url="
              pub.acf.link_or_attachment
                ? pub.acf.file_attachment.url
                : pub.acf.external_link
            " />
        </div>
      </li>
    </ul>
    <ul
      v-else
      class="publications loading">
      <li
        role="status"
        class="publication pub-skeleton">
        <div class="publication-meta">
          <div class="meta date skeleton sk-row">
            <span />
          </div>
          <div class="meta conf_pub skeleton sk-row">
            <p />
          </div>
          <div class="meta programs skeleton sk-row">
            <span />
          </div>
        </div>
        <div class="publication-data">
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[50%] skeleton sk-row" />
          <div class="w-[23%] skeleton sk-row" />
        </div>
      </li>
      <li
        role="status"
        class="publication pub-skeleton">
        <div class="publication-meta">
          <div class="meta date skeleton sk-row">
            <span />
          </div>
          <div class="meta conf_pub skeleton sk-row">
            <p />
          </div>
          <div class="meta programs skeleton sk-row">
            <span />
          </div>
        </div>
        <div class="publication-data">
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[50%] skeleton sk-row" />
          <div class="w-[23%] skeleton sk-row" />
        </div>
      </li>
      <li
        role="status"
        class="publication pub-skeleton">
        <div class="publication-meta">
          <div class="meta date skeleton sk-row">
            <span />
          </div>
          <div class="meta conf_pub skeleton sk-row">
            <p />
          </div>
          <div class="meta programs skeleton sk-row">
            <span />
          </div>
        </div>
        <div class="publication-data">
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[100%] skeleton sk-row" />
          <div class="w-[50%] skeleton sk-row" />
          <div class="w-[23%] skeleton sk-row" />
        </div>
      </li>
    </ul>
    <div
      v-if="paginatedPubs.length > 1 && !isLoading"
      class="paginator-container">
      <div class="paginator-wrapper">
        <div class="paginator">
          <button
            class="page-select prev"
            @click="prevPage()">
            <div class="arrow-container">
              <img src="@/assets/img/caret-down-progressive.svg" />
            </div>
          </button>
          <ul
            v-if="paginatedPubs.length > 0"
            class="pages">
            <li
              v-for="pageIdx in availablePages"
              :key="`page-${pageIdx}`">
              <button
                :class="{ active: currentPage === pageIdx }"
                @click="updatePage(pageIdx)">
                {{ pageIdx + 1 }}
              </button>
            </li>
          </ul>
          <button
            class="page-select next"
            @click="nextPage()">
            <div class="arrow-container">
              <img src="@/assets/img/caret-down-progressive.svg" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'
import { onBeforeUpdate, ref } from 'vue'
const props = defineProps(['publications', 'itemsPerPage', 'isLoading'])
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

const { locoScroll } = useSmoothScroll()

const paginate = (pubs, size) => {
  return pubs.reduce((acc, val, i) => {
    let idx = Math.floor(i / size)
    let page = acc[idx] || (acc[idx] = [])
    page.push(val)

    return acc
  }, [])
}

let paginatedPubs = ref([])

let currentPage = ref(0)

const updatePage = (num) => {
  currentPage.value = num
  locoScroll.value.scrollTo('top')
}

const availablePages = ref([])

const toCommaSeparated = (arr) => {
  return arr.join(', ')
}

const nextPage = () => {
  if (paginatedPubs.value.length - 1 === currentPage.value) {
    currentPage.value = 0
  } else {
    currentPage.value++
    locoScroll.value.scrollTo('top')
  }
}

const prevPage = () => {
  if (currentPage.value === 0) {
    currentPage.value = paginatedPubs.value.length - 1
  } else {
    currentPage.value--
    locoScroll.value.scrollTo('top')
  }
}

const mounted = ref(false)

onBeforeUpdate(() => {
  mounted.value = true
  paginatedPubs.value = paginate(props.publications, props.itemsPerPage)
  availablePages.value = paginatedPubs.value.map((pub, idx) => {
    return idx
  })
})
</script>

<style lang="scss" scoped>
.publications-list {
  @apply flex flex-col gap-10;
  .publications {
    @apply flex flex-col gap-[5px] min-h-[50px]
    lg:min-h-[460px];
    .publication {
      @apply flex flex-col gap-6
      bg-white pt-8 pb-6 px-6 rounded-[20px] lg:min-h-[230px]
      lg:flex-row lg:gap-[100px] lg:pt-12 lg:pb-6 lg:pl-[50px] lg:pr-[88px];

      &-meta {
        @apply flex flex-col gap-[10px] pb-[10px] border-b-[1px] border-[#D3D3D3]
        lg:flex-[0_0_25%] lg:border-none lg:gap-[20px];
        .meta {
          span,
          p {
            @apply text-balanced font-FKGrotesk text-[13px] not-italic font-normal leading-[120%] tracking-[-0.26px]
            lg:text-lg lg:leading-[22px] lg:tracking-[-0.18px];
          }
        }
        .programs {
          @apply flex flex-wrap gap-1;
        }
      }

      &-data {
        @apply flex flex-col items-center gap-7
        lg:items-start lg:justify-between lg:flex-1;
        .text {
          @apply text-balanced  font-bold w-full header-6
          lg:text-[22px] lg:leading-[26px] lg:tracking-[-0.44px];
        }
      }
    }
    .pub-skeleton {
      .publication-data {
        @apply items-start gap-0;
      }
    }
  }
  .paginator-container {
    @apply flex flex-col items-center;
    .paginator-wrapper {
      @apply relative p-2 bg-gray_green_trans rounded-[18px] min-w-[290px] max-w-[100%];
      .paginator {
        @apply flex justify-between items-center rounded-[15px] h-20 px-[20px] gap-[20px]
        [background:linear-gradient(90deg,rgba(30,107,92,0.06)_0%,rgba(30,107,92,0.06)_100%),rgba(255,255,255,0.62)];

        .page-select {
          .arrow-container {
            @apply flex justify-center items-center
           bg-white w-[35px] h-[35px] rounded-full;
            img {
              @apply transition-all;
            }
          }
          &.prev {
            img {
              @apply rotate-90;
            }
          }
          &.next {
            img {
              @apply -rotate-90;
            }
          }
        }

        .pages {
          @apply flex justify-center gap-[2px];
          li {
            button {
              @apply pt-[1.5px] h-[27px] w-[27px] border-[1px] border-transparent rounded-full transition-all;

              &.active {
                @apply border-humble;
              }
            }
          }
        }
      }
    }
  }
}

.skeleton {
  @apply max-w-sm animate-pulse;
  &.sk-title {
    @apply h-2.5 bg-humbe_trans/20 rounded-full dark:bg-progressive/30 w-48 mb-4;
  }
  &.sk-row {
    @apply h-2 bg-humbe_trans/20 rounded-full dark:bg-progressive/30 mb-2.5;
  }
}
</style>
