<template>
  <header
    id="header-nav"
    ref="header"
    class="no-cursor header-nav white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      device === 'tablet' || device === 'mobile' ? 'mobile' : ''
    }
    `">
    <nav ref="navWrapper">
      <div class="branding">
        <div class="logo-container">
          <a href="/">
            <div v-show="device === 'desktop'">
              <div v-if="true">
                <div
                  ref="lottieContainer"
                  class="lottie-container" />
              </div>
              <div v-else>
                <img
                  v-if="site.site_logo?.url && !sticky"
                  :src="site.site_logo?.url || ''" />
                <img
                  v-else
                  :src="site.site_logo_sticky?.url || ''" />
              </div>
            </div>
            <div v-show="device !== 'desktop'">
              <div
                v-if="true"
                class="logo-container-mobile">
                <div
                  id="lottie-container"
                  ref="lottieContainerMobile"
                  class="lottie-container"
                  :class="{ 'logo-not-visible': navOpen }" />
                <img
                  class="logo-white"
                  :class="{ 'logo-visible': navOpen }"
                  src="@/assets/img/revmed-white.svg" />
              </div>
              <div v-else>
                <img :src="site.site_logo_sticky?.url || ''" />
              </div>
            </div>
          </a>
        </div>
        <div
          class="mobile-nav"
          @click="toggle">
          <span class="line line-one" />
          <span class="line line-two" />
          <span class="line line-three" />
        </div>
      </div>
      <ul
        v-if="device === 'desktop'"
        ref="nav"
        class="nav-container">
        <template v-if="preIntroShown">
          <transition-group
            v-for="(item, index) in topLevelMenu(menu)"
            :key="item.id"
            tag="li"
            appear
            @before-enter="beforeEnter"
            @enter="enter"
            @click="item.class[0] === '' ? close(false) : null">
            <template v-if="item.parent === '0'">
              <template v-if="item.class[0] != 'search_link'">
                <NavMenuItem
                  :key="item.id"
                  :data-ind="index"
                  :menu="menu"
                  :item="item"
                  :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                  @clicked="close" />
              </template>
              <template v-else-if="item.class[0] === 'orange'">
                <NavMenuItem
                  :key="item.id"
                  :data-ind="index"
                  :menu="menu"
                  :item="item"
                  :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                  @clicked="close" />
              </template>
              <template v-else>
                <img
                  id="searchIcon"
                  :key="item.id"
                  :src="searchIcon"
                  alt="Search icon"
                  @click="showSearch" />

                <Search
                  v-if="displaySearch == true"
                  @display-search="showSearch"></Search>
              </template>
            </template>
          </transition-group>
        </template>
      </ul>
      <div
        v-else
        class="nav-wrapper">
        <!-- <focus-trap v-model:active="navOpen"> -->
        <ul
          ref="nav"
          class="nav-container nav-mobile">
          <template v-if="introShown">
            <transition-group
              v-for="(item, index) in topLevelMenu(mobileMenu)"
              :key="item.id"
              tag="li"
              appear
              @before-enter="beforeEnter"
              @enter="enter"
              @click="item.class[0] === '' ? close(false) : null">
              <template v-if="item.parent === '0'">
                <template v-if="item.class[0] != 'search_link'">
                  <NavMenuItem
                    :key="item.id"
                    :data-ind="index"
                    :menu="mobileMenu"
                    :item="item"
                    :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                    @clicked="close" />
                </template>
                <template v-else-if="item.class[0] === 'orange'">
                  <NavMenuItem
                    :key="item.id"
                    :data-ind="index"
                    :menu="mobileMenu"
                    :item="item"
                    :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                    @clicked="close" />
                </template>
                <template v-else>
                  <img
                    id="searchIcon"
                    :key="item.id"
                    :src="searchIcon"
                    alt="Search icon"
                    @click="showSearch" />

                  <Search
                    v-if="displaySearch == true"
                    @display-search="showSearch"></Search>
                </template>
              </template>
            </transition-group>
          </template>
        </ul>
        <!-- </focus-trap> -->
      </div>
    </nav>
  </header>
</template>

<script setup>
// import { FocusTrap } from 'focus-trap-vue'
import NavMenuItem from './NavMenuItem.vue'
import Search from './NavSearch.vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LottieWeb from 'lottie-web'
import headerLogoJson from '@/assets/lottie/header-logo.json'
import searchIcon from '@/assets/img/search.svg'
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/components/composables/useUtils.js'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll'

gsap.registerPlugin(ScrollTrigger)

const { scrollY, device, genUUID } = useUtils()
const { introShown, preIntroShown } = useIntroShown()
const { locoScroll } = useSmoothScroll()

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const mobileMenu = ref(store.menu({ name: 'mobile-menu' }))
const site = ref(store.acfOptions())
const header = ref()
const sticky = ref()
const navOpen = ref()
const displaySearch = ref()
const scrollPostion = ref(0)

const headerLottieAnimID = ref(`logo-anim-${genUUID().substring(0, 5)}`)
const lottieContainer = ref()
const lottieContainerMobile = ref()

const animateOnScroll = () => {
  if (!lottieContainer.value) {
    setTimeout(() => {
      console.log('refreshing...')
      animateOnScroll()
    }, 100)
  } else {
    const anim = LottieWeb.loadAnimation({
      container: lottieContainer.value,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      animationData: headerLogoJson,
    })

    const animMob = LottieWeb.loadAnimation({
      container: lottieContainerMobile.value,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      animationData: headerLogoJson,
    })

    ScrollTrigger.create({
      trigger: 'body',
      start: 'top+=140 top',
      end: 'top+=1000 top',
      onUpdate: ({ progress }) => {
        animMob.goToAndStop(progress * (anim.totalFrames - 1), true)
      },
      markers: false,
      id: headerLottieAnimID.value,
    })

    ScrollTrigger.create({
      trigger: 'body',
      start: 'top+=140 top',
      end: 'top+=1000 top',
      onUpdate: ({ progress }) => {
        anim.goToAndStop(progress * (anim.totalFrames - 1), true)
      },
      markers: false,
      id: headerLottieAnimID.value,
    })
  }
}

const introTransition = () => {
  if (introShown.value) {
    gsap.fromTo(
      lottieContainer.value,
      { y: -200 },
      { y: 0, duration: 1, delay: 0.5 }
    )
    gsap.fromTo(
      lottieContainerMobile.value,
      { y: -200 },
      { y: 0, duration: 1, delay: 0.5 }
    )
  } else {
    setTimeout(() => {
      introTransition()
    }, 100)
  }
}

watch(scrollY, (newVal) => {
  scrollPostion.value = newVal
})

onMounted(() => {
  gsap.set(lottieContainer.value, { y: -200 })
  gsap.set(lottieContainerMobile.value, { y: -200 })
  introTransition()
  animateOnScroll()
  stickyOnScroll()
})

onBeforeUnmount(() => {
  const lottieAnim = ScrollTrigger.getById(headerLottieAnimID.value)
  if (lottieAnim) lottieAnim.kill()
})

const showSearch = () => {
  this.displaySearch = !this.displaySearch

  const html = document.querySelector('html')
  if (this.displaySearch) {
    html.classList.add('no-overflow')
  } else {
    html.classList.remove('no-overflow')
  }
}
const topLevelMenu = (menu) => {
  const topMenu = menu.filter((x) => x.parent === '0')
  return topMenu
}
const toggle = () => {
  navOpen.value = !navOpen.value
  if (navOpen.value) {
    locoScroll.value.stop()
  } else {
    locoScroll.value.start()
  }
}
const close = (data) => {
  if (data !== undefined) navOpen.value = data
  else navOpen.value = !navOpen.value
  locoScroll.value.start()
}
const beforeEnter = (el) => {
  // add the initial style to the elements
  el.style.transform = 'translateY(10px)'
  el.style.opacity = 0
}
const enter = (el) => {
  gsap.to(el, {
    y: 0,
    opacity: 1,
    duration: 0.3,
    delay: el.dataset.ind * 0.3,
  })
}
const stickyOnScroll = () => {
  if (scrollPostion.value > 0 && device !== 'tablet' && device !== 'mobile') {
    header.value.classList.add('sticky')
    sticky.value = true
  } else {
    header.value.classList.remove('sticky')
    sticky.value = false
  }
}
</script>

<style lang="scss" scoped>
#header-nav.mobile {
  @apply fixed top-0 text-black px-[6px];
}

#header-nav {
  @apply fixed w-full mt-3 px-[20px];

  .logo-container img:not(.logo-white) {
    @apply h-[37px] lg:h-[45px];
  }
}
#header-nav.sticky {
  @apply top-0 mt-0 lg:fixed transition duration-100 fixed;
  @apply lg:text-black transition-colors duration-100;
  nav {
    @apply max-h-[70px] border-none;
    .logo-container {
      img {
        @apply w-[80%] h-full max-h-[50px] lg:mt-0;
      }
    }
  }
}
#header-nav.nav-open {
  .line-one {
    @apply transform w-7 rotate-45 translate-y-[10px] translate-x-[0px];

    &:before {
      @apply w-7 bg-white;
    }
  }
  .line-three {
    @apply transform -rotate-45 -translate-y-[10px] -translate-x-[0px];
    &:before {
      @apply w-7 bg-white;
    }
  }
  .line-two {
    @apply opacity-0;
  }
  .branding {
    @apply relative bg-[rgba(255,255,255,0.05)];
    box-shadow: 0px 2px 1px 0px #ffffff26 inset;
    // filter: brightness(100);
  }
  .nav-wrapper {
    @apply visible opacity-100;
  }
}

.header-nav {
  @apply flex mt-0 mb-0 items-stretch
         justify-center top-0 min-h-[50px] max-h-[50px]
         text-black
          lg:max-h-[110px]
         lg:text-black z-[40];
  nav {
    @apply w-full flex flex-col lg:flex-row items-stretch lg:h-[66px] lg:max-h-[66px]
    lg:items-center pt-0 xl:px-3 2xl:px-0 lg:py-0
    transition-all duration-300 ease-in-out
    backdrop-blur-[20px]
    rounded-[15px];
    background: linear-gradient(
        0deg,
        rgba(30, 107, 92, 0.06),
        rgba(30, 107, 92, 0.06)
      ),
      linear-gradient(
        270deg,
        rgba(255, 255, 255, 0.2) 44.96%,
        rgba(255, 255, 255, 0) 58.69%
      ),
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.2) 11.27%,
        rgba(255, 255, 255, 0) 23.4%
      ),
      rgba(255, 255, 255, 0.42);
    box-shadow: inset 0px 2px 1px rgba(255, 255, 255, 0.15);
    ul {
      @apply flex flex-col my-3 lg:my-0 lg:flex-row lg:gap-14 justify-start items-center lg:items-end md:px-0 lg:px-5 xl:px-0;

      li {
        @apply flex w-full lg:w-auto text-left;
      }
    }
  }
  .branding {
    @apply h-[50px] w-full lg:w-[30%] xl:w-[35%] text-center rounded-[15px]
           md:text-left flex items-center lg:max-h-[65px]
           justify-between p-3 xl:p-0 lg:shadow-none;
    .logo-container {
      @apply h-full xl:pl-4 xl:max-h-full flex items-center;
      position: relative;
      top: -10;
      img:not(.logo-white) {
        @apply w-[80%] lg:w-auto h-[30px] lg:h-[45px] lg:pl-0;
      }

      &-mobile {
        @apply relative;

        .lottie-container {
          @apply transition-all;
        }

        .logo-white {
          @apply opacity-0 absolute top-0 left-0 w-full h-full transition-all;
        }
        .logo-not-visible {
          @apply opacity-0;
        }

        .logo-visible {
          @apply opacity-100;
        }
      }
      .lottie-container {
        height: 45px;
        width: 150px;
      }
    }
    .mobile-nav {
      @apply flex flex-col lg:hidden float-right;
      .line {
        @apply mb-2 transition-all duration-500 ease-in-out;
      }
      .line:last-child {
        @apply mb-0;
      }
      .line:before {
        @apply content-[''] block h-[2px] w-[20px] bg-humble
        transition-all duration-500 ease-in-out;
      }
      .line:nth-child(2):before {
        @apply w-[25px];
      }
    }
  }
  .nav-container {
    @apply w-full
             text-center md:text-left
             transition duration-500 ease-in-out m-0 place-items-start justify-end
             lg:pr-[50px];
    height: fit-content;
  }
  .nav-wrapper {
    @apply transition-all duration-500 ease-in-out bg-humble
    invisible opacity-0 absolute top-[-12px] left-[-6px] -z-1
    w-[103vw] h-[100vh] m-0;
    .nav-mobile {
      @apply transition-all duration-500 ease-in-out
      relative px-[30px] pt-[85px] w-full max-h-[95vh] overflow-y-auto
      flex flex-col justify-start items-start;
    }
  }
}
#searchIcon {
  @apply mt-[5px] pl-5 cursor-pointer mx-auto py-4 lg:py-0;
}
</style>
