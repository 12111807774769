<template>
  <main>
    <header>
      <h1>{{ title }}</h1>
    </header>
    <section v-if="posts">
      <post-item
        v-for="post in posts"
        :key="post.id"
        :post="post" />
      <pagination
        v-if="totalPages > 1"
        :total="totalPages"
        :current="props.page" />
    </section>
  </main>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '../stores/main'
import PostItem from '@/components/template-parts/PostItem.vue'
import Pagination from '@/components/template-parts/PaginationItem.vue'

const store = useStore()
const props = defineProps({
  page: {
    type: Number,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  },
})

const postsRequest = ref({
  type: 'posts',
  params: {
    per_page: store.site.posts_per_page,
    page: props.value,
    categories: null,
  },
  showLoading: true,
})
const categoryRequest = ref({
  type: 'categories',
  slug: props.slug,
})
const totalPages = ref(0)

const category = computed(() => {
  return store.getCurrentSingleBySlug(categoryRequest.value)
})

const posts = computed(() => {
  if (category.value) {
    return store.requestedItems(postsRequest.value)
  }
  return []
})
const title = computed(() => {
  return `Archive for ${category.value ? category.value.name : ''}`
})

const getCategory = () => {
  return store.getSingleBySlug(categoryRequest.value).then(() => {
    setPostsRequestParams()
    store.updateDocTitle({
      parts: [category.value.name, store.site.name],
    })
  })
}

const getPosts = () => {
  return store.getItems(postsRequest.value)
}

const setPostsRequestParams = () =>
  (postsRequest.value.params.categories = category.value.id)
const setTotalPages = () =>
  (totalPages.value = store.totalPages(postsRequest.value))

onBeforeMount(() => {
  getCategory()
    .then(() => getPosts())
    .then(() => setTotalPages())
})
</script>
