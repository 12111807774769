<template>
  <div class="post-meta">
    <archive-link
      :archive-id="author"
      archive-type="users"
      class="post-author" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ArchiveLink from '@/components/utility/ArchiveLink.vue'

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})
const author = ref(props.post.author)
</script>

<style></style>
