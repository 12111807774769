<template>
  <section
    :id="animTriggerId"
    class="text-with-two-imgs-module-component module-container">
    <div class="inner-container">
      <div class="section-content">
        <BicolorLine />
        <div
          class="section-content-text"
          v-html="data.text" />
        <img
          src="@/assets/img/circle-arrow.svg"
          alt=""
          class="deco-cta" />
      </div>
      <div class="section-imgs">
        <img
          class="left"
          :src="data.imgs[0].url" />
        <img
          class="right"
          :src="data.imgs[1].url" />
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TextWithTwoImgsComponent",
    "CLASSNAME": "text-with-two-imgs-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

import { ref, onMounted, onBeforeUnmount } from 'vue'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import useUtils from '@/components/composables/useUtils'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import bicolorLineAnim from '@/assets/anims/bicolored-line-anim'

gsap.registerPlugin(ScrollTrigger)

const { genUUID, device } = useUtils()
const animIDs = ref({
  line: genUUID(),
  imgs: genUUID(),
  container: genUUID(),
})
const animTriggerId = `anim-trigger-${animIDs.value.container}`

defineProps(['data'])

const cardAnim = () => {
  const cssSelector = `#${animTriggerId} > div > div > .bicolor-line`
  bicolorLineAnim({
    cssSelector: cssSelector,
    trigger: `#${animTriggerId}`,
    id: animIDs.value.line,
  })
}

const imgsAnim = (offsetValue) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: `#${animTriggerId}`,
      start: 'top bottom',
      end: 'bottom-=150px 25%',
      scrub: 1,
      id: animIDs.value.imgs,
      // markers: true,
    },
  })

  tl.to('.section-imgs > .left', {
    y: -offsetValue,
  }).to(
    '.section-imgs > .right',
    {
      y: offsetValue,
    },
    '<'
  )
}

onMounted(() => {
  let animMoveValue = 250 / 2

  if (device.value === 'mobile') {
    animMoveValue = 25
  }
  cardAnim()
  imgsAnim(animMoveValue)
})

onBeforeUnmount(() => {
  const animLine = ScrollTrigger.getById(animIDs.value.line)
  const animImgs = ScrollTrigger.getById(animIDs.value.imgs)
  const animContainer = ScrollTrigger.getById(animIDs.value.container)
  if (animLine) animLine.kill()
  if (animImgs) animImgs.kill()
  if (animContainer) animContainer.kill()
})
</script>

<style lang="scss" scoped>
.text-with-two-imgs-module-component {
  @apply py-10 bg-white;
  .inner-container {
    @apply relative flex flex-col gap-16
    lg:flex-row lg:justify-between lg:px-0;
    .section-content {
      @apply flex flex-col gap-5
      lg:w-[390px];

      .bicolor-line {
        @apply w-[66px] h-[3px];
      }

      &-text {
        &:deep() {
          @include wysiwyg {
            @apply header-5 font-[400] normal-case;
          }
        }
      }

      .deco-cta {
        @apply hidden left-14 h-12 w-12
        lg:flex lg:items-center lg:justify-center lg:mt-20;
      }
    }
    .section-imgs {
      @apply flex justify-center gap-2 
      lg:gap-4 lg:-mt-[80px];
      img {
        @apply w-[171px] h-[177px] rounded-xl object-cover
        xl:w-[275px] xl:h-[281px] xl:rounded-[20px];
      }

      .left {
        @apply rounded-tr-[45px] lg:mt-[250px];
      }
    }
  }
}
</style>
