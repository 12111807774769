<template>
  <div
    ref="floatingButton"
    class="floating-button-module-component"
    @click="scrollTo()">
    <div class="inner-floating-button">
      {{ data.button_text }}
      <img
        src="@/assets/img/circle-arrow.svg"
        alt=""
        class="pl-6 lg:pl-0" />
    </div>
  </div>
</template>

<script setup>
/* #META
   {
    "NAME": "FloatingButtonComponent",
    "CLASSNAME": "floating-button-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

import { ref, onMounted, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useUtils from '@/components/composables/useUtils'

import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
gsap.registerPlugin(ScrollTrigger)
const { locoScroll } = useSmoothScroll()
const props = defineProps(['data'])
const floatingButton = ref()

const { genUUID } = useUtils()
const animID = ref(genUUID())

const scrollTo = () => {
  locoScroll.value.scrollTo(document.querySelector(`#${props.data.target}`))
}

onMounted(() => {
  gsap.fromTo(
    floatingButton.value,
    {
      y: 0,
    },
    {
      y: 80,
      scrollTrigger: {
        trigger: `#${props.data.target}`,
        scrub: true,
        start: 'top bottom',
        end: 'bottom+=300 bottom',
        markers: false,
        id: animID.value,
      },
    }
  )
})

onBeforeUnmount(() => {
  const anim = ScrollTrigger.getById(animID.value)
  if (anim) anim.kill()
})
</script>

<style lang="scss" scoped>
.floating-button-module-component {
  @apply fixed z-[36] bottom-2 right-3 cursor-pointer;
  .inner-floating-button {
    @apply flex items-center bg-humble/30 text-white rounded-full p-2 pl-8 backdrop-blur-md
    header-6;
    img {
      @apply ml-[20px];
    }
  }
}
</style>
