<template>
  <div
    v-if="device === 'desktop'"
    class="inner-container values-scroller">
    <div class="anim-section">
      <div
        ref="animation"
        class="lottie-anim"></div>
    </div>
    <div
      ref="valueSection"
      class="values-section">
      <div
        v-for="(step, index) of steps"
        ref="valueItems"
        :key="`value-step-${index}`"
        class="value"
        :class="`value-step-${index}`">
        <!-- <div class="step-number">
          {{
            (index + 1).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })
          }}
        </div> -->
        <h3>{{ step.title }}</h3>
        <div
          class="step-description"
          v-html="step.description" />
      </div>
    </div>
  </div>
  <div
    v-else
    class="inner-container values-carousel">
    <div class="anim-section">
      <div class="lottie-anim-bg">
        <div
          ref="animation"
          class="lottie-anim"></div>
      </div>
    </div>
    <div class="carousel">
      <div class="car-controls">
        <div class="car-controls-top">
          <button
            class="left"
            :class="{ disabled: mobileCurr === 0 }"
            :disabled="mobileCurr === 0"
            @click="prev()">
            <img src="@/assets/img/icon-arrow-left-approachable.svg" />
          </button>

          <button
            class="right"
            :class="{ disabled: mobileCurr === props.steps.length - 1 }"
            :disabled="mobileCurr === props.steps.length - 1"
            @click="next()">
            <img src="@/assets/img/icon-arrow-left-approachable.svg" />
          </button>
        </div>
        <div class="car-controls-bottom">
          <div
            v-for="(step, index) of steps"
            :key="`step-indicator-${index}`"
            class="indicator-wrapper"
            :class="{ last: index === steps.length - 1 }">
            <div
              class="indicator"
              :class="{ active: index === mobileCurr }" />
            <div class="separator" />
          </div>
        </div>
      </div>
      <div class="car-text">
        <template
          v-for="(step, index) of steps"
          :key="`car-item-step-${index}`">
          <transition
            name="custom-fade"
            mode="out-in">
            <div
              v-if="index === mobileCurr"
              class="car-item-container">
              <div class="step-number">
                {{
                  (index + 1).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })
                }}
              </div>
              <h3>{{ step.title }}</h3>
              <div
                class="step-description"
                v-html="step.description" />
            </div>
          </transition>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LottieWeb from 'lottie-web'
import evolution from '@/assets/lottie/RevMed_LogoEvolution.json'
import useUtils from '@/components/composables/useUtils'
gsap.registerPlugin(ScrollTrigger)
const { device, genUUID } = useUtils()
const animID = ref(genUUID())
const anim = ref()
const animation = ref()
const mobileCurr = ref(0)
const props = defineProps(['steps'])

// Main sections to calculate height
const valueSection = ref()

const valuesAnimation = () => {
  const allItems = gsap.utils.toArray(valueSection.value.children)

  ScrollTrigger.create({
    trigger: '.values-scroller',
    start: 'top+=200 center',
    end: 'bottom bottom-=220',
    pin: animation.value,
    scrub: 1,
    id: animID.value,
    onUpdate: ({ progress }) => {
      anim.value.goToAndStop(progress * 130, true)
    },
    snap: {
      snapTo: 1 / (allItems.length - 1),
      duration: { min: 0.1, max: 1 },
      directional: true,
      onStart: ({ progress }) => {
        if (progress < 0.1) {
          anim.value.playSegments([0, 35], false)
        } else if (progress > 0.1 && progress < 0.4) {
          anim.value.playSegments([35, 54], false)
        } else if (progress > 0.4 && progress < 0.6) {
          anim.value.playSegments([54, 64], false)
        } else if (progress > 0.6 && progress < 0.8) {
          anim.value.playSegments([64, 84], false)
        } else if (progress > 0.8) {
          anim.value.playSegments([84, 130], false)
        }
      },
      ease: 'power1.inOut',
    },
    markers: false,
  })
  setTimeout(() => {
    const snap = ScrollTrigger.snapDirectional([0, 50, 200, 1000], 1)
    snap(8) // 5  (closest, non-directional)
    snap(8, 1) // 20 (closest greater than)
    snap(99, -1)
  }, 3000)
}
const animSegments = [
  [0, 35],
  [35, 64],
  [64, 84],
  [84, 130],
]
const next = () => {
  anim.value.playSegments(animSegments[mobileCurr.value], true)
  mobileCurr.value += 1
}
const prev = () => {
  mobileCurr.value -= 1
  let framesRef = new Array(
    animSegments[mobileCurr.value][0],
    animSegments[mobileCurr.value][1]
  )
  anim.value.playSegments(framesRef.reverse(), true)
}
onMounted(() => {
  anim.value = LottieWeb.loadAnimation({
    container: animation.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: evolution,
  })
  if (device.value === 'desktop') {
    valuesAnimation()
  }
})
onBeforeUnmount(() => {
  const anim = ScrollTrigger.getById(animID.value)
  if (anim) anim.kill()
})
</script>

<!-- <script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import LottieWeb from 'lottie-web'
import evolution from '@/assets/lottie/RevMed_LogoEvolution.json'
import useUtils from '@/components/composables/useUtils'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

gsap.registerPlugin(ScrollTrigger)
const { device, genUUID } = useUtils()
const animID = ref(genUUID())
const valueItems = ref()
const anim = ref()
const animation = ref()
const mobileCurr = ref(0)
const props = defineProps(['steps'])
const { locoScroll } = useSmoothScroll()

const valuesAnimation = () => {
  ScrollTrigger.create({
    trigger: '.values-scroller',
    start: 'top+=200 center',
    end: 'bottom bottom-=160',
    pin: animation.value,
    scrub: 1,
    id: animID.value,
    onUpdate: ({ progress }) => {
      anim.value.goToAndStop(progress * 130, true)
    },
  })

  valueItems.value.forEach((valueItem, idx) => {
    const height = gsap.getProperty(valueItem, 'height')
    const halfHeight = height / 2 + 40
    ScrollTrigger.create({
      id: `value-${idx}`,
      trigger: valueItem,
      scrub: true,
      start: 'top-=25% center',
      end: 'bottom+=25% center',
      onEnter: () => {
        locoScroll.value.scrollTo(valueItem, {
          lock: false,
          offset: -halfHeight,
        })
      },
      onEnterBack: () => {
        locoScroll.value.scrollTo(valueItem, {
          lock: false,
          offset: -height / 1.8,
        })
      },
      markers: false,
    })
  })
}

const animSegments = [
  [0, 35],
  [35, 64],
  [64, 84],
  [84, 110],
]

const next = () => {
  anim.value.playSegments(animSegments[mobileCurr.value], true)
  mobileCurr.value += 1
}

const prev = () => {
  mobileCurr.value -= 1
  let framesRef = new Array(
    animSegments[mobileCurr.value][0],
    animSegments[mobileCurr.value][1]
  )
  anim.value.playSegments(framesRef.reverse(), true)
}

onMounted(() => {
  anim.value = LottieWeb.loadAnimation({
    container: animation.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: evolution,
  })
  if (device.value === 'desktop') {
    valuesAnimation()
  }
})
onBeforeUnmount(() => {
  valueItems.value.forEach((valueItem, idx) => {
    ScrollTrigger.getById(`value-${idx}`).kill()
  })
  const anim = ScrollTrigger.getById(animID.value)
  if (anim) anim.kill()
})
</script> -->
<style lang="scss" scoped>
.values-scroller {
  @apply flex mt-28 mb-12;
  .anim-section {
    @apply w-1/2;
    .lottie-anim {
      @apply bg-balanced h-[420px] rounded-3xl w-10/12 pointer-events-none;
      &:deep() {
        svg {
          transform: scale(1.5) !important;
        }
      }
    }
  }
  .values-section {
    @apply w-5/12;
    .value {
      @apply flex flex-col min-h-[500px] justify-center;
    }
  }
}
.values-carousel {
  @apply flex flex-col;
  .anim-section {
    @apply mt-28 mb-10 flex justify-center;
    .lottie-anim-bg {
      @apply bg-balanced h-[189px] w-[182px] rounded-[20px]
      flex items-center justify-center relative overflow-hidden;
      .lottie-anim {
        @apply absolute h-[300px] w-[300px] pointer-events-none;
      }
    }
  }
  .carousel {
    @apply flex flex-col;
    .car-text {
      @apply relative h-[310px];
      .car-item-container {
        @apply absolute;
        &.active {
        }
      }
    }

    .car-controls {
      @apply relative flex flex-col gap-6 mb-7;
      &-top {
        @apply absolute -top-28  w-full left-0 flex justify-between;
        button {
          @apply bg-white h-[47px] w-[47px] rounded-full flex justify-center items-center transition-all;
          img {
            @apply h-[23px] transition-all;
          }
          &.disabled {
            @apply opacity-50;
          }
        }
        .right {
          img {
            @apply rotate-180;
          }
        }
      }
      &-bottom {
        @apply flex justify-center;
        .indicator-wrapper {
          @apply flex items-center;

          .indicator {
            @apply h-[10px] w-[10px] rounded-full bg-progressive opacity-30 transition-all;
            &.active {
              @apply opacity-100;
            }
          }
          .separator {
            @apply mx-[11px] h-px w-[37px] bg-balanced;
          }
          &.last {
            .separator {
              @apply hidden;
            }
          }
        }
      }
    }
  }
}

// global styles

.step-number {
  @apply text-progressive w-10 h-10 flex justify-center items-center pt-1 font-FKDisplay text-[18px] border border-progressive rounded-full;
}
h3 {
  @apply font-FKDisplay text-[26px] leading-[26px] my-4
  lg:text-balanced lg:header-2 lg:my-6;
}
.step-description {
  @apply text-humble leading-[22px] min-h-[175px];
}

.custom-fade-enter-from {
  opacity: 0;
}
.custom-fade-enter-active {
  transition: opacity 0.4s ease-in-out;
  transition-delay: 0.5s;
}
.custom-fade-leave-to {
  opacity: 0;
}
.custom-fade-leave-active {
  transition: opacity 0.4s ease-in;
}
</style>
