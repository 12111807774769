<template>
  <section class="floating-media-module-component module-container">
    <div
      class="floating-media"
      :class="{ 'no-video-box': !data.has_video }">
      <img
        class="top-box floating-box"
        :src="data.imgs[0].url" />
      <button
        v-if="data.has_video"
        class="video-box floating-box">
        <div class="video-box-inner">
          <div class="video-box-inner-inner">
            <div class="play">
              <img src="@/assets/img/play-icon.svg" />
            </div>
            <div
              v-if="data.video_title"
              class="video-title">
              <span
                class="title-text"
                v-html="data.video_title" />
              <span
                class="title-text"
                v-html="data.video_title" />
            </div>
          </div>
        </div>
      </button>
      <img
        class="bottom-box floating-box"
        :src="data.imgs[1].url" />
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "FloatingMediaComponent",
    "CLASSNAME": "floating-media-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

// Note: need to implement video (no design yet)
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import useUtils from '@/components/composables/useUtils.js'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps(['data'])
const { genUUID, device } = useUtils()

const animID = ref(genUUID())

const boxesAnim = (offsetValue) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.floating-media-module-component',
      scrub: 0.75,
      start: '-250px 100%',
      end: '2000px 100%',
      id: animID.value,
    },
  })

  tl.fromTo(
    '.top-box',
    {
      y: -offsetValue,
      duration: 10,
    },
    {
      y: offsetValue,
    }
  )
  if (props.data.has_video) {
    tl.from(
      '.video-box',
      {
        y: -offsetValue,
      },
      '<'
    )
  }
  tl.fromTo(
    '.bottom-box',
    {
      y: offsetValue,
    },
    {
      y: -offsetValue,
    },
    '<'
  )
}

onMounted(() => {
  let animMoveValue = 150
  if (device.value === 'mobile') {
    animMoveValue = 100
  }
  boxesAnim(animMoveValue)
})

onBeforeUnmount(() => {
  const mediaAnim = ScrollTrigger.getById(animID.value)
  if (mediaAnim) mediaAnim.kill()
})
</script>

<style lang="scss" scoped>
.floating-media-module-component {
  @apply py-[10px] h-full pointer-events-none  flex justify-center z-5
  lg:py-0 lg:flex lg:justify-start lg:items-center;

  .floating-media {
    @apply flex flex-col 
    w-[90%] gap-10
    md:w-[65%] lg:gap-0
    lg:w-full;
    .floating-box {
      @apply w-[203px] h-[207px] rounded-[20px] object-cover;
    }

    .top-box {
      @apply self-end rounded-bl-[60px]
        w-[250px] h-[250px]
        md:w-[350px] md:h-[350px]
        lg:w-[300px] lg:h-[300px]
        xl:w-[350px] xl:h-[357px];
    }

    .video-box {
      @apply self-center pointer-events-auto z-1 relative w-[163px] h-[163px] my-[-50px]
        lg:mt-[-75px] lg:mb-[-40px] lg:w-[175px] lg:h-[175px];
      &-inner {
        @apply h-full rounded-[25px] backdrop-blur-[15px]
            p-5
          transition-all duration-300 ease-in-out;
        box-shadow: 0px 2px 1px 0px #ffffff26 inset;
        &-inner {
          @apply h-full flex items-end justify-center
            transition-all duration-300 ease-in-out;
          .video-title {
            @apply relative overflow-hidden;
            p,
            span {
              @apply text-white;
            }
            .title-text {
              @apply transition-all duration-300 ease-in-out;
            }
            .title-text:first-child {
              @apply absolute left-0 bottom-[-20px];
            }
            .title-text:nth-child(2) {
              @apply flex;
            }
          }
          .play {
            @apply top-0 left-0 absolute w-full h-full
            flex items-center justify-center pointer-events-none
            transition-all duration-300 ease-in-out;
          }
        }
        &:hover,
        &:focus,
        &:focus-visible {
          @apply scale-90;
          .video-box-inner-inner {
            @apply scale-110;
          }
          .play {
            @apply scale-125;
          }

          .title-text:first-child {
            @apply bottom-1/2;
            transform: translateY(50%);
          }
          .title-text:nth-child(2) {
            @apply translate-y-[-20px];
          }
        }
      }
    }

    .bottom-box {
      @apply self-start rounded-tr-[60px]
        sm:w-[270px] sm:h-[276px] sm:ml-9
        lg:w-[203px] lg:h-[207px] lg:ml-0
        xl:w-[270px] xl:h-[276px] xl:ml-9;
    }

    &.no-video-box {
      .bottom-box {
        @apply sm:ml-0 xl:ml-0;
      }
    }
  }
}
</style>
