<template>
  <section class="team-cards module-container">
    <div
      class="image-cards-container"
      :class="{
        'inner-container': !isChild,
        'mt-[140px]': !category,
      }">
      <SpacerComponent
        v-if="category !== undefined"
        :data="spacerConfigs" />
      <h2
        v-if="category !== '' && category !== undefined"
        class="cat-title">
        {{ category }}
      </h2>
      <div
        v-if="haveImg"
        class="image-cards"
        :class="{
          'no-current': !member,
          'team-component-cards': isTeamComponent,
        }">
        <a
          v-for="(el, idx) in members"
          :key="idx"
          class="image-card"
          :class="{
            'is-current':
              !isTeamComponent && isCurrent(el.link || el.permalink),
          }"
          :href="el.link || el.permalink">
          <div class="image-card-img-wrapper">
            <img
              class="image-card-img"
              :src="el.acf.img.url" />
          </div>
          <div class="image-card-text">
            <p
              class="image-card-text-title"
              v-html="el.title?.rendered || el.post_title" />
            <p
              v-if="el.acf.title"
              class="image-card-text-subtitle"
              v-html="el.acf.title" />
          </div>
        </a>
      </div>
      <div
        v-else
        :class="{ 'no-current': !member }"
        class="no-image-cards">
        <a
          v-for="(el, idx) in members"
          :key="idx"
          class="no-image-card"
          :class="{
            'is-current':
              !isTeamComponent && isCurrent(el.link || el.permalink),
          }"
          :href="el.link || el.permalink">
          <div class="no-image-card-inner">
            <BicolorLine />
            <p
              class="no-image-card-title"
              v-html="el.title?.rendered || el.post_title" />
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
import SpacerComponent from '@/components/modules/SpacerComponent.vue'
import BicolorLine from '../../utility/BicolorLine.vue'

const props = defineProps([
  'isChild',
  'member',
  'members',
  'haveImg',
  'isTeamComponent',
  'category',
])

const spacerConfigs = {
  separator: true,
  rounded: 'none',
  spacer_height: '200',
  spacer_height_tablet: '100',
  spacer_height_mobile: '100',
  back_background_color: 'rgba(255,255,255,0)',
  background_color: 'rgba(255,255,255,0)',
  separator_left: 'rgb(14,67,67)',
  separator_right: 'rgb(19,211,119)',
}

const isCurrent = (thisLink) => {
  return thisLink === props.member?.link
}
</script>

<style lang="scss" scoped>
.team-cards {
  @apply lg:flex lg:justify-center;

  .inner-container {
    @apply lg:px-[60px];
  }

  .cat-title {
    @apply text-balanced header-5 normal-case
    lg:header-4 mb-[60px];
  }

  .image-cards-container {
    @apply lg:mx-0 lg:w-full lg:max-w-[1400px];
    .image-cards {
      @apply flex flex-wrap gap-[14px]
      xl:gap-8;
      .image-card {
        @apply flex flex-col  gap-[18px] w-[calc(50%_-_7px)]
        sm:w-[calc(25%_-_10.5px)]
        lg:gap-[20px] lg:min-h-[540px]
        xl:min-h-[550px] xl:w-[calc(25%_-_24px)];
        &-img-wrapper {
          @apply h-[230px] lg:h-auto overflow-hidden rounded-[20px];
        }
        &-img {
          @apply opacity-100 rounded-[20px] w-full h-full object-cover
          xl:h-[425px] transition-all;
        }
        &-text {
          @apply flex flex-col gap-[5px];
          &-title {
            @apply text-base font-medium
            lg:text-[22px];
          }
          &-subtitle {
            @apply text-sm leading-5;
          }
          p {
            @apply font-FKGrotesk text-balanced;
          }
        }

        &:hover,
        &:focus-visible {
          .image-card-img {
            @apply lg:scale-110;
          }
        }
        &.is-current {
          @apply pointer-events-none;
          .image-card-img {
            @apply opacity-50;
          }
        }
        &.no-current {
          .image-card-img {
            @apply opacity-100;
          }
        }
      }
    }
    .team-component-cards {
      .image-card {
        @apply xl:w-[calc(33%_-_24px)];
      }
    }
    .no-image-cards {
      @apply flex flex-col gap-[14px]
      lg:flex-row lg:flex-wrap lg:gap-8;

      .no-image-card {
        @apply opacity-100 transition-all flex flex-col justify-center gap-4 bg-balanced rounded-[20px] h-[120px] p-[18px]
        lg:w-[calc(50%_-_16px)]
        xl:w-[calc(33.3%_-_24px)];
        &.is-current {
          @apply opacity-50;
        }

        &-inner {
          @apply flex flex-col gap-5;
        }

        &-title {
          @apply text-lg leading-[26px]
          xl:text-[21px];
        }
        .bicolor-line {
          @apply w-[66px] h-[3px];
        }
        p {
          @apply text-functional font-FKGrotesk;
        }

        &:hover,
        &:focus-visible {
          @apply lg:opacity-50;
        }
      }
    }
  }
}
.team-component {
  .image-cards {
    .image-card {
    }
  }
}
</style>
