<template>
  <section class="publications-filters">
    <div class="publications-filters-wrapper">
      <div
        v-if="device !== 'desktop'"
        role="button"
        class="wrapper-filter filter-def-style"
        :class="{ active: isOpenStates.wrapper }"
        @click="switchWrapperOpenState()">
        Filters
        <div class="filter-arrow-container">
          <div class="filter-arrow">
            <img src="@/assets/img/caret-down-progressive.svg" />
          </div>
        </div>
      </div>
      <component
        :is="device === 'desktop' ? 'div' : SlideUpDown"
        v-model="isOpenStates.wrapper">
        <div class="filters">
          <Dropdown
            v-for="(title, idx) in filterArrays.titles"
            :key="`filter-${title}-${idx}`"
            :idx="idx"
            :title="title"
            :class="
              loading
                ? 'pointer-events-none cursor-wait z-50 filter grayscale saturate-0 brightness-150'
                : ''
            "
            :dropdowns="filterArrays"
            :parent-selected-state="selected" />
        </div>
      </component>
    </div>
  </section>
</template>

<script setup>
import useUtils from '@/components/composables/useUtils'
import Dropdown from '@/components/utility/Dropdown.vue'
// import { FocusTrap } from 'focus-trap-vue'
import SlideUpDown from 'vue3-slide-up-down'
import { onMounted, ref, watch } from 'vue'
let props = defineProps(['filters', 'selectedOptions', 'loading'])

const { device } = useUtils()

let selected = ref(props.selectedOptions)

let isOpenStates = ref({
  wrapper: false,
  filters: [],
})

let filterArrays = ref({
  titles: [],
  options: [],
})

const switchWrapperOpenState = () => {
  isOpenStates.value.wrapper = !isOpenStates.value.wrapper
}

watch(device, (newVal) => {
  if (
    (newVal === 'mobile' || newVal === 'tablet') &&
    isOpenStates.value.wrapper !== false
  ) {
    isOpenStates.value.wrapper = false
  }
})

watch(props.filters, () => {
  if (props.filters) {
    const filters = props.filters

    // generating filter arrays for programmatic rendering
    filterArrays.value.titles = Object.values(filters).map((filter) => {
      return filter.title
    })
    filterArrays.value.options = Object.values(filters).map((filter) => {
      return filter.options
    })
  }
})

onMounted(() => {
  if (props.filters) {
    const filters = props.filters

    // generating filter arrays for programmatic rendering
    filterArrays.value.titles = Object.values(filters).map((filter) => {
      return filter.title
    })
    filterArrays.value.options = Object.values(filters).map((filter) => {
      return filter.options
    })
  }
})
</script>

<style lang="scss" scoped>
.publications-filters {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @apply px-0 lg:px-10;
  &-wrapper {
    @apply relative p-2 bg-gray_green_trans rounded-[18px]
    lg:h-[96px] lg:overflow-visible;

    .filter-def-style {
      @apply relative box-content rounded-[18px] transition-all py-[15px] min-h-[50px]
      flex flex-col justify-between;

      .filter-arrow-container {
        @apply absolute right-[10px] top-0
        flex items-center justify-center
        h-20 w-[50px];

        .filter-arrow {
          @apply flex justify-center items-center
           bg-white w-[35px] h-[35px] rounded-full;
          img {
            @apply transition-all;
          }
        }
      }
    }

    .active > .filter-arrow-container,
    .active > * > .filter-arrow-container {
      .filter-arrow {
        img {
          @apply rotate-180;
        }
      }
    }
    .wrapper-filter {
      @apply flex justify-center bg-white px-5;
      .filter-arrow-container {
        .filter-arrow {
          @apply bg-functional;
        }
      }
    }
    .filter-slideupdown-wrapper {
    }
    .filters {
      @apply relative flex flex-col gap-[3px] mt-[3px] transition-all
      lg:flex-row lg:gap-[10px] lg:mt-0;
    }
  }
}
</style>
