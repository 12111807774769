<template>
  <div
    :id="`image-${uid}`"
    class="w-full image-container">
    <img v-lazy="source" />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps(['sizes'])
const observer = ref(null)
const uid = ref(`${Math.floor(Math.random() * (564 * 1000000))}`)
const source = ref(null)

function pictureObserve(e) {
  const width = e[0].contentRect.width
  const height = e[0].contentRect.height

  if (width > 1080) {
    source.value = props.sizes.hero
  } else if (width > 900 && width <= 1080) {
    source.value = props.sizes['16:9']
  } else if (width <= 900 && width == height) {
    if (width <= 400) {
      source.value = props.sizes['thumb']
    } else {
      source.value = props.sizes['1:1']
    }
  } else if (width <= 900 && width > height) {
    source.value = props.sizes['3:2']
  } else {
    if (width <= 400) {
      source.value = props.sizes['thumb']
    } else {
      source.value = props.sizes['1:1']
    }
  }
}

onMounted(() => {
  observer.value = new ResizeObserver((e) => pictureObserve(e))
  observer.value.observe(document.querySelector(`#image-${uid.value}`))
})

onBeforeUnmount(() => {
  observer.value.unobserve(document.querySelector(`#image-${uid.value}`))
})
</script>

<style lang="scss" scoped>
.image-container {
  @apply relative;

  img {
    @apply w-full h-full object-cover;
  }
}
</style>
