<template>
  <div
    ref="wrapper"
    class="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-for="(module, index) in page.acf.modules">
          <template
            v-if="module.acf_fc_layout === 'TextWithImgAndVideoComponent'">
            <TextWithImgAndVideoComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'PositionDetailsComponent'">
            <PositionDetailsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'TextWithTwoImgsComponent'">
            <TextWithTwoImgsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'ThreeCardsComponent'">
            <ThreeCardsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'AnimatedColsComponent'">
            <AnimatedColsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'CallToActionComponent'">
            <CallToActionComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TextBlocksComponent'">
            <TextBlocksComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TextRevealComponent'">
            <TextRevealComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'HorizontalLogoAnimComponent'">
            <HorizontalLogoAnimComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="
              module.acf_fc_layout === 'TwoColsWithFloatingMediaComponent'
            ">
            <TwoColsWithFloatingMediaComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'OpenPosComponent'">
            <OpenPosComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'PipelineComponent'">
            <PipelineComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'PublicationsComponent'">
            <PublicationsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'PrivacyPolicyComponent'">
            <PrivacyPolicyComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'AboutValuesComponent'">
            <AboutValuesComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'MenuAnchorComponent'">
            <MenuAnchorComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TwoColsTextComponent'">
            <TwoColsTextComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'FloatingButtonComponent'">
            <FloatingButtonComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'GreenBoxAndTextComponent'">
            <GreenBoxAndTextComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'PageHeroComponent'">
            <PageHeroComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'TeamsComponent'">
            <TeamsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'FloatingMediaComponent'">
            <FloatingMediaComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'QuoteComponent'">
            <QuoteComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'TextWithCardsComponent'">
            <TextWithCardsComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'CardListComponent'">
            <CardListComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'DecorationComponent'">
            <DecorationComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'BlogListComponent'">
            <BlogListComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'ImageComponent'">
            <ImageComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'SpacerComponent'">
            <SpacerComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'HeroComponent'">
            <HeroComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template
            v-else-if="module.acf_fc_layout === 'TextWithIconComponent'">
            <TextWithIconComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'NewPipelineComponent'">
            <NewPipelineComponent
              :key="index"
              :data="module"
              :index="index" />
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script setup>
import TextWithImgAndVideoComponent from '@/components/modules/TextWithImgAndVideoComponent.vue'
import PositionDetailsComponent from '@/components/modules/PositionDetailsComponent.vue'
import TextWithTwoImgsComponent from '@/components/modules/TextWithTwoImgsComponent.vue'
import HorizontalLogoAnimComponent from '@/components/modules/HorizontalLogoAnimComponent.vue'
import ThreeCardsComponent from '@/components/modules/ThreeCardsComponent.vue'
import AnimatedColsComponent from '@/components/modules/AnimatedColsComponent.vue'
import CallToActionComponent from '@/components/modules/CallToActionComponent.vue'
import TextBlocksComponent from '@/components/modules/TextBlocksComponent.vue'
import TextRevealComponent from '@/components/modules/TextRevealComponent.vue'
import TwoColsWithFloatingMediaComponent from '@/components/modules/TwoColsWithFloatingMediaComponent.vue'
import OpenPosComponent from '@/components/modules/OpenPosComponent.vue'
import PipelineComponent from '@/components/modules/PipelineComponent.vue'
import PublicationsComponent from '@/components/modules/Publications/PublicationsComponent.vue'
import PrivacyPolicyComponent from '@/components/modules/PrivacyPolicyComponent.vue'
import AboutValuesComponent from '@/components/modules/AboutValuesComponent.vue'
import MenuAnchorComponent from '@/components/modules/MenuAnchorComponent.vue'
import FloatingButtonComponent from '@/components/modules/FloatingButtonComponent.vue'
import PageHeroComponent from '@/components/modules/PageHeroComponent.vue'
import TwoColsTextComponent from '@/components/modules/TwoColsTextComponent.vue'
import GreenBoxAndTextComponent from '@/components/modules/GreenBoxAndTextComponent.vue'
import TeamsComponent from '@/components/modules/TeamsComponent.vue'
import FloatingMediaComponent from '@/components/modules/FloatingMediaComponent.vue'
import QuoteComponent from '@/components/modules/QuoteComponent.vue'
import TextWithCardsComponent from '@/components/modules/TextWithCardsComponent.vue'
import CardListComponent from '@/components/modules/CardListComponent.vue'
import DecorationComponent from '@/components/modules/DecorationComponent.vue'
import BlogListComponent from '@/components/modules/BlogListComponent.vue'
import ImageComponent from '@/components/modules/ImageComponent.vue'
import SpacerComponent from '@/components/modules/SpacerComponent.vue'
import HeroComponent from '@/components/modules/HeroComponent.vue'
import TextWithIconComponent from '@/components/modules/TextWithIconComponent.vue'
import NewPipelineComponent from '@/components/modules/NewPipelineComponent.vue'

import { computed, onBeforeMount, onMounted, nextTick } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useUtils from '@/components/composables/useUtils.js'

const store = useStore()
const router = useRouter()
const props = defineProps(['slug', 'isHomepage'])
const request = {
  type: 'pages',
  slug: props.slug,
  showLoading: true,
}

const { decodeTitle } = useUtils()

const page = computed(() => {
  return store.getCurrentSingleBySlug(request)
})

onBeforeMount(() => getPage())

onMounted(() => {
  const anchor = router.currentRoute.hash
  nextTick(() => {
    if (anchor && document.querySelector(anchor)) {
      location.href = anchor
    }
  })
})

const getPage = async () => {
  store.getSingleBySlug(request).then(() => {
    if (page.value) {
      if (!props.isHomepage) {
        store.updateDocTitle({
          parts: [decodeTitle(page.value.title.rendered), store.site.name],
        })
      } else {
        store.updateDocTitle({
          parts: [null, store.site.name],
        })
      }
    } else {
      router.replace('/404')
    }
  })
}

// const normalizeData = (type) => {
//   if (type) {
//     const filtered = store.getCurrentSingleBySlug(request)
//     const rawData = store.getPosts({ type: type })
//     if (filtered) {
//       for (const module of filtered.acf.modules) {
//         if (module.acf_fc_layout === type) {
//           const data = Object.entries(rawData).map((x) => x[1])
//           data.pop()
//           module[type] = data
//         }
//       }
//     }
//   }
// }
</script>

<style lang="scss"></style>
