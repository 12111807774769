<template>
  <div class="video-box-wrapper">
    <button
      class="video-box"
      :class="{ opaque: opaque }"
      @click="show = true">
      <div class="video-box-inner">
        <div class="video-box-inner-inner">
          <div
            class="play"
            :class="{ 'long-title': title.length > 15 }">
            <img src="@/assets/img/play-icon.svg" />
          </div>
          <div class="video-title">
            <span
              ref="titleHtml"
              class="title-text"
              v-html="title" />
            <span
              class="title-text"
              v-html="title" />
          </div>
        </div>
      </div>
    </button>
    <ModalConfirm
      v-model="show"
      @confirm="() => confirm()">
      <video
        controls
        autoplay
        width="1920"
        height="1080">
        <source
          :src="video"
          type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h2
        v-if="videoTitle"
        class="video-title-inner"
        v-html="videoTitle" />
    </ModalConfirm>
  </div>
</template>

<script setup>
import ModalConfirm from './ModalConfirm.vue'
import { ref } from 'vue'
// This component is wip.
// todo: Modal
defineProps(['title', 'video', 'opaque', 'videoTitle'])

const show = ref(false)

function confirm() {
  show.value = false
}
</script>

<style lang="scss" scoped>
.video-box-wrapper {
  @apply w-full h-full;
  .video-box {
    @apply self-center pointer-events-auto z-1 relative w-full h-full rounded-[20px];

    &-inner {
      @apply h-full rounded-[25px] backdrop-blur-[15px]
            p-5
          transition-all duration-300 ease-in-out;

      box-shadow: 0px 2px 1px 0px #ffffff26 inset;
      &-inner {
        @apply h-full flex items-end justify-center
            transition-all duration-300 ease-in-out;
        .video-title {
          @apply lg:w-[80%] relative overflow-hidden;
          p,
          span {
            @apply text-white;
          }
          .title-text {
            @apply transition-all duration-300 ease-in-out;
            &:deep {
              @apply flex justify-center w-full;
            }
          }
          .title-text:first-child {
            @apply absolute left-0 bottom-[-100%];
          }
          .title-text:nth-child(2) {
            @apply flex;
          }
        }
        .play {
          @apply top-0 left-0 absolute w-full h-full 
            flex items-center justify-center pointer-events-none
            transition-all duration-300 ease-in-out;

          &.long-title {
            img {
              @apply mb-[40px];
            }
          }
          img {
            @apply w-[30%] h-[30%]  lg:mb-0;
          }
        }
      }
    }
    &:hover,
    &:focus,
    &:focus-visible {
      .video-box-inner {
        @apply scale-90;
      }
      .video-box-inner-inner {
        @apply scale-110;
      }
      .play {
        @apply scale-125;
      }

      .title-text:first-child {
        bottom: 0;
        // @apply bottom-1/2;
        // transform: translateY(50%);
      }
      .title-text:nth-child(2) {
        @apply translate-y-[-100%];
      }
    }

    &.opaque {
      .video-box-inner {
        @apply bg-white/30;
      }
    }
  }
}
.video-title-inner {
  @apply header-5 normal-case text-white mt-4;
}
</style>
