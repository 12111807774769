<template>
  <div class="intro">
    <div
      ref="introContainer"
      class="intro-bg"></div>
    <div class="intro-container">
      <div
        ref="animation"
        class="intro-animation"></div>
    </div>
  </div>
</template>

<script setup>
import LottieWeb from 'lottie-web'
import introJson from '@/assets/lottie/logo-intro.json'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import { ref, onMounted, onBeforeUnmount } from 'vue'
// import useLoadPercentage from '@/components/composables/useLoadPercentage.js'

const { locoScroll } = useSmoothScroll()
const { setIntroComplete, setPreIntroComplete, introShown } = useIntroShown()
const animation = ref()
const anim = ref()
const introContainer = ref()
// const textContainer = ref()
// const { percentage } = useLoadPercentage()

const stopScroll = () => {
  if (!introShown.value) {
    if (locoScroll.value) {
      locoScroll.value.scrollTo(0, { immediate: true })
      locoScroll.value.stop()
    } else {
      setTimeout(() => {
        stopScroll()
      }, 100)
    }
  }
}

onMounted(() => {
  stopScroll()
  document.documentElement.classList.add('no-overflow')
  anim.value = LottieWeb.loadAnimation({
    container: animation.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: introJson,
  })
  // anim.value.setSpeed(0.8)
  if (window.location.pathname === '/') {
    anim.value.playSegments([20, 55], true)
    setTimeout(() => {
      introContainer.value.classList.add('transparent')
      anim.value.playSegments([55, 135], true)
      setPreIntroComplete()
    }, 1000)
    anim.value.addEventListener('complete', () => {
      setIntroComplete()
      removeScrollLock()
    })
  } else {
    setPreIntroComplete()
    setIntroComplete()
    removeScrollLock()
  }
})

const removeScrollLock = () => {
  setTimeout(() => {
    document.documentElement.classList.remove('no-overflow')
    locoScroll.value.start()
  }, 1800)
}

// watch(percentage, (newVal) => {
//   if (newVal === 100) {
//     setTimeout(() => {
//       textContainer.value.classList.add('transparent')
//       setTimeout(() => {
//         if (window.location.pathname === '/') {
//           introContainer.value.classList.add('transparent')
//           anim.value.playSegments([55, 135], true)
//           setPreIntroComplete()
//           anim.value.addEventListener('complete', () => {
//             setIntroComplete()
//             removeScrollLock()
//           })
//         } else {
//           setPreIntroComplete()
//           setIntroComplete()
//           removeScrollLock()
//         }
//       }, 500)
//     }, 300)
//   }
// })

onBeforeUnmount(() => {
  LottieWeb.destroy
})
</script>

<style lang="scss">
.intro {
  @apply fixed top-0 left-0 w-full h-full z-50;

  .intro-bg {
    // @apply bg-transparent;
    @apply bg-gradient-to-tr from-humble to-balanced opacity-100 transition-opacity duration-[1.5s] ease-in-out w-screen h-screen absolute top-0 left-0 z-[-1];
    &.transparent {
      @apply opacity-0;
    }
  }
  .intro-container {
    @apply inner-container pt-[125px] lg:pt-[210px] relative flex items-center z-[52];
    .intro-animation {
      @apply w-[220px] md:w-[400px] lg:w-[480px];
    }
    // .intro-text {
    //   @apply text-approachable text-[50px] md:text-[60px] absolute left-24 md:left-56 opacity-100 transition-opacity duration-500 ease-in-out;
    //   &.transparent {
    //     @apply opacity-0;
    //   }
    // }
  }
}
html {
  scrollbar-gutter: stable;
}
</style>
