<template>
  <section
    ref="slidesTrigger"
    class="three-cards-module-component module-container">
    <div
      ref="slidesContainer"
      class="inner-container">
      <div
        v-for="(card, idx) in selectedCards"
        :key="`card-${idx}`"
        class="card"
        :class="[`card-${idx + 1}`]">
        <div class="card-image">
          <div
            class="card-image-title"
            v-html="card.left.img_text" />
          <img
            :src="card.left.img.url"
            alt="" />
        </div>
        <div class="card-content">
          <h2
            class="card-content-title"
            v-html="card.right.title" />
          <div
            class="card-content-copy"
            v-html="card.right.content" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "ThreeCardsComponent",
    "CLASSNAME": "three-cards-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import useUtils from '@/components/composables/useUtils'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const props = defineProps(['data'])

const { genUUID, device } = useUtils()
const animID = ref(genUUID())

const selectedCards = ref([])

const slidesContainer = ref(null)
const slidesTrigger = ref(null)

const getThreeCards = () => {
  const shuffled = props.data.cards.slice().sort(() => 0.5 - Math.random())
  return shuffled.slice(0, 3)
}

const cardsAnim = () => {
  if (device.value === 'desktop') {
    const cards = slidesContainer.value.children
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: slidesContainer.value,
        start: 'top-=50 top',
        end: 'bottom-=500 center',
        pin: slidesContainer.value,
        pinSpacing: false,
        scrub: true,
        anticipatePin: 1,
        markers: false,
        id: animID.value,
      },
    })
    tl.to(slidesTrigger.value, {
      background: '#FFF',
    })
      .to(
        cards[0],
        {
          y: '0%',
          scale: 0.8,
          filter: 'blur(5px)',
        },
        '<'
      )
      .to(
        cards[1],
        {
          y: '-90%',
        },
        '<'
      )
      .to(
        cards[2],
        {
          y: '-90%',
        },
        '<'
      )
      .to(
        cards[1],
        {
          y: '-75%',
          scale: 0.9,
          filter: 'blur(5px)',
        },
        '>+0.5'
      )
      .to(
        cards[0],
        {
          y: '10%',
        },
        '<'
      )
      .to(
        cards[2],
        {
          y: '-160%',
        },
        '<'
      )
  } else {
    gsap.to(slidesTrigger.value, {
      background: '#FFF',
      scrollTrigger: {
        trigger: slidesContainer.value,
        start: 'top-=50 top',
        end: 'bottom-=500 center',
        scrub: true,
        markers: false,
        id: animID.value,
      },
    })
  }
}

onBeforeMount(() => {
  selectedCards.value = getThreeCards()
})
onMounted(() => {
  cardsAnim()
})
onBeforeUnmount(() => {
  const anim = ScrollTrigger.getById(animID.value)
  if (anim) anim.kill()
})
</script>

<style lang="scss" scoped>
.three-cards-module-component {
  @apply bg-balanced py-10 overflow-hidden;
  .inner-container {
    // @apply flex flex-col gap-[14px] px-[6px]
    // lg:px-[60px];
    @apply px-[6px] 
    lg:h-[200vh] xl:px-[0px];
    .card {
      @apply mb-[14px] px-[15px] pb-[25px] pt-[38px] min-h-[600px] bg-functional shrink-0 rounded-[20px]
      flex flex-col gap-[70px] items-center
      lg:flex-row lg:justify-center lg:items-center lg:gap-[100px] lg:min-h-[515px]
       lg:pt-[75px] lg:pb-[60px];

      &-2 {
        @apply bg-progressive;

        .card-content-title {
          @apply text-humble;
        }
      }
      &-3 {
        @apply bg-approachable;
      }

      &-image {
        @apply relative flex justify-between items-center pr-3 w-full max-w-[500px]
        lg:flex-col-reverse lg:w-[350px] lg:pr-0 lg:gap-0;

        &-title {
          @apply w-[40%] header-6 
           lg:w-auto lg:absolute -bottom-10 -translate-x-0 lg:-translate-x-2/4 left-2/4;
        }

        img {
          @apply w-36 h-36 rounded-full object-cover
          lg:w-[350px] lg:h-[350px];
        }
      }
      &-content {
        @apply flex flex-col gap-14
        lg:w-[450px] lg:gap-5 lg:justify-center;
        &-title {
          @apply max-w-[224px] text-balanced font-FKGrotesk text-[26px] font-bold leading-[30px]
          lg:max-w-none;
        }
        &-copy:deep() {
          @apply flex flex-col gap-5;
          @include wysiwyg {
            @apply font-FKGrotesk text-base body;
          }
        }
      }
    }
  }
}
</style>
