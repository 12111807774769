<template>
  <section class="text-with-img-and-video-module-component module-container">
    <div class="inner-container">
      <div class="media">
        <img
          :src="data.left.img.url"
          class="media-img" />
        <div
          v-if="data.left.video"
          class="video">
          <VideoBoxModal
            :video-title="data.left.video_title"
            :title="data.left.video_box_title"
            :video="data.left.video.url"
            :opaque="true" />
        </div>

        <div
          v-else
          class="no-image-box">
          <img src="../../assets/img/logomark-about.svg" />
        </div>
      </div>
      <div class="content">
        <BicolorLine />
        <h2
          class="content-title"
          v-html="data.right.title" />
        <div
          class="content-text"
          v-html="data.right.text" />
        <div
          v-if="data.right.text_sm"
          class="content-text-sm"
          v-html="data.right.text_sm" />
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TextWithImgAndVideoComponent",
    "CLASSNAME": "text-with-img-and-video-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */
import BicolorLine from '@/components/utility/BicolorLine.vue'
import VideoBoxModal from '@/components/utility/VideoBoxModal.vue'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import useUtils from '@/components/composables/useUtils.js'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps(['data'])
const { genUUID, device } = useUtils()

const animID = ref(genUUID())

const boxesAnim = (offsetValue) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.text-with-img-and-video-module-component',
      scrub: 0.75,
      start: '-250px 100%',
      end: '2000px 100%',
      id: animID.value,
    },
  })

  tl.fromTo(
    '.media-img',
    {
      y: -offsetValue,
      duration: 10,
    },
    {
      y: offsetValue,
    }
  )
  if (props.data.left.video) {
    tl.from(
      '.video',
      {
        y: -offsetValue,
      },
      '<'
    )
  } else {
    tl.from(
      '.no-image-box',
      {
        y: -offsetValue,
      },
      '<'
    )
  }
}

onMounted(() => {
  let animMoveValue = 150
  if (device.value === 'mobile' || !props.data.left.video) {
    animMoveValue = 100
  }
  boxesAnim(animMoveValue)
})

onBeforeUnmount(() => {
  const mediaAnim = ScrollTrigger.getById(animID.value)
  if (mediaAnim) mediaAnim.kill()
})
</script>

<style lang="scss" scoped>
.text-with-img-and-video-module-component {
  @apply bg-balanced py-10;
  .inner-container {
    @apply flex flex-col items-center gap-14
     lg:flex-row lg:items-center lg:gap-16
     xl:pl-0 xl:gap-32;
    .media {
      @apply flex flex-col w-full sm:max-w-[50%]
      lg:min-w-[400px] lg:max-w-none
      xl:min-w-[500px];
      &-img {
        @apply object-cover w-[227px] h-[232px] rounded-[20px] -mb-14
        lg:w-[350px] lg:h-[350px] lg:-mb-20
        xl:w-[407px] xl:h-[407px];
      }
      .video {
        @apply self-end w-[163px] h-[163px] rounded-[20px]
        lg:w-[240px] lg:h-[240px];
      }
      .no-image-box {
        @apply flex justify-center self-end bg-humble w-[163px] h-[163px] rounded-[20px] lg:h-0 lg:w-0;
        img {
          @apply scale-75;
        }
      }
    }
    .content {
      @apply flex flex-col gap-5;
      .bicolor-line {
        @apply w-[66px] h-[3px];
      }

      &-title {
        @apply header-4 font-normal text-approachable mb-[10px]
        lg:font-FKDisplay lg:not-italic lg:leading-10 lg:tracking-[-0.8px];
      }
      &-text {
        &:deep() {
          @include wysiwyg {
            @apply text-white body;
          }
        }
      }
      &-text-sm {
        &:deep() {
          @include wysiwyg {
            @apply text-white font-FKGrotesk text-sm leading-5;
          }
        }
      }
    }
  }
}
</style>
