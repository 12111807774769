<template>
  <section class="privacy-policy-module-component">
    <div class="module-container bg-white">
      <div class="inner-container">
        <p class="text-center mb-24">Last updated {{ modified }}</p>
        <div
          class="top-content"
          v-html="data.content" />
      </div>
    </div>
    <div class="module-container rounded-tr-[100px] -mt-20 bg-functional">
      <div class="inner-container">
        <div
          class="footer-content"
          v-html="data.footer" />
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "PrivacyPolicyComponent",
    "CLASSNAME": "privacy-policy-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */
import { onBeforeMount, ref } from 'vue'
import { useStore } from '../../stores/main'

const props = defineProps(['data'])
const store = useStore()
const modified = ref()

const getPageData = async () => {
  await store.getSingleBySlug({
    slug: 'privacy-policy',
    type: 'pages',
  })
  const data = store.getCurrentSingleBySlug({
    slug: 'privacy-policy',
    type: 'pages',
  })
  if (props.data.date_modified) {
    modified.value = getFormattedDate(new Date(props.data.date_modified))
  } else {
    modified.value = getFormattedDate(new Date(data.modified))
  }
}

function getFormattedDate(date) {
  var year = date.getFullYear()
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  var month = date.getMonth()
  month = months[month]

  var day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return month + ' ' + day + ', ' + year
}

onBeforeMount(() => {
  getPageData()
})
</script>

<style lang="scss" scoped>
.privacy-policy-module-component {
  .top-content:deep() {
    ul {
      list-style-type: none !important;
      margin-left: 28px !important;
      li {
        list-style-type: none !important;
        position: relative;
      }
      li::before {
        content: '';
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4wNjAwNiAwLjc4MDk5QzMuMzY2NTIgMC42MDY3NTggMy43NDIwOCAwLjYwNjc1OCA0LjA0ODU0IDAuNzgwOTlMNi4xMjY2NSAxLjk2MjQ2QzYuNDM5MjkgMi4xNDAyMSA2LjYzMjQxIDIuNDcyMTUgNi42MzI0MSAyLjgzMTc5VjUuMTY4MjFDNi42MzI0MSA1LjUyNzg1IDYuNDM5MjkgNS44NTk3OSA2LjEyNjY1IDYuMDM3NTRMNC4wNDg1NCA3LjIxOTAxQzMuNzQyMDggNy4zOTMyNCAzLjM2NjUyIDcuMzkzMjQgMy4wNjAwNiA3LjIxOTAxTDAuOTgxOTQ3IDYuMDM3NTRDMC42NjkzMDMgNS44NTk3OSAwLjQ3NjE4NiA1LjUyNzg1IDAuNDc2MTg2IDUuMTY4MjFWMi44MzE3OUMwLjQ3NjE4NiAyLjQ3MjE1IDAuNjY5MzAzIDIuMTQwMjEgMC45ODE5NDcgMS45NjI0NkwzLjA2MDA2IDAuNzgwOTlaIiBmaWxsPSIjMTNEMzc3Ii8+Cjwvc3ZnPgo=');
        margin-right: 0.5rem;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: -20px;
        top: 8px;
      }
    }

    ol {
      li {
        counter-increment: double-digit-counter;
        margin-bottom: 10px;
        font-size: 18px;
      }
      li::before {
        content: counter(double-digit-counter, decimal-leading-zero) '.';
        margin-right: 0.5rem;
        top: 0px;
        position: relative;
        background: transparent;
        color: #0e4343;
        width: 28px;
        height: 28px;
        font-size: 18px;
        border-radius: 100px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
    @apply pb-20;
    h2 {
      @apply text-balanced text-[40px] leading-tight mb-8 font-normal;
    }
    .big-text {
      @apply text-[22px] font-medium mb-20;
    }
    h3 {
      @apply text-[26px] text-humble mb-8;
    }

    p {
      @apply text-[18px] text-humble mb-8;
    }

    ul {
      @apply my-10 ml-4 lg:ml-10;
      li {
        @apply text-[18px] text-humble mb-2 list-disc;
      }
    }
  }

  .footer-content:deep() {
    ul {
      list-style-type: none !important;
      margin-left: 28px !important;
      li {
        list-style-type: none !important;
        position: relative;
      }
      li::before {
        content: '';
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4wNjAwNiAwLjc4MDk5QzMuMzY2NTIgMC42MDY3NTggMy43NDIwOCAwLjYwNjc1OCA0LjA0ODU0IDAuNzgwOTlMNi4xMjY2NSAxLjk2MjQ2QzYuNDM5MjkgMi4xNDAyMSA2LjYzMjQxIDIuNDcyMTUgNi42MzI0MSAyLjgzMTc5VjUuMTY4MjFDNi42MzI0MSA1LjUyNzg1IDYuNDM5MjkgNS44NTk3OSA2LjEyNjY1IDYuMDM3NTRMNC4wNDg1NCA3LjIxOTAxQzMuNzQyMDggNy4zOTMyNCAzLjM2NjUyIDcuMzkzMjQgMy4wNjAwNiA3LjIxOTAxTDAuOTgxOTQ3IDYuMDM3NTRDMC42NjkzMDMgNS44NTk3OSAwLjQ3NjE4NiA1LjUyNzg1IDAuNDc2MTg2IDUuMTY4MjFWMi44MzE3OUMwLjQ3NjE4NiAyLjQ3MjE1IDAuNjY5MzAzIDIuMTQwMjEgMC45ODE5NDcgMS45NjI0NkwzLjA2MDA2IDAuNzgwOTlaIiBmaWxsPSIjMTNEMzc3Ii8+Cjwvc3ZnPgo=');
        margin-right: 0.5rem;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: -20px;
        top: 8px;
      }
    }

    ol {
      li {
        counter-increment: double-digit-counter;
        margin-bottom: 10px;
        font-size: 18px;
      }
      li::before {
        content: counter(double-digit-counter, decimal-leading-zero) '.';
        margin-right: 0.5rem;
        top: 0px;
        position: relative;
        background: transparent;
        color: #0e4343;
        width: 28px;
        height: 28px;
        font-size: 18px;
        border-radius: 100px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
    @apply py-28;
    h2 {
      @apply text-balanced text-[40px] mb-8 font-normal;
    }
    .big-text {
      @apply text-[22px] font-medium mb-20;
    }
    h3 {
      @apply text-[26px] text-humble mb-8;
    }

    p {
      @apply text-[18px] text-humble mb-8;
    }

    ul {
      @apply my-10 ml-10;
      li {
        @apply text-[18px] text-humble mb-2 list-disc;
      }
    }
  }
}
</style>
