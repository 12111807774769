<template>
  <div
    class="collapsed-content flex w-full h-20 lg:h-11 py-3 lg:py-0 items-center">
    <div
      class="w-10/12 lg:w-[93%] h-full flex flex-col lg:flex-row items-center">
      <div class="name-area flex flex-row items-center w-full lg:w-3/12">
        <p class="w-1/2">{{ row.name_area.name }}</p>
        <p
          class="w-1/2 right"
          v-html="row.name_area.sub_name" />
      </div>
      <div class="pipeline-progress-bar w-full lg:w-9/12 flex relative h-full">
        <div
          v-for="(header, idx) of data.table_headers.phases"
          :key="`header-${idx}`"
          class="pipeline-header flex-1 text-center border-x-none lg:border-r border-gray-200"></div>
        <div
          class="progress-bar absolute w-full h-auto lg:h-full flex justify-start items-center pl-0 lg:pl-4 mt-1 lg:mt-0 bg-functional lg:bg-transparent rounded-full">
          <div
            class="progress bg-gradient-to-r from-progressive to-balanced h-5 rounded-full flex items-center"
            :style="{
              width: `${row.phase_progress.progress}%`,
              background: `linear-gradient(132deg, ${data.table_headers.header_color} 0%, ${darkerBg} 100%)`,
            }">
            <div
              class="mobile-label block lg:hidden absolute top-[1px] left-2 text-[12px]"
              :class="{ 'white-text': !checkBrightness() }">
              {{ row.phase_progress.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toggle-space w-2/12 lg:w-[7%] flex items-center justify-center">
      <img
        src="@/assets/img/caret-down-progressive.svg"
        class="px-3 lg:px-2 py-4 lg:py-3 bg-functional rounded-full scale-110 transition-transform hover:scale-100 cursor-pointer duration-700"
        :class="open ? 'rotate-180' : ''"
        @click="toggleOpen()" />
    </div>
  </div>
  <Vue3SlideUpDown v-model="open">
    <div class="expanded-content pr-0 lg:pr-8 flex flex-col lg:flex-row">
      <div class="expanded-name-area py-6 pb-0 lg:pb-6 w-full lg:w-[24.05%]">
        <div
          class="inner-content py-2 lg:py-10 border-r-none lg:border-r border-gray-200">
          <BicolorLine extraclass="mb-4 block lg:hidden" />
          <p
            class="text-[26px] mb-2 leading-tight text-humble"
            v-html="row.name_area.expanded_name" />
          <BicolorLine extraclass="hidden lg:block" />
        </div>
      </div>
      <div
        class="expanded-content w-full lg:w-[75.85%] flex flex-col lg:flex-row items-center py-6">
        <div
          class="expanded-text w-full lg:w-7/12 px-0 lg:px-8 mb-6 text-[14px]"
          v-html="row.description" />
        <div class="expanded-cta w-full lg:w-5/12">
          <div
            v-for="(cta, ind) of row.cta_area"
            :key="`cta-${ind}`"
            class="cta flex w-full justify-between px-4 py-2 border border-gray-300 rounded-2xl items-center"
            :class="ind === row.cta_area.length ? 'mb-4 lg:mb-2' : 'mb-2'">
            <div class="titleArea">
              <p class="miniTitle text-[12px] xl:text-[14px]">
                {{ cta.cta_line_1 }}
              </p>
              <p class="title text-[14px] xl:text-[16px]">
                {{ cta.cta_line_2 }}
              </p>
            </div>
            <a
              class="w-fit-content"
              :href="cta.cta_button.url"
              :target="cta.cta_button.target">
              <button
                class="bg-progressive px-4 rounded-full h-[35px] text-[12px] xl:text-[14px] scale-100 transition-transform hover:scale-95">
                {{ cta.cta_button.title }}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Vue3SlideUpDown>
</template>
<script setup>
import { ref, onMounted, onBeforeMount, computed } from 'vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import Vue3SlideUpDown from 'vue3-slide-up-down'
import useUtils from '@/components/composables/useUtils'
import tinycolor from 'tinycolor2'
const { genUUID } = useUtils()
const animID = ref(genUUID())

const open = ref(props.row.open_by_default)
const props = defineProps(['row', 'data'])
// console.log(props)

gsap.registerPlugin(ScrollTrigger)

const toggleOpen = () => {
  open.value = !open.value
}

const darkerBg = computed(() => {
  return tinycolor(props.data.table_headers.header_color).darken(20).toString()
})

const checkBrightness = () => {
  return tinycolor(props.data.table_headers.header_color).isLight()
}

onMounted(() => {
  gsap.fromTo(
    '.progress-bar',
    {
      width: '0%',
    },
    {
      scrollTrigger: {
        trigger: '.progress-bar',
        start: 'top 80%',
        end: 'bottom 80%',
        markers: false,
        id: animID.value,
      },
      width: '100%',
      duration: 1.5,
      ease: 'power2.out',
      stagger: 0.2,
    }
  )
})

onBeforeMount(() => {
  const anim = ScrollTrigger.getById(animID.value)
  if (anim) anim.kill()
})
</script>
<style lang="scss" scoped>
.pipeline-header {
  &:first-child {
    @apply lg:border-l;
  }
}

.name-area {
  p {
    @apply text-base leading-4;
  }
  .right:deep() {
    sub,
    sup {
      @apply text-[12px];
    }
    @apply max-h-[30px] leading-[20px];
  }
}

.mobile-label.white-text {
  @apply text-white;
}
</style>
