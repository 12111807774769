<template>
  <div
    v-if="item.parent === '0'"
    class="menu-items group relative items-center"
    :class="`${hasSub(item)} ${
      device === 'mobile' ? 'mobile-subnav-root' : ''
    }`">
    <template v-if="item.class.includes('has-subnav')">
      <template v-if="item.url !== '#'">
        <template v-if="device === 'mobile'">
          <div class="z-2 inline-block relative">
            <router-link
              class="header-1 mobile-subnav-parent lg:mb-0"
              :class="subnavOpen ? 'mb-3' : ''"
              :to="convertToRelative(item.url)"
              :target="item.target"
              v-html="item.content"></router-link>
          </div>
          <div
            class="submenu-trigger z-1 top-2 lg:top-0 -right-0 lg:-right-10 absolute h-[37px] w-full"
            @click="openSubnav">
            <img
              class="caret ml-2 absolute top-[12px] right-[25px]"
              :class="subnavOpen ? 'transform rotate-180' : ''"
              src="@/assets/img/caret-down.svg" />
          </div>
        </template>
        <template v-else>
          <router-link
            class="header-1 desktop-subnav-parent mb-3 lg:mb-0"
            :target="item.target"
            :to="convertToRelative(item.url)">
            <span v-html="item.content"></span>
            <img
              class="caret ml-2"
              :class="sticky ? 'sticky' : ''"
              src="@/assets/img/caret-down.svg" />
          </router-link>
        </template>
      </template>
      <template v-else>
        <div class="desktop-subnav-parent mb-0 lg:mb-3">
          <span v-html="item.content"></span>
          <div
            class="ml-2 transform group-hover:rotate-180 transition-transform ease-in-out flex items-center chevron"
            :class="{ 'rotate-180': subnavOpen }">
            <svg
              width="13px"
              height="6px"
              viewBox="0 0 13 6"
              version="1.1"
              stroke="inherit">
              <g
                stroke="inherit"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-dasharray="0 0">
                <g
                  transform="translate(-1074.000000, -93.000000)"
                  stroke="inherit">
                  <g transform="translate(711.000000, 86.000000)">
                    <g transform="translate(304.000000, 0.000000)">
                      <path d="M60,7 L65.5,13 L71,7" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <template v-if="device === 'mobile' || device === 'tablet'">
        <slide-up-down
          v-model="subnavOpen"
          class="subnav-accordion">
          <ul class="sub-menu">
            <template v-for="sub in menu">
              <li
                v-if="sub.parent == item.id"
                :key="sub.id"
                :title="sub.title">
                <template v-if="isExternal(sub.url)">
                  <a
                    class="header-1"
                    :href="sub.url"
                    :target="sub.target"
                    :title="sub.content"
                    @click="closeNav"
                    v-html="sub.content"></a>
                </template>
                <template v-else>
                  <router-link
                    class="header-1"
                    :to="convertToRelative(sub.url)"
                    @click="closeNav"
                    v-html="sub.content"></router-link>
                </template>
              </li>
            </template>
          </ul>
        </slide-up-down>
      </template>
      <template v-else>
        <ul class="sub-menu">
          <template v-for="sub in menu">
            <li
              v-if="sub.parent == item.id"
              :key="sub.id"
              :title="sub.title"
              :class="arrayToString(sub.class)">
              <template v-if="isExternal(sub.url)">
                <a
                  class="header-1"
                  :href="sub.url"
                  :class="arrayToString(sub.class)"
                  :target="sub.target"
                  :title="sub.content"
                  v-html="sub.content"></a>
              </template>
              <template v-else>
                <router-link
                  class="header-1"
                  :to="convertToRelative(sub.url)"
                  @click="closeNav"
                  v-html="sub.content"></router-link>
              </template>
            </li>
            <template v-else></template>
          </template>
          <div class="triangle"></div>
        </ul>
      </template>
    </template>
    <template v-else-if="item.class.includes('CTA') && !device === 'mobile'">
      <router-link
        class="header-1"
        :to="convertToRelative(item.url)"
        exact
        @click="closeNav">
        <span v-html="item.content"></span>
        <svg
          class="ml-2"
          width="17"
          height="8"
          viewBox="0 0 17 8"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.844 4.18558C17.0393 3.99032 17.0393 3.67374 16.844 3.47848L13.6621 0.296497C13.4668 0.101235 13.1502 0.101235 12.955 0.296497C12.7597 0.491759 12.7597 0.808342 12.955 1.0036L15.7834 3.83203L12.955 6.66046C12.7597 6.85572 12.7597 7.1723 12.955 7.36757C13.1502 7.56283 13.4668 7.56283 13.6621 7.36757L16.844 4.18558ZM0.925781 4.33203H16.4905V3.33203H0.925781V4.33203Z" />
        </svg>
      </router-link>
    </template>
    <template v-else>
      <router-link
        v-if="!isExternal(item.url)"
        class="header-1"
        :target="item.target"
        :to="convertToRelative(item.url)"
        exact
        @click="closeNav">
        <span v-html="item.content" />
        <!-- <div
          v-if="device === 'mobile'"
          class="hex-container">
          <img
            src="@/assets/img/hex.svg"
            alt="" />
        </div> -->
        <div class="hex-container">
          <img
            src="@/assets/img/hex.svg"
            alt="" />
        </div>
      </router-link>
      <a
        v-else
        class="header-1"
        :target="item.target"
        :href="item.url"
        exact
        @click="closeNav">
        <span v-html="item.content" />
        <!-- <div
          v-if="device === 'mobile'"
          class="hex-container">
          <img
            src="@/assets/img/hex.svg"
            alt="" />
        </div> -->
        <div class="hex-container">
          <img
            src="@/assets/img/hex.svg"
            alt="" />
        </div>
      </a>
    </template>
  </div>
</template>

<script setup>
import SlideUpDown from 'vue3-slide-up-down'
import useUtils from '@/components/composables/useUtils.js'
import { ref, watch } from 'vue'

const { device, scrollY, convertToRelative, isExternal } = useUtils()
defineProps(['menu', 'item'])
const subnavOpen = ref()
const sticky = ref()

watch(scrollY, (newVal) => {
  if (newVal > 50) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

const hasSub = (item) => {
  return item.class.includes('has-subnav')
    ? `parent-nav ${arrayToString(item.class)}`
    : `${arrayToString(item.class)}`
}
const openSubnav = () => {
  subnavOpen.value = !subnavOpen.value
}

const closeNav = () => {
  subnavOpen.value = false
}

const arrayToString = (array) => {
  return array.join(' ')
}
</script>

<style lang="scss">
.caret {
  @apply transition duration-500 filter lg:brightness-100;
}

li {
  @apply relative;
  .menu-items {
    @apply w-full 
    flex flex-col py-[15px] relative fill-white stroke-white
    border-b-[1px] border-b-progressive
    md:pb-5
    lg:w-auto lg:py-0 lg:border-0;
    &.orange {
      a {
        @apply text-gray-600;
      }
    }
    &.has-subnav {
      @apply flex;
      a {
        @apply w-max;
      }
    }
    .sub-menu {
      li {
        @apply p-0;
        a {
          @apply pb-4 lg:pb-0 lg:p-10 lg:pr-16 w-full inline-block;
        }
      }
    }
    a {
      @apply relative  text-[34px]   self-center lg:self-start
    lg:text-humble lg:text-[16px] lg:hover:opacity-100 lg:hover:text-gray-600
    w-full h-full duration-200 transition ease-in-out;
      span {
        @apply text-white
        lg:text-humble;
      }
      .hex-container {
        @apply flex items-center justify-center opacity-0  top-0 right-0 absolute h-full w-5
        
          lg:right-[50%] lg:translate-x-[50%] lg:top-5  lg:transition-all lg:duration-[400ms] lg:ease-in-out;
        img {
          @apply lg:scale-50 lg:rotate-[180deg] lg:transition-all lg:duration-[400ms] lg:ease-in-out;
        }
      }
      &:hover {
        .hex-container {
          @apply lg:opacity-100;
          img {
            @apply lg:scale-100 lg:rotate-[0deg];
          }
        }
      }
    }
    a.router-link-active {
      span {
        @apply opacity-100;
      }
      .hex-container {
        @apply opacity-100;
      }
    }
    .subnav-accordion {
      @apply w-full;
    }
  }
}

.desktop-subnav-parent {
  @apply flex flex-row justify-center opacity-60 hover:opacity-100 transition-opacity duration-200;
  stroke: inherit;
  .chevron {
    stroke: inherit;
    svg {
      stroke: inherit;
    }
  }
}

.mobile-subnav-parent {
  @apply inline-block opacity-60 hover:opacity-100 transition-opacity duration-200;
  stroke: inherit;
}

.mobile-subnav-root {
  img {
    &::before {
      @apply content-[''] absolute w-full h-full bg-green-500;
    }
  }
}

li.CTA {
  @apply lg:p-0;
  a {
    @apply lg:border lg:border-white lg:p-3 lg:px-6 lg:flex lg:items-center lg:content-center;
    svg {
      fill: inherit;
      stroke: none;
    }
  }
}

.sub-menu {
  @apply relative lg:absolute flex flex-col top-0
      lg:right-[-15px] lg:transform lg:translate-y-[-999999px]
     bg-black md:shadow-xl xl:max-w-[800px] w-full xl:w-max;
  li {
    @apply flex justify-center xl:justify-start text-center lg:text-left text-black hover:text-white hover:bg-black
      lg:border-black lg:border-b lg:border-opacity-10 lg:px-2 lg:py-1;
    a {
      @apply flex py-4 lg:last:py-3;
    }
  }
}
@media all and (min-width: 1024px) {
  ul.sub-menu .triangle {
    content: '';
    position: absolute;
    top: -10px;
    right: 12px;
    height: 10px;
    width: 10px;
    background-color: #ffffff00;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e9edee;
    clear: both;
    transform: rotate(180deg);
  }
  .sub-menu > li:nth-child(1):hover ~ .triangle {
    border-top: 10px solid rgba(0, 181, 223, 1);
  }
}

.parent-nav:hover .sub-menu {
  @apply lg:opacity-100 transition-opacity duration-500 ease-in-out lg:transform lg:translate-y-[56px];
}

// final styles for menu items
</style>
