<template>
  <section
    class="text-reveal-module-component"
    :style="{
      '--revmed-mini-text-color': data.color_settings.mini_heading_text_color,
      '--revmed-text-color': data.color_settings.text_color,
      '--revmed-bg-color': data.color_settings.bg_color,
      '--revmed-highlight-color': data.color_settings.highlight_color,
    }">
    <div
      class="inner-container"
      :class="data.horizontal_padding ? '' : 'lg:px-0'">
      <h2
        v-if="data.mini_heading"
        class="mini-title">
        {{ data.mini_heading }}
      </h2>
      <div
        :id="`text-${uniqueId}`"
        ref="textToBreak"
        class="text"
        v-html="addBGDiv(data.text)" />
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TextRevealComponent",
    "CLASSNAME": "text-reveal-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */

/*
 *   Insert your logic here
 */

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import useUtils from '@/components/composables/useUtils'

const { genUUID } = useUtils()

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])
const splitted = ref()
const uniqueId = ref()
const textToBreak = ref()

const animateText = () => {
  gsap.fromTo(
    `#text-${uniqueId.value} div.line > *`,
    {
      y: '100%',
    },
    {
      y: '0%',
      duration: 1,
      stagger: 0.1,
      ease: 'power4.out',
      scrollTrigger: {
        id: `text-${uniqueId.value}`,
        trigger: `#text-${uniqueId.value}`,
        start: 'top-=80% center',
        end: 'bottom-=30% center',
        scrub: 1,
        markers: false,
      },
    }
  )
  gsap.fromTo(
    `#text-${uniqueId.value} div.highlight`,
    {
      width: '0%',
    },
    {
      width: '103%',
      duration: 1,
      stagger: 0.1,
      ease: 'power4.out',
      scrollTrigger: {
        id: `highlight-${uniqueId.value}`,
        trigger: `#text-${uniqueId.value}`,
        start: 'top+=10% center',
        end: 'bottom center',
        scrub: 1,
        markers: false,
      },
    }
  )
}

const addBGDiv = (html) => {
  return html.replace(
    /<em>/g,
    `<em><div class="highlight highlight-${uniqueId.value}"></div>`
  )
}

const splitIt = () => {
  const breakIt = document.querySelector(`#text-${uniqueId.value}`)

  if (!breakIt) {
    setTimeout(() => {
      splitIt()
    }, 100)
  } else {
    const textLines = new SplitType(`#text-${uniqueId.value} > *`, {
      types: 'lines, words',
    })
    splitted.value = textLines.lines
    animateText()
  }
}

onMounted(() => {
  uniqueId.value = genUUID()
  splitIt()
})

onBeforeUnmount(() => {
  const textAnim = ScrollTrigger.getById(`text-${uniqueId.value}`)
  if (textAnim) textAnim.kill()
  const highlightAnim = ScrollTrigger.getById(`highlight-${uniqueId.value}`)
  if (highlightAnim) highlightAnim.kill()
})
</script>

<style lang="scss" scoped>
.text-reveal-module-component {
  @apply module-container py-12;
  background-color: var(--revmed-bg-color);
  .inner-container {
    .mini-title {
      @apply text-center mb-4 text-[16px] lg:text-[22px];
      color: var(--revmed-mini-text-color);
    }
    .text:deep() {
      @apply text-[30px] lg:text-[80px] leading-none tracking-normal lg:tracking-[-4px];
      * {
        @apply text-[26px] lg:text-[80px] leading-none tracking-normal lg:tracking-[-4px];
      }
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-[var(--revmed-text-color)];
      }
      .line {
        @apply overflow-hidden;
      }
      .word {
        @apply text-[var(--revmed-text-color)];
      }
      em {
        @apply not-italic;
        .highlight {
          @apply h-full w-full absolute left-0 bottom-1 z-[-1]
          lg:bottom-[13px] lg:h-[55px];
          background-color: var(--revmed-highlight-color);
        }
      }
    }
  }
}
</style>
