<template>
  <div class="wrapper module-container">
    <main>
      <div
        class="section-404 inner-container container w-full h-100 max-w-screen-xl mx-auto my-32 lg:my-60">
        <section class="text-left mb-6">
          <h1
            class="font-FKDisplay text-balanced text-[40px] leading-[45px] lg:text-[60px] lg:leading-[60px] font-normal mb-10">
            <span class="text-progressive">Sorry,</span> we can’t find the
            <br class="hidden lg:block" />
            page you’re looking for!
          </h1>
          <BicolorLine extraclass="bottom-line" />
          <p class="mt-10 font-light">Error Code: 404</p>
          <p class="mt-6 font-light text-[22px] mb-16">
            Please try retyping your URL, go back, or visit our homepage.
          </p>
          <ButtonGlobal
            text="Home"
            target="_self"
            url="/" />
        </section>
      </div>
    </main>
  </div>
</template>

<script setup>
import { useStore } from '@/stores/main'
import BicolorLine from '@/components/utility/BicolorLine.vue'
import ButtonGlobal from '@/components/utility/ButtonGlobal.vue'

const store = useStore()

store.updateDocTitle({
  parts: ['Page not found', store.site.name],
})
</script>

<style lang="scss" scoped>
.section-404:deep() {
  .bottom-line {
    @apply w-60;
  }
}
</style>
