<template>
  <section class="pagination">
    <a
      class="pagination__previous"
      :class="{ disabled: props.current === 1 }"
      href="#"
      rel="previous"
      @click.prevent="goToPage(props.current - 1)"
      v-html="'&lsaquo; Previous'"></a>
    <div class="pages">
      <a
        v-for="(item, index) in props.total"
        :key="index"
        class="pagination__item"
        :class="{
          disabled: props.current === item,
          active: props.current === item,
        }"
        href="#"
        :rel="`page-${item}`"
        @click.prevent="goToPage(item)">
        {{ item }}
      </a>
    </div>
    <a
      class="pagination__next"
      :class="{ disabled: props.current === props.total }"
      href="#"
      rel="next"
      @click.prevent="goToPage(current + 1)"
      v-html="'Next &rsaquo;'"></a>
  </section>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const props = {
  current: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
}

const goToPage = (page) => {
  if (!page || page > props.total) return
  let path = route.path
  if (props.current === 1 && page !== 1) {
    path += `page/${page}/`
  } else if (page === 1) {
    path = path.replace(`page/${props.current}/`, '')
  } else {
    path = path.replace(`page/${props.current}/`, `page/${page}/`)
  }
  router.push(path)
}
</script>

<style lang="scss">
.pagination {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 1.8rem;
    text-decoration: none;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &.active {
      font-weight: bold;
      opacity: 1;
    }
  }

  .pagination__item {
    padding: 5px;
  }
}
</style>
