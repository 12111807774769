<template>
  <div
    id="website-wrapper"
    @click="handleClicks">
    <transition
      name="fade"
      mode="out-in">
      <SiteIntro v-if="!introShown" />
    </transition>
    <nav-menu
      class="main-nav"
      name="main-menu" />
    <div id="website-content">
      <router-view v-slot="{ Component }">
        <transition
          name="fade"
          @before-enter="transitionElement"
          @leave="leave"
          @after-leave="completeTransition">
          <component
            :is="Component"
            :key="$route.path" />
        </transition>
      </router-view>
      <div
        ref="footerRef"
        class="footer-wrapper"
        :class="IsFooterVisible ? '' : 'py-10'">
        <Footer v-if="IsFooterVisible" />
      </div>
    </div>
    <transition name="fade">
      <site-loading v-if="loading" />
    </transition>
    <ModalsContainer />
  </div>
</template>

<script setup>
import NavMenu from './components/template-parts/NavMenu/NavMenu.vue'
// import AppFooter from './components/template-parts/Footer.vue'
import SiteLoading from './components/utility/SiteLoading.vue'
import SiteIntro from './components/utility/SiteIntro.vue'
import { ref, computed, onMounted, defineAsyncComponent } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import emitter from './scripts/emitter'
import useIntroShown from './components/composables/useIntroShown.js'
// Uncomment the following line to enable smoothScroll
import useSmoothScroll from './components/composables/useSmoothScroll.js'
import { ModalsContainer } from 'vue-final-modal'
const router = useRouter()
const store = useStore()
const site = ref(store.site)
const showMenu = ref()
const { introShown } = useIntroShown()
const footerRef = ref()
const IsFooterVisible = ref(false)

// load footer async
const Footer = defineAsyncComponent(() =>
  import('./components/template-parts/Footer.vue')
)

// Uncomment the following line to enable smoothScroll
const { activate, locoScroll } = useSmoothScroll()

const loading = computed(() => {
  return store.site.loading
})

const transitionElement = () => {
  showMenu.value = true
}
const completeTransition = () => {
  showMenu.value = false
}
const leave = (_el, done) => {
  emitter.emit('scrollBeforeEnter', done)
}

const getLinkEl = (el) => {
  while (el.parentNode) {
    if (el.tagName === 'A') return el
    el = el.parentNode
  }
}

const handleClicks = (e) => {
  const a = getLinkEl(e.target)
  if (a && a.href.includes(site.value.url)) {
    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = e
    // don't handle if has class 'no-router'
    if (a.className.includes('no-router')) return
    // don't handle with control keys
    if (metaKey || altKey || ctrlKey || shiftKey) return
    // don't handle when preventDefault called
    if (defaultPrevented) return
    // don't handle right clicks
    if (button !== undefined && button !== 0) return
    // don't handle if `target="_blank"`
    if (a.target && a.target.includes('_blank')) return
    // don't handle same page links
    const currentURL = new URL(a.href, window.location.href)
    if (currentURL && currentURL.pathname === window.location.pathname) {
      // if same page link has same hash prevent default reload
      if (currentURL.hash === window.location.hash) e.preventDefault()
    }
    // Prevent default and push to vue-router
    e.preventDefault()
    const path = a.href.replace(site.value.url, '')
    router.push(path)
  }
}

onMounted(() => {
  // Uncomment the following line to enable smoothScroll
  emitter.on('scrollToTop', () => {
    locoScroll.value.scrollTo('top', { immediate: true })
  })

  setTimeout(() => {
    // Check if footer in view
    const { stop } = useIntersectionObserver(
      footerRef,
      ([{ isIntersecting }]) => {
        if (!IsFooterVisible.value && isIntersecting) {
          IsFooterVisible.value = isIntersecting
          stop()
        }
      },
      {
        rootMargin: '0px 0px 500px 0px',
      }
    )
  }, 1000)
  activate()
})
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
/* Vue transition classes
-------------------------------------------- */

.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.4s ease-out;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.4s ease-in;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#website-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main {
  position: relative;
  overflow: hidden;
}

// Lenis Styles START
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
// Lenis Styles END
</style>
