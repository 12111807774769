<template>
  <section
    :key="sectionAnimID"
    class="horizontal-logo-anim-module-component module-container">
    <div class="inner-container">
      <div
        v-if="device === 'desktop'"
        class="desktop-layout">
        <div class="sliding-anim">
          <div class="sliding-anim-top">
            <!-- <div class="anim-section"> -->
            <div
              ref="animation"
              class="lottie-anim"></div>
            <!-- </div> -->
          </div>
          <div class="sliding-anim-bottom">
            <div class="indicator">
              <div class="indicator-progress" />
            </div>
            <div class="steps">
              <div
                v-for="(step, index) of data.list"
                :key="`step-text-${index}`"
                class="step">
                {{ step.item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="mobile-carusel">
        <div class="anim-section">
          <div class="lottie-anim-bg">
            <div
              ref="animation"
              class="lottie-anim"></div>
          </div>
        </div>
        <div class="carousel">
          <div class="car-controls">
            <div class="car-controls-top">
              <button
                class="left"
                :class="{ disabled: mobileCurr === 0 }"
                :disabled="mobileCurr === 0"
                @click="prev()">
                <img src="@/assets/img/icon-arrow-left-approachable.svg" />
              </button>

              <button
                class="right"
                :class="{ disabled: mobileCurr === data.list.length - 1 }"
                :disabled="mobileCurr === data.list.length - 1"
                @click="next()">
                <img src="@/assets/img/icon-arrow-left-approachable.svg" />
              </button>
            </div>
            <div class="car-controls-bottom">
              <div class="indicator" />
            </div>
            <!-- <div class="car-controls-bottom">
              <div
                v-for="(step, index) of data.list"
                :key="`step-indicator-${index}`"
                class="indicator"
                :class="{ active: index === mobileCurr }"></div>
            </div> -->
          </div>
          <div class="car-text">
            <h2 class="title">{{ data.title }}</h2>
            <template
              v-for="(step, index) of data.list"
              :key="`car-item-step-${index}`">
              <transition
                name="custom-fade"
                mode="out-in">
                <div
                  v-if="index === mobileCurr"
                  class="car-item-container">
                  <p class="car-item">{{ step.item }}</p>
                  <div
                    class="step-description"
                    v-html="step.description" />
                </div>
              </transition>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HorizontalLogoAnimComponent",
    "CLASSNAME": "horizontal-logo-anim-module-component",
    "URL": "http://vuevitewp.local/test"
   }
  #ENDMETA */
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import LottieWeb from 'lottie-web'
import evolution from '@/assets/lottie/RevMed_LogoEvolution.json'
import useUtils from '@/components/composables/useUtils'

gsap.registerPlugin(ScrollTrigger)
const { device, genUUID } = useUtils()
const animID = ref({
  animXTranslate: genUUID(),
  lottie: genUUID(),
  progBar: genUUID(),
  scroll: genUUID(),
})

const sectionAnimID = 'section-id-' + animID.value.scroll

const anim = ref()
const animation = ref()
const mobileCurr = ref(0)
defineProps(['data'])

const valuesAnimation = () => {
  const animStart = 'top+=200 center'
  const animEnd = 'bottom center'
  const lottieAnim = document.querySelector('.lottie-anim')
  const parentWidth = document.querySelector('.sliding-anim').offsetWidth
  const parentHeight = document.querySelector('.sliding-anim').offsetHeight
  const lottieAnimWidth = lottieAnim.offsetWidth

  const initialOffset = -30
  gsap.fromTo(
    lottieAnim,
    {
      x: initialOffset,
    },
    {
      x: parentWidth - lottieAnimWidth - initialOffset,
      scrollTrigger: {
        trigger: '.horizontal-logo-anim-module-component',
        start: animStart,
        end: animEnd,
        scrub: 1,
        id: animID.value.animXTranslate,
      },
    }
  )
  gsap.to('.indicator-progress', {
    width: parentWidth,
    scrollTrigger: {
      trigger: '.horizontal-logo-anim-module-component',
      start: animStart,
      end: animEnd,
      scrub: 1,
      id: animID.value.progBar,
    },
  })
  gsap.fromTo(
    '.horizontal-logo-anim-module-component > .inner-container',
    {
      y: 0,
    },
    {
      y: parentHeight * 0.8,
      scrollTrigger: {
        trigger: '.horizontal-logo-anim-module-component',
        start: animStart,
        end: animEnd,
        scrub: 1,
        id: animID.value.lottie,
        onUpdate: ({ progress }) => {
          anim.value.goToAndStop(progress * 130, true)
        },
      },
    }
  )
}

const animSegments = [
  [0, 35],
  [35, 64],
  [64, 84],
  [84, 130],
]

const selectorCurrPos = ref(0)

const moveSelector = (dir) => {
  const selector = document.querySelector('.indicator')
  const to = selector.parentElement.offsetWidth * 0.2
  if (dir === '>') {
    gsap.to(selector, {
      x: selectorCurrPos.value + to,
    })
    selectorCurrPos.value += to
  } else if (dir === '<') {
    gsap.to(selector, {
      x: selectorCurrPos.value - to,
    })
    selectorCurrPos.value -= to
  }
}

const next = () => {
  anim.value.playSegments(animSegments[mobileCurr.value], true)
  mobileCurr.value += 1
  moveSelector('>')
}

const prev = () => {
  mobileCurr.value -= 1
  moveSelector('<')
  let framesRef = new Array(
    animSegments[mobileCurr.value][0],
    animSegments[mobileCurr.value][1]
  )
  anim.value.playSegments(framesRef.reverse(), true)
}

onMounted(() => {
  anim.value = LottieWeb.loadAnimation({
    container: animation.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: evolution,
  })
  if (device.value === 'desktop') {
    valuesAnimation()
  }
})
onBeforeUnmount(() => {
  const animXTranslate = ScrollTrigger.getById(animID.value.animXTranslate)
  const animLottie = ScrollTrigger.getById(animID.value.lottie)
  const animProgBar = ScrollTrigger.getById(animID.value.progBar)
  const animScroll = ScrollTrigger.getById(animID.value.scroll)
  if (animXTranslate) animXTranslate.kill()
  if (animLottie) animLottie.kill()
  if (animProgBar) animProgBar.kill()
  if (animScroll) animScroll.kill()
})
</script>

<style lang="scss" scoped>
.horizontal-logo-anim-module-component {
  @apply bg-balanced pb-10;

  .desktop-layout {
    @apply lg:h-[500px];
    .sliding-anim {
      &-top {
        .lottie-anim {
          @apply w-[250px] h-[250px] scale-150;
        }
      }
      &-bottom {
        @apply flex flex-col gap-[22px];

        .indicator {
          @apply w-full h-[3px] bg-humble;
          &-progress {
            @apply h-full w-0 bg-progressive;
          }
        }

        .steps {
          @apply flex justify-between;
          .step {
            @apply w-[190px] text-functional text-center body;
          }
        }
      }
    }
  }

  .mobile-carusel {
    .anim-section {
      @apply overflow-hidden;
      .lottie-anim-bg {
        @apply my-[-65px] pointer-events-none scale-150;
      }
    }
    .carousel {
      @apply flex flex-col gap-[30px];

      .car-controls {
        @apply flex flex-col gap-10;
        &-top {
          @apply flex justify-center gap-14;
          button {
            @apply bg-white p-[10px] rounded-full;
            img {
              @apply w-[30px] h-[30px];
            }
            &.disabled {
              @apply opacity-50;
            }
          }
          .right {
            img {
              @apply rotate-180;
            }
          }
        }
        // &-bottom {
        //   @apply flex px-5;

        //   .indicator {
        //     @apply bg-humble h-[3px] flex-1 transition-all;
        //     &.active {
        //       @apply bg-approachable;
        //     }
        //   }
        // }
        &-bottom {
          @apply w-full h-[3px] bg-humble;
          .indicator {
            @apply bg-approachable h-full w-[20%];
          }
        }
      }
      .car-text {
        .title,
        .car-item {
          @apply text-center text-white;
        }
        .title {
          @apply mb-[22px];
        }
        .car-item-container {
          @apply flex justify-center;
          .car-item {
            @apply text-[22px] w-[250px];
          }
        }
      }
    }
  }
}
.custom-fade-enter-from {
  opacity: 0;
}
.custom-fade-enter-active {
  transition: opacity 0.4s ease-in-out;
  transition-delay: 0.5s;
}
.custom-fade-leave-to {
  opacity: 0;
}
.custom-fade-leave-active {
  transition: opacity 0.4s ease-in;
}
</style>
